/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';

import DocumentForm from './components/DocumentForm';

const Documents = () => {
  const [activeTab, setActiveTab] = useState('docList');

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <div className="border-b-solid mb-0 rounded-t-2xl border-b-0 border-b-transparent bg-white p-6 pb-0">
              <h6>Documents</h6>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
              <div className="md:flex">
                <ul
                  className="mr-6 mt-8 flex w-full max-w-sm flex-shrink-0 flex-col border-r border-gray-300/50 bg-white
				py-2"
                >
                  <li
                    className={`flex cursor-pointer items-center px-8 pt-1 pb-1 text-lg hover:text-primary
                    ${
                      activeTab === 'docList' ? 'text-primary' : 'text-gray-500'
                    }
					          capitalize`}
                    onClick={() => setActiveTab('docList')}
                  >
                    <button type="button" className="ml-2">
                      <svg
                        className="h-5 w-5 fill-current"
                        viewBox="0 0 256 512"
                      >
                        <path
                          d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                        />
                      </svg>
                    </button>
                    <span>Tous les documents</span>
                  </li>
                  <li
                    className={`flex cursor-pointer items-center px-8 pt-1 pb-1 text-lg hover:text-primary
                    ${activeTab === 'note' ? 'text-primary' : 'text-gray-500'}
					          capitalize`}
                    onClick={() => setActiveTab('note')}
                  >
                    <button type="button" className="ml-2">
                      <svg
                        className="h-5 w-5 fill-current"
                        viewBox="0 0 256 512"
                      >
                        <path
                          d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                        />
                      </svg>
                    </button>
                    <span>Charger une note circulaire</span>
                  </li>
                  <li
                    className={`flex cursor-pointer items-center px-8 pt-1 pb-1 text-lg hover:text-primary
                    ${activeTab === 'pv' ? 'text-primary' : 'text-gray-500'}
					          capitalize`}
                    onClick={() => setActiveTab('pv')}
                  >
                    <button type="button" className="ml-2">
                      <svg
                        className="h-5 w-5 fill-current"
                        viewBox="0 0 256 512"
                      >
                        <path
                          d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                        />
                      </svg>
                    </button>
                    <span>Charger un PV</span>
                  </li>
                  <li
                    className={`flex cursor-pointer items-center px-8 pt-1 pb-1 text-lg hover:text-primary
                    ${activeTab === 'cr' ? 'text-primary' : 'text-gray-500'}
					          capitalize`}
                    onClick={() => setActiveTab('cr')}
                  >
                    <button type="button" className="ml-2">
                      <svg
                        className="h-5 w-5 fill-current"
                        viewBox="0 0 256 512"
                      >
                        <path
                          d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                        />
                      </svg>
                    </button>
                    <span>Charger un compte-rendu</span>
                  </li>
                </ul>

                <div
                  className="mr-6 flex w-full flex-shrink-0 flex-col rounded-lg bg-white
				py-2 md:w-2/3"
                >
                  {activeTab === 'docList' && (
                    <div className="flex min-h-screen p-6">
                      <div className="w-full">
                        <DocumentForm
                          title="Tous les documents"
                          type=""
                          activeTab="docList"
                          setActiveTab={setActiveTab}
                        />
                      </div>
                    </div>
                  )}

                  {activeTab === 'note' && (
                    <div className="flex min-h-screen p-6">
                      <div className="max-w-3xl">
                        <DocumentForm
                          title="Télécharger une note circulaire"
                          type="note"
                          activeTab="docForm"
                          setActiveTab={setActiveTab}
                        />
                      </div>
                    </div>
                  )}

                  {activeTab === 'pv' && (
                    <div className="flex min-h-screen p-6">
                      <div className="max-w-3xl">
                        <DocumentForm
                          title="Télécharger un procès-verbal d'assemblée générale"
                          type="pv"
                          activeTab="docForm"
                          setActiveTab={setActiveTab}
                        />
                      </div>
                    </div>
                  )}

                  {activeTab === 'cr' && (
                    <div className="flex min-h-screen p-6">
                      <div className="max-w-3xl">
                        <DocumentForm
                          title="Télécharger un compte-rendu"
                          type="cr"
                          activeTab="docForm"
                          setActiveTab={setActiveTab}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
