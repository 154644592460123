/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';

import { getProjects } from './api/projet';
import ProjectModal from './components/ProjectModal';

const Projets = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [isClosedModal, setIsClosedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [project, setProject] = useState({
    file: null,
  });
  const [projects, setProjects] = useState([]);
  const [offset] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);

  const fetchProjects = () => {
    setLoading(true);
    getProjects(offset, page)
      .then((res) => {
        if (res.data) {
          setProjects(res.data.projects);
          setTotalPage(res.data.totalPage);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          type: 'error',
          text: 'Une erreur est survenue lors de la récupération des projects',
        });
      });
  };

  // Pagination function
  const paginate = (step) => {
    switch (step) {
      // if page > 1 && step = -1 : prev
      case -1:
        if (page > 1) {
          const prevPage = page - 1;
          setPage(prevPage);
        }
        break;

      // page < totalPage && step = 1 : next
      case 1:
        if (page <= totalPage) {
          const nextPage = page + 1;
          setPage(nextPage);
        }
        break;

      // default
      default:
        break;
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [page]);

  const displayPopUp = () => {
    setShowPopUp(!showPopUp);
  };

  const closeModal = () => {
    fetchProjects();
    setShowPopUp(false);
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'open':
        return 'Ouvert';
      case 'closed':
        return 'Fermé';
      default:
        return 'Ouvert';
    }
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <div
              className="border-b-solid mb-0 flex items-center justify-between
             rounded-t-2xl border-b-0 border-b-transparent bg-white p-6 pb-3"
            >
              <h6>Projets</h6>
              <button
                type="button"
                className="mt-6 flex rounded-full bg-primary py-4 px-7 font-semibold text-white md:mt-0"
                onClick={displayPopUp}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="currentColor"
                  />
                </svg>{' '}
                Créer un projet
              </button>
            </div>

            <div className="flex min-h-screen p-6">
              <div className="w-full">
                <table
                  className="w-full border-separate rounded border border-slate-200 text-left"
                  cellSpacing="0"
                >
                  <tbody>
                    <tr>
                      <th className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell">
                        N°
                      </th>
                      <th
                        scope="col"
                        className="hidden h-12 flex-1 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                      >
                        Nom
                      </th>
                      <th
                        scope="col"
                        className="hidden h-12 flex-1 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                      >
                        Etat
                      </th>
                      <th
                        scope="col"
                        className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                      />
                    </tr>
                    {projects.map((proj, i) => (
                      <tr className="block border-b border-slate-200 last:border-b-0 sm:table-row sm:border-none">
                        <td
                          data-th="Name"
                          className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                        >
                          {i + 1}
                        </td>
                        <td
                          data-th="title"
                          className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                        >
                          {proj.name}
                        </td>
                        <td
                          data-th="title"
                          className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                        >
                          {proj.description.substring(0, 50)}...
                        </td>
                        <td
                          data-th="date"
                          className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                        >
                          {renderStatus(proj.status)}
                        </td>
                        <td
                          data-th="title"
                          className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                        >
                          <button
                            type="button"
                            className="text-blue-500 underline"
                            onClick={() => {
                              setProject(proj);
                              setShowPopUp(true);
                            }}
                          >
                            Voir/Modifier
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {loading && (
                  <div className="block w-full border border-t-0 border-slate-200 py-2 text-center">
                    <p>Chargement...</p>
                  </div>
                )}
                {!loading && projects.length === 0 && (
                  <div className="block w-full border border-t-0 border-slate-200 py-2 text-center">
                    <p>Aucun project</p>
                  </div>
                )}

                {message.type && (
                  <div
                    className={`my-4 text-center
                  ${
                    message.type === 'success'
                      ? 'text-green-500'
                      : 'text-red-500'
                  }`}
                  >
                    {message.text}
                  </div>
                )}

                {/* Pagination */}
                <nav
                  role="navigation"
                  aria-label="Pagination Navigation"
                  className="mt-8"
                >
                  <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
                    <li>
                      <button
                        type="button"
                        aria-label="Goto Page 1"
                        className="inline-flex h-10 cursor-pointer items-center justify-center gap-4
                     rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700
                      transition duration-300 focus:bg-primary/10
                      focus:stroke-primary focus:text-primary focus-visible:outline-none
                       hover:bg-primary/10 hover:stroke-primary  hover:text-primary"
                        disabled={`${page === 1 ? 'disabled' : ''}`}
                        onClick={() => paginate(-1)}
                      >
                        <span className="order-2">Précédent</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="-mx-1 h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          role="graphics-symbol"
                          aria-labelledby="title-09 desc-09"
                        >
                          <title id="title-09">Page précédente</title>
                          <desc id="desc-09">lien à page précédente</desc>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      </button>
                    </li>

                    <li>
                      <button
                        type="button"
                        aria-label="Goto Page 3"
                        className="inline-flex h-10 cursor-pointer items-center justify-center gap-4
                     rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700
                      transition duration-300 focus:bg-primary/10
                      focus:stroke-primary focus:text-primary focus-visible:outline-none
                       hover:bg-primary/10 hover:stroke-primary  hover:text-primary"
                        onClick={() => paginate(1)}
                        disabled={`${page === totalPage ? 'disabled' : ''}`}
                      >
                        <span>Suivant </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="-mx-1 h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          role="graphics-symbol"
                          aria-labelledby="title-10 desc-10"
                        >
                          <title id="title-10">Page suivante</title>
                          <desc id="desc-10">lien à la page suivante</desc>
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

            {/* Project modal */}
            {showPopUp && (
              <ProjectModal
                onOpen={showPopUp}
                onClose={closeModal}
                onSuccess={closeModal}
                data={project}
                isClosedModal={isClosedModal}
                setIsClosedModal={setIsClosedModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projets;
