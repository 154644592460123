/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/aria-role */
import {
  addKkiapayCloseListener,
  addKkiapayListener,
  openKkiapayWidget,
  removeKkiapayListener,
} from 'kkiapay';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { postTeam } from '../api/team';
import ButtonLoading from './ButtonLoading';

const PaymentModal = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [data, setData] = useState({
    type: '',
    date: '',
    time: '',
    notify: false,
    public: false,
  });
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  const handleClose = () => {
    if (show) {
      setShow(false);

      setTimeout(() => {
        if (props.onOpen) {
          props.onClose();
        }
      }, 50);
    }
  };

  const modalRef = useDetectClickOutside({ onTriggered: handleClose });

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (props.contest) {
      setData({
        ...data,
        type: props.contest.type,
        date: props.contest.start_date.split('T')[0],
        time: props.contest.start_date.split('T')[1].split('.')[0],
        notify: props.contest.notify,
        public: props.contest.public,
      });
    }
  }, [props.contest]);

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const successHandler = (response) => {
    setPaymentProcessing(false);
    setTransactionId(response?.transactionId);
  };

  function onSubmit() {
    if (!loading) {
      setPaymentProcessing(false);
      setLoading(true);
      setMessage({});

      const list = props.teams.map((team) =>
        team.map((player) => {
          return { id: player.id };
        })
      );

      // remove empty team
      const teams = list.filter((team) => team.length > 0);

      postTeam(props.contest.id, teams, transactionId)
        .then((response) => {
          setLoading(false);

          if (response.data.success) {
            setMessage({
              type: 'success',
              text: `Vos équipes ont rejoins le ${
                props.type === 'tournament' ? 'championnat' : 'concours'
              } avec succès.`,
            });

            setTimeout(() => {
              props.onSuccess();
            }, 1000);
          } else {
            setMessage({
              type: 'error',
              text: 'Une erreur est survenue. Veuillez réessayer.',
            });
          }
        })
        .catch((error) => {
          setLoading(false);

          if (error.response.status === 400) {
            setMessage({
              type: 'error',
              text: error.response.data.message,
            });
          } else if (
            error.response?.status === 401 ||
            error.response?.status === 403
          ) {
            logOut();
            setLoading(false);
            setMessage({
              type: 'error',
              text: 'Votre session a expiré. Veuillez vous reconnecter.',
            });
          } else {
            setMessage({
              type: 'error',
              text: 'Une erreur est survenue. Veuillez réessayer.',
            });
            setLoading(false);
          }
        });
    }
  }

  useEffect(() => {
    addKkiapayListener('success', successHandler);
    addKkiapayListener('error', (error) => {
      setPaymentProcessing(false);
    });
    addKkiapayCloseListener(() => {
      setPaymentProcessing(false);
    });
    return () => {
      removeKkiapayListener('success', successHandler);
    };
  }, []);

  function open(e) {
    e.preventDefault();

    if (transactionId) {
      onSubmit();
    } else {
      setTransactionId('');
      setPaymentProcessing(true);
      openKkiapayWidget({
        amount: Number(props.contest?.price || 0) * Number(props.teams.length),
        api_key: process.env.REACT_APP_KKIAPAY_API_KEY,
        sandbox: true,
      });
    }
  }

  return (
    <div
      className={`decision-tree sticky-0 fixed inset-0 z-[999] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-80 px-3 transition-all
      ${show ? 'bg-gray-900' : 'bg-transparent'}`}
    >
      <div
        ref={modalRef}
        className="relative mx-auto w-full max-w-2xl overflow-hidden rounded-3xl bg-white"
      >
        <div className="max-h-[80vh] overflow-y-scroll p-10 px-4 pt-4 pb-16 shadow transition-all scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200 xl:pb-8">
          <header id="header-3a" className="gap- flex items-center">
            <h1 className="text-xl font-bold">Finaliser vos inscriptions</h1>
            <button
              type="button"
              className="ml-auto inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-5 text-sm font-medium tracking-wide text-gray-500 transition duration-300 focus:text-primary focus-visible:outline-none disabled:cursor-not-allowed"
              aria-label="close dialog"
              onClick={handleClose}
            >
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  role="graphics-symbol"
                  aria-labelledby="title-79 desc-79"
                >
                  <title id="title-79">Close</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </header>

          <div className="flex flex-col gap-4 pt-8">
            <div className="pb-9">
              <form onSubmit={open}>
                <p className="mb-4">
                  Vous êtes sur le point d&apos;inscrire {props.teams.length}{' '}
                  équipe(s) à ce{' '}
                  {props.type === 'tournament' ? 'championnat' : 'concours'}.
                  Une fois que vous aurez cliqué sur le bouton ci-dessous, vous
                  serez redirigé vers la plateforme de paiement pour effectuer
                  le paiement de vos inscriptions.
                  <br />
                </p>

                <p className="mb-4 text-red-500">
                  Note: Aucun remboursement ne sera effectué en cas
                  d&apos;annulation de votre part.
                </p>
                <br />
                <br />
                {message?.type && (
                  <div
                    className={`text-center text-sm ${
                      message.type === 'success'
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    {message.text}
                    <br />
                    <br />
                  </div>
                )}

                <div className="flex">
                  <button
                    type="submit"
                    className={`close-button relative mx-auto rounded-xl bg-primary px-3 py-2 text-white
                                          ${
                                            !loading &&
                                            'duration-300 hover:scale-105'
                                          }`}
                  >
                    {loading && 'En cours'}
                    {!loading &&
                      !paymentProcessing &&
                      !transactionId &&
                      'Procéder au paiement'}
                    {!loading &&
                      !paymentProcessing &&
                      transactionId &&
                      'Valider'}
                    {!loading && paymentProcessing && 'Patientez ...'}
                    {loading && <ButtonLoading />}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {props.loading && (
            <div className="xt-loader absolute inset-0 z-content overflow-hidden rounded-inherit">
              <div className="xt-spinner text-primary-500 absolute inset-0 m-auto h-6 w-6">
                <svg
                  viewBox="0 0 240 240"
                  className="absolute scale-150"
                  preserveAspectRatio="xMinYMin meet"
                >
                  <circle
                    className="origin-center stroke-current opacity-25"
                    fill="none"
                    strokeWidth="30"
                    cx="120"
                    cy="120"
                    r="100"
                    strokeDasharray="628"
                    strokeDashoffset="0"
                    pathLength="628"
                  />
                </svg>
                <svg viewBox="0 0 240 240">
                  <circle
                    className="relativext-icon origin-center animate-xt-spinner stroke-current"
                    fill="none"
                    strokeWidth="30"
                    cx="120"
                    cy="120"
                    r="100"
                    strokeDasharray="628"
                    strokeDashoffset="628"
                    pathLength="628"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

PaymentModal.propTypes = {
  type: PropTypes.string.isRequired,
  onOpen: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  contest: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    number_of_team: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    notify: PropTypes.bool,
    public: PropTypes.bool,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    drawing_date: PropTypes.string,
    composition: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  teams: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        club: PropTypes.string,
        id: PropTypes.string,
        licence: PropTypes.string,
        name: PropTypes.string,
        occupation: PropTypes.string,
        profile: PropTypes.string,
        role: PropTypes.string,
        verified: PropTypes.bool,
      })
    )
  ),
  onSuccess: PropTypes.func,
};

export default PaymentModal;
