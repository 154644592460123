import { publicRequest, userRequest } from '.';

export const getAllLigues = async () => {
  return publicRequest.get('ligues');
};

export const getLigues = async (offset) => {
  return userRequest.get(`ligues?offset=${offset}`);
};

export const postLigue = async (league) => {
  return userRequest.post(`ligues`, league);
};

export const putLigue = async (id, league) => {
  return userRequest.put(`ligues/${id}`, league);
};

export const searchLigue = async (query, offset) => {
  return userRequest.get(`ligues/search?query=${query}&offset=${offset}`);
};
