import React from 'react';

const NoMatch = () => {
  return (
    <div className="flex min-h-screen flex-col pt-16 pb-12">
      <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
        <div className="flex flex-shrink-0 justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">Error</span>
            <img src="/logo-2.png" alt="logo" />
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-red-purple-500 text-sm font-semibold uppercase tracking-wide">
              404
            </p>
            <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Page non trouvée
            </h1>
            <p className="mt-2 text-base text-gray-500">
              Désolé, la page que vous recherchez n&apos;existe pas.
            </p>
            <div className="mt-6">
              <a
                href="/"
                className="text-base font-medium text-primary hover:text-secondary"
              >
                Revenir en arrière<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoMatch;
