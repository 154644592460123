/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-unresolved */
import 'xtendui/src/infinitescroll';

import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { getAllLigues } from './api/ligue';
import {
  addUser,
  getClubs,
  getPlayers,
  getUserRole,
  getUsers,
  searchPlayers,
  searchUsers,
  //  updateUser,
} from './api/user';
import countries from './assets/countries.json';
import ButtonLoading from './components/ButtonLoading';

const Players = () => {
  const location = useLocation();
  const path = location.pathname;
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [currentLimit, setNumberPlayer] = useState(0);
  const [players, setPlayers] = useState([]);
  const [show, setShow] = useState(false);
  const [display, setDisplay] = useState(false);
  const [isBenin, setIsBenin] = useState(false);
  const [league, setLeagues] = useState([]);
  const [club, setClubs] = useState([]);
  const [leagueId, setLeagueId] = useState();
  const playerRef = useRef(null);
  const [search, setSearch] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  const [user, setUser] = useState({
    lastName: '',
    firstName: '',
    email: '',
    phone: '',
    occupation: '',
    birthDate: '',
    country: '',
    ligue: '',
    club: '',
    profile: '',
    bio: '',
    fromDisk: false,
  });
  const profileSchema = Yup.object().shape({
    lastName: Yup.string().required('Nom requis'),
    firstName: Yup.string().required('Prénom requis'),
    email: Yup.string().email('Email incorrect').required('Email requis'),
    phone: Yup.string(),
    occupation: Yup.string(),
    birthDate: Yup.string(),
    country: Yup.string(),
    ligue: Yup.string(),
    club: leagueId ? Yup.string().required('Club requis') : Yup.string(),
    profile: Yup.mixed(),
    bio: Yup.string(),
  });

  const formikField = (field, setFieldValue) => {
    if (field.type === 'select') {
      if (field.hasValidation) {
        return (
          <Field
            as="select"
            name={field.name}
            className="w-full rounded border border-gray-300 bg-white p-3.5"
            validate={field.validate}
          >
            {field.listOptions.map((option) => {
              return <option value={option.value}>{option.name}</option>;
            })}
          </Field>
        );
      }
      return (
        <Field
          as="select"
          name={field.name}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    if (field.hasValidation) {
      if (field.type === 'textarea') {
        return (
          <Field
            name={field.name}
            as="textarea"
            placeholder={field.placeholder}
            className="w-full rounded border border-gray-300 bg-white p-3.5"
            validate={field.validate}
          />
        );
      }
      return (
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
          validate={field.validate}
        />
      );
    }
    if (field.type === 'textarea') {
      return (
        <Field
          as="textarea"
          name={field.name}
          placeholder={field.placeholder}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
        />
      );
    }
    if (field.type === 'file') {
      return (
        <div>
          <div className="flex items-center">
            {user.profile && !user?.fromDisk && (
              <img
                src={`${process.env.REACT_APP_WEB_APP_URL_ASSET_DIR}${user.profile}`}
                alt="PROFILE"
                className="h-24 w-24 rounded-full object-cover"
              />
            )}
            {user.profile && user?.fromDisk && (
              <img
                src={user.profile}
                alt="PROFILE"
                className="h-24 w-24 rounded-full object-cover"
              />
            )}
            {!user.profile && (
              <span className="inline-block h-24 w-24 overflow-hidden rounded-full bg-gray-100 object-cover">
                <svg
                  className="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
            )}
            <div className="relative">
              <button
                type="button"
                className="absolute z-0 ml-4 rounded border border-gray-400 py-2 px-4 font-semibold text-gray-800 shadow hover:bg-gray-100"
              >
                Changer
              </button>
              <input
                id="dropzone-file"
                name="file"
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/webp"
                onChange={(event) => {
                  const file = event.currentTarget.files[0];
                  setFieldValue('profile', file);
                  setUser({
                    ...user,
                    profile: URL.createObjectURL(file),
                    fromDisk: true,
                  });
                }}
                className="form-control z-10 opacity-0"
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <Field
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
      />
    );
  };

  const renderCharacter = (string) => {
    if (string) {
      return (
        String(string?.slice(0, 1)).toUpperCase() +
        String(string?.slice(1)).toLowerCase()
      );
    }
    return '';
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const updatePlayers = (res, limit) => {
    setPlayers(
      res?.data?.map((player) => {
        return {
          id: player?.id,
          profile: player?.profile,
          name: `${renderCharacter(player?.lastName)} ${renderCharacter(
            player?.firstName
          )}`,
          occupation: renderCharacter(player?.occupation),
          role: renderCharacter(player?.role.name),
          club: player?.club?.name || 'Aucun',
          licence: player?.licence?.number || 'Aucun',
        };
      })
    );
    setNumberPlayer(limit);
  };

  const getAllLeagues = () => {
    getAllLigues().then((res) => {
      setLeagues([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((leagueData) => {
          return {
            value: leagueData?.ligue?.id,
            name: leagueData?.ligue?.name,
          };
        }),
      ]);
      setLeagueId(res.data[0].id);
    });
  };

  const validateCountry = (value) => {
    if (value === 'BJ') {
      setIsBenin(true);
      getAllLeagues();
    }
  };

  const validateLigue = (value) => {
    if (value) {
      setLeagueId(value);
    }
  };

  const requestClubs = (ligueId) => {
    getClubs(ligueId).then((res) => {
      setClubs([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((clubData) => {
          return {
            value: clubData?.club?.id,
            name: clubData?.club?.name,
          };
        }),
      ]);
    });
  };

  useEffect(() => {
    if (leagueId) requestClubs(leagueId);
  }, [leagueId]);

  const list = [
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Nom',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'Prénom',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'phone',
      type: 'text',
      placeholder: 'Numéro de téléphone',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'occupation',
      type: 'text',
      placeholder: 'Occupation',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'birthDate',
      type: 'date',
      placeholder: 'Date de naissance',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'country',
      type: 'select',
      listOptions: countries.fr,
      placeholder: 'Pays',
      show: true,
      hasValidation: true,
      validate: validateCountry,
      semiWidth: true,
    },
    {
      name: 'ligue',
      type: 'select',
      listOptions: league,
      placeholder: 'Ligue',
      show: isBenin,
      hasValidation: true,
      validate: validateLigue,
      semiWidth: true,
    },
    {
      name: 'club',
      type: 'select',
      listOptions: club,
      placeholder: 'Club',
      show: isBenin,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'profile',
      type: 'file',
      placeholder: 'Photo',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'bio',
      type: 'textarea',
      placeholder: 'Biographie',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
  ];

  const listPlayers = () => {
    setLoading(true);
    const limit = currentLimit + 20;
    if (path === '/players' && localStorage.getItem('xprincipal') === true) {
      getPlayers(limit, true)
        .then((res) => {
          setLoading(false);
          updatePlayers(res, limit);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          }
        });
    } else {
      getUsers(limit, false)
        .then((res) => {
          setLoading(false);
          updatePlayers(res, limit);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          }
        });
    }
  };

  useEffect(() => {
    listPlayers();
  }, []);

  const handleClose = () => {
    if (show) {
      setShow(false);

      setTimeout(() => {
        setDisplay(false);
      }, 50);
    }
  };

  const open = () => {
    setDisplay(true);

    setTimeout(() => {
      setShow(true);
    }, 50);
  };

  const modalRef = useDetectClickOutside({ onTriggered: handleClose });

  const onSearchPlayer = (e, size) => {
    e.preventDefault();
    if (search.length > 2) {
      setSearchMode(true);
      setLoading(true);
      setPlayers([]);

      let limit = currentLimit + 20;

      if (size) limit = size;

      if (path === '/players' && localStorage.getItem('xprincipal') === true) {
        searchPlayers(search, limit, true)
          .then((res) => {
            updatePlayers(res, limit);
            setLoading(false);
          })
          .catch((err) => {
            if (err.response?.status === 401 || err.response?.status === 403) {
              logOut();
            }
          });
      } else {
        searchUsers(search, limit, false)
          .then((res) => {
            updatePlayers(res, limit);
            setLoading(false);
          })
          .catch((err) => {
            if (err.response?.status === 401 || err.response?.status === 403) {
              logOut();
            }
          });
      }
    }
    if (search.length === 0) {
      setSearchMode(false);
    }
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section
              ref={playerRef}
              className="infinitescroll-container relative w-full md:min-h-screen"
            >
              <header className="mb-6 w-full px-10 py-10">
                <h1 className="text-4xl font-extrabold text-secondary">
                  {path === '/players' ? 'Joueurs' : 'Utilisateurs'}
                </h1>
                <div className="xt-row mt-6 flex items-center justify-between">
                  <div className="max-w-xs">
                    <form
                      className="relative z-0 flex w-full -space-x-px"
                      onSubmit={(e) => onSearchPlayer(e)}
                    >
                      <div className="relative w-full">
                        <svg
                          className="pointer-events-none absolute inset-y-0 left-0 h-full w-8 stroke-gray-400 pl-2.5"
                          viewBox="0 0 256 256"
                          aria-hidden="true"
                        >
                          <circle
                            cx="116"
                            cy="116"
                            r="84"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          />
                          <line
                            x1="175.4"
                            y1="175.4"
                            x2="224"
                            y2="224"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          />
                        </svg>
                        <label htmlFor="search-button" className="sr-only">
                          Rechercher un utilisateur
                        </label>
                        <input
                          id="search-button"
                          type="search"
                          placeholder="Rechercher un utilisateur..."
                          className="block w-full rounded-l-md border-gray-200 pl-10 text-sm transition focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (e.target.value.length === 0) {
                              setTimeout(() => {
                                listPlayers();
                              }, 500);
                            }
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-r border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-800 transition focus:z-10 focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 hover:border-gray-300 hover:bg-gray-100"
                      >
                        Rechercher
                      </button>
                    </form>
                  </div>
                  <div>
                    <button
                      type="button"
                      className="mt-6 flex rounded-full bg-primary py-4 px-7 font-semibold text-white md:mt-0"
                      onClick={() => open()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                          fill="currentColor"
                        />
                      </svg>{' '}
                      Ajouter un{' '}
                      {path === '/players' ? 'joueur' : 'Utilisateur'}
                    </button>
                  </div>
                </div>
              </header>
              {typeof players !== 'undefined' &&
                players.length === 0 &&
                !loading &&
                !searchMode && (
                  <p className="px-10">
                    Il n&apos;y a pas encore{' '}
                    {path === '/players' ? 'de joueurs' : "d'utilisateurs"}.
                  </p>
                )}{' '}
              {searchMode && loading && (
                <p className="px-10 text-center">Chargement...</p>
              )}
              {searchMode && !loading && players.length === 0 && (
                <p className="px-10 text-center">
                  Aucun {path === '/players' ? 'joueur' : 'Utilisateur'} ne
                  correspond à votre recherche
                </p>
              )}
              <InfiniteScroll
                dataLength={20}
                next={listPlayers}
                hasMore={players.length === currentLimit}
                loader={<h4 className="text-center">Chargement...</h4>}
                endMessage={
                  currentLimit % 50 > 20 && (
                    <p style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                      <b>Vous avez atteint la fin !</b>
                    </p>
                  )
                }
                refreshFunction={listPlayers}
              >
                <div className="flex flex-wrap justify-start gap-4 p-4">
                  {players.map((player) => {
                    return (
                      <div key={player.id}>
                        <div className="card shadow-soft-xl cursor-pointer !bg-white hover:scale-[1.01]">
                          <div className="bg-gradient mb-2 h-20 w-20 select-none rounded-full">
                            {player.profile && (
                              <img
                                src={player.profile}
                                alt={player.name}
                                className="h-full w-full rounded-full object-cover"
                              />
                            )}
                            {!player.profile && (
                              <span className="inline-block h-full w-full overflow-hidden rounded-full bg-gray-100">
                                <svg
                                  className="h-full w-full text-gray-300"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              </span>
                            )}
                          </div>
                          <div className="text-lg font-bold text-gray-900">
                            {player?.name}
                          </div>

                          <div className="text-center text-default">
                            {player?.occupation}
                          </div>
                          <div className="text-gray-600">
                            {path === '/users' && (
                              <>
                                <strong>Rôle : </strong>
                                <span className="rounded-2xl bg-primary py-1 px-1.5 text-sm text-white">
                                  {player?.role}
                                </span>{' '}
                                <br />
                              </>
                            )}
                            <strong>Club :</strong> {player?.club} <br />
                            <strong>Licence :</strong> {player?.licence}
                          </div>
                          {/* <div className="text-gray-600">
                      </div> */}

                          <div className="mt-2 flex h-5 w-auto items-center justify-center gap-3 text-gray-600">
                            <i className="fa-brands fa-twitter fa-lg cursor-pointer transition duration-200 hover:text-gray-400" />
                            <i className="fa-brands fa-linkedin fa-lg cursor-pointer transition duration-200 hover:text-gray-400" />
                            <i className="fa-brands fa-dribbble fa-lg cursor-pointer transition duration-200 hover:text-gray-400" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
              {display && (
                <div
                  className={`decision-tree sticky-0 fixed inset-0 z-[999] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-80 px-3 transition-all
        ${show ? 'bg-gray-900' : 'bg-transparent'}`}
                >
                  <div
                    ref={modalRef}
                    className="relative mx-auto max-w-2xl overflow-hidden rounded-3xl bg-white"
                  >
                    <div className="max-h-[80vh] overflow-y-scroll p-10 px-4 pt-4 pb-16 shadow transition-all scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200 xl:pb-24">
                      <header id="header-3a" className="gap- flex items-center">
                        <button
                          type="button"
                          className="ml-auto inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-5 text-sm font-medium tracking-wide text-gray-500 transition duration-300 focus:text-primary focus-visible:outline-none disabled:cursor-not-allowed"
                          aria-label="close dialog"
                          onClick={handleClose}
                        >
                          <span className="relative only:-mx-5">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth="2"
                              role="graphics-symbol"
                              aria-labelledby="title-79 desc-79"
                            >
                              <title id="title-79">Close</title>
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </span>
                        </button>
                      </header>
                      <div className="">
                        <Formik
                          enableReinitialize
                          initialValues={user}
                          validationSchema={profileSchema}
                          onSubmit={(values) => {
                            if (!loading) {
                              const formData = new FormData();
                              formData.append('firstName', values.firstName);
                              formData.append('lastName', values.lastName);
                              formData.append('email', values.email);
                              formData.append('phone', values.phone);
                              formData.append('occupation', values.occupation);
                              formData.append(
                                'birthDate',
                                new Date(values.birthDate).toISOString()
                              );
                              formData.append('bio', values.bio);
                              const COUNTRY = values.country;
                              formData.append('country', COUNTRY);
                              if (COUNTRY === 'BJ') {
                                formData.append('club', values.club);
                                formData.append('ligue', values.ligue);
                              }
                              if (values.profile)
                                formData.append('profile', values.profile);
                              setIsLoading(true);
                              getUserRole()
                                .then((res) => {
                                  formData.append('roleId', res.data.id);

                                  addUser(formData)
                                    .then(() => {
                                      setIsLoading(false);
                                      setMessage([
                                        true,
                                        'Le compte a été ajouté avec succès',
                                      ]);
                                      setTimeout(() => {
                                        listPlayers();
                                        handleClose();
                                      }, 1000);
                                    })
                                    .catch((err) => {
                                      if (
                                        err.response?.status === 401 ||
                                        err.response?.status === 403
                                      ) {
                                        logOut();
                                      } else {
                                        setMessage([
                                          false,
                                          "Une erreur est survenue lors de l'ajout du compte",
                                        ]);
                                      }
                                      setIsLoading(false);
                                    });
                                })
                                .catch(() => {
                                  setMessage([
                                    false,
                                    'Une erreur est survenue lors du traitement. Veuillez réessayer',
                                  ]);
                                  setIsLoading(false);
                                });
                            }
                          }}
                        >
                          {({ errors, touched, setFieldValue }) => (
                            <Form className="flex flex-col gap-4 pt-8">
                              <div className="flex flex-wrap justify-between gap-y-4">
                                {list.map((field) => {
                                  return (
                                    field.show && (
                                      <div
                                        key={field.name}
                                        className={`flex w-full flex-col md:${
                                          field.semiWidth && 'w-1/2'
                                        }`}
                                      >
                                        <div className="flex flex-col items-start justify-start space-y-1">
                                          <h3 className="text-xl leading-normal text-gray-900">
                                            {[field.placeholder]}
                                          </h3>
                                        </div>
                                        <div className="flex flex-col items-start justify-start space-y-1">
                                          <h3 className="text-xl leading-normal text-gray-900">
                                            {[field.title]}
                                          </h3>
                                        </div>
                                        <div className="pr-8">
                                          {formikField(field, setFieldValue)}
                                          {errors[field.name] &&
                                          touched[field.name] ? (
                                            <div className="text-red-400">
                                              {errors[field.name]}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                              <div
                                className={`text-center text-sm ${
                                  message[0] ? 'text-primary' : 'text-secondary'
                                }`}
                              >
                                {message[1]}
                              </div>
                              <div className="flex w-full justify-between">
                                <button
                                  type="button"
                                  className={`xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-2 ml-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-secondary bg-transparent bg-none px-4 py-3 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:border-secondary
                                  ${
                                    !loading && 'duration-300 hover:scale-105'
                                  }`}
                                  data-class="bg-white"
                                  onClick={handleClose}
                                >
                                  Annuler
                                </button>
                                <button
                                  type="submit"
                                  className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                                  data-class="bg-transparent"
                                >
                                  {isLoading && 'En cours'}
                                  {!isLoading && !user?.id && 'Ajouter'}
                                  {!isLoading && user?.id && 'Modifier'}
                                  {isLoading && <ButtonLoading />}
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                      {loading && (
                        <div className="xt-loader absolute inset-0 z-content overflow-hidden rounded-inherit">
                          <div className="xt-spinner text-primary-500 absolute inset-0 m-auto h-6 w-6">
                            <svg
                              viewBox="0 0 240 240"
                              className="absolute scale-150"
                              preserveAspectRatio="xMinYMin meet"
                            >
                              <circle
                                className="origin-center stroke-current opacity-25"
                                fill="none"
                                strokeWidth="30"
                                cx="120"
                                cy="120"
                                r="100"
                                strokeDasharray="628"
                                strokeDashoffset="0"
                                pathLength="628"
                              />
                            </svg>
                            <svg viewBox="0 0 240 240">
                              <circle
                                className="relativext-icon origin-center animate-xt-spinner stroke-current"
                                fill="none"
                                strokeWidth="30"
                                cx="120"
                                cy="120"
                                r="100"
                                strokeDasharray="628"
                                strokeDashoffset="628"
                                pathLength="628"
                              />
                            </svg>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Players;
