/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';

import { getInvitations, getMyClubInvitations } from './api/invitation';

const Invitations = () => {
  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [currentLimit, setNumberInvitation] = useState(0);

  useEffect(() => {
    const limit = currentLimit + 20;
    if (localStorage?.getItem('xprincipal')) {
      getMyClubInvitations(limit)
        .then((res) => {
          setInvitations(res.data.invitations);
        })
        .catch((err) => {
          console.log(err.message);
        });
    } else {
      // Fetching all the invitations
      getInvitations(limit)
        .then((res) => {
          console.log(res.data);
          setInvitations(res.data);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
    setNumberInvitation(limit);
  }, []);

  const renderStatus = (status) => {
    switch (status) {
      case 'pending':
        return (
          <span className="font-semibold text-yellow-500">En attente</span>
        );
      case 'refused':
        return <span className="font-semibold text-red-500">Refusé</span>;
      default:
        return '';
    }
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section className="relative w-full md:min-h-screen">
              <div className="container mx-auto px-5 py-24">
                <div className="mb-2 md:w-3/4">
                  <h2 className="title-font text-2xl font-medium text-gray-900 sm:text-3xl">
                    Invitations
                  </h2>

                  <table
                    className="mt-4 w-full border-separate rounded border border-slate-200 text-left"
                    cellSpacing="0"
                  >
                    <tbody>
                      <tr>
                        <th
                          scope="col"
                          className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                        >
                          N°
                        </th>
                        <th
                          scope="col"
                          className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                        >
                          Membre
                        </th>
                        <th
                          scope="col"
                          className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                        />
                      </tr>
                      {invitations?.map((invitation) => {
                        return (
                          <tr className="block border-b border-slate-200 last:border-b-0 sm:table-row sm:border-none">
                            <td
                              data-th="Name"
                              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                            >
                              1
                            </td>
                            <td
                              data-th="Title"
                              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                            >
                              {invitation.user.firstName}{' '}
                              {invitation.user.lastName}
                            </td>
                            <td
                              data-th="Role"
                              className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                            >
                              {renderStatus(invitation.status)}
                            </td>
                            <td
                              data-th="Username"
                              className="flex h-12 items-center space-x-4 border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                            >
                              <button
                                type="button"
                                className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                >
                                  <path
                                    d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
                                    fill="rgba(255,255,255,1)"
                                    stroke="rgba(255,255,255,1)"
                                  />
                                </svg>
                              </button>
                              <button
                                type="button"
                                className="rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="14"
                                  height="14"
                                >
                                  <path
                                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                                    fill="rgba(255,255,255,1)"
                                    stroke="rgba(255,255,255,1)"
                                  />
                                </svg>
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {invitations && invitations.length === 0 && (
                    <div className="border p-1 text-center">
                      Aucune invitation n'a été envoyée
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invitations;
