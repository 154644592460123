/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { getAllLigues } from './api/ligue';
import { getClubs, getMyProfile, updateUser } from './api/user';
import countries from './assets/countries.json';
import ButtonLoading from './components/ButtonLoading';
import MutationRequestForm from './components/MutationRequestForm';

const MyAccount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [isBenin, setIsBenin] = useState(false);
  const [league, setLeagues] = useState([]);
  const [club, setClubs] = useState([]);
  const [leagueId, setLeagueId] = useState();
  const [user, setUser] = useState({
    fromDisk: false,
  });
  const [activeTab, setActiveTab] = useState('profile');
  const profileSchema = Yup.object().shape({
    lastName: Yup.string().required('Nom requis'),
    firstName: Yup.string().required('Prénom requis'),
    email: Yup.string().email('Email incorrect').required('Email requis'),
    phone: Yup.string().required('Numéro de téléphone requis'),
    occupation: Yup.string().required('Occupation requis'),
    birthDate: Yup.string().required('Date de naissance requis'),
    country: Yup.string().required('Pays requis'),
    ligue: Yup.string(),
    club: leagueId ? Yup.string().required('Club requis') : Yup.string(),
    profile: Yup.mixed(),
    bio: Yup.string(),
  });

  const formikField = (field, setFieldValue) => {
    if (field.type === 'select') {
      if (field.hasValidation) {
        return (
          <Field
            as="select"
            name={field.name}
            className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
            validate={field.validate}
          >
            {field.listOptions.map((option) => {
              return <option value={option.value}>{option.name}</option>;
            })}
          </Field>
        );
      }
      return (
        <Field
          as="select"
          name={field.name}
          className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    if (field.hasValidation) {
      if (field.type === 'textarea') {
        return (
          <Field
            name={field.name}
            as="textarea"
            placeholder={field.placeholder}
            className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
            validate={field.validate}
          />
        );
      }
      return (
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
          validate={field.validate}
        />
      );
    }
    if (field.type === 'textarea') {
      return (
        <Field
          as="textarea"
          name={field.name}
          placeholder={field.placeholder}
          className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
        />
      );
    }
    if (field.type === 'file') {
      return (
        <div>
          <div className="flex items-center">
            {user.profile && !user?.fromDisk && (
              <img
                src={`${process.env.REACT_APP_WEB_APP_URL_ASSET_DIR}${user.profile}`}
                alt="PROFILE"
                className="h-24 w-24 rounded-full object-cover"
              />
            )}
            {user.profile && user?.fromDisk && (
              <img
                src={user.profile}
                alt="PROFILE"
                className="h-24 w-24 rounded-full object-cover"
              />
            )}
            {!user.profile && (
              <span className="inline-block h-24 w-24 overflow-hidden rounded-full bg-gray-100 object-cover">
                <svg
                  className="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
            )}
            <div className="relative">
              <button
                type="button"
                className="absolute z-0 ml-4 rounded border border-gray-400 py-2 px-4 font-semibold text-gray-800 shadow hover:bg-gray-100"
              >
                Changer
              </button>
              <input
                id="dropzone-file"
                name="file"
                type="file"
                accept="image/png, image/jpeg, image/jpg, image/webp"
                onChange={(event) => {
                  const file = event.currentTarget.files[0];
                  setFieldValue('profile', file);
                  setUser({
                    ...user,
                    profile: URL.createObjectURL(file),
                    fromDisk: true,
                  });
                }}
                className="form-control z-10 opacity-0"
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <Field
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
      />
    );
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const renderCharacter = (string) => {
    if (string) {
      return (
        String(string?.slice(0, 1)).toUpperCase() +
        String(string?.slice(1)).toLowerCase()
      );
    }
    return '';
  };

  const getAllLeagues = () => {
    getAllLigues().then((res) => {
      setLeagues([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((leagueData) => {
          return {
            value: leagueData?.ligue?.id,
            name: leagueData?.ligue?.name,
          };
        }),
      ]);
      setLeagueId(res.data[0].id);
    });
  };

  const validateCountry = (value) => {
    if (value === 'BJ') {
      setIsBenin(true);
      getAllLeagues();
    }
  };

  const getProfile = () => {
    getMyProfile()
      .then((res) => {
        localStorage?.setItem('xrole', res?.data?.role?.name);
        const CONNECTED_USER = res?.data;
        const VERIFIED = CONNECTED_USER?.verified;
        setUser({
          ...user,
          id: CONNECTED_USER?.id,
          firstName: renderCharacter(CONNECTED_USER?.firstName),
          lastName: renderCharacter(CONNECTED_USER?.lastName),
          email: CONNECTED_USER?.email || '',
          phone: CONNECTED_USER?.phone || '',
          profile: CONNECTED_USER?.profile || '',
          occupation: CONNECTED_USER?.occupation || '',
          verified: VERIFIED,
          birthDate: CONNECTED_USER?.birthDate
            ? CONNECTED_USER?.birthDate?.split('T')[0]
            : '',
          country: CONNECTED_USER?.country || countries.fr[0].value,
          bio: CONNECTED_USER?.bio || '',
          clubId: CONNECTED_USER?.club?.id || '',
          ligueId: CONNECTED_USER?.ligue?.id || '',
        });

        if (CONNECTED_USER?.country === 'BJ') {
          setIsBenin(true);
          getAllLeagues();
        }
        setTimeout(() => {}, 500);
        if (!VERIFIED) {
          setMessage([
            false,
            'Votre compte doit être vérifié pour pouvoir être modifié',
          ]);
        }
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setTimeout(() => {}, 500);
      });
  };

  const validateLigue = (value) => {
    if (value) {
      setLeagueId(value);
    }
  };

  const requestClubs = (ligueId) => {
    getClubs(ligueId).then((res) => {
      setClubs([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((clubData) => {
          return {
            value: clubData?.club?.id,
            name: clubData?.club?.name,
          };
        }),
      ]);
    });
  };

  useEffect(() => {
    if (leagueId) requestClubs(leagueId);
  }, [leagueId]);

  useEffect(() => {
    getProfile();
  }, []);

  const list = [
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Nom',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'Prénom',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'phone',
      type: 'text',
      placeholder: 'Numéro de téléphone',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'occupation',
      type: 'text',
      placeholder: 'Occupation',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'birthDate',
      type: 'date',
      placeholder: 'Date de naissance',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'country',
      type: 'select',
      listOptions: [
        {
          value: '',
          name: 'Sélectionner',
        },
        ...countries.fr,
      ],
      placeholder: 'Pays',
      show: true,
      hasValidation: true,
      validate: validateCountry,
      semiWidth: true,
    },
    {
      name: 'ligue',
      type: 'select',
      listOptions: league,
      placeholder: 'Ligue',
      show: isBenin,
      hasValidation: true,
      validate: validateLigue,
      semiWidth: true,
    },
    {
      name: 'club',
      type: 'select',
      listOptions: club,
      placeholder: 'Club',
      show: isBenin,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'profile',
      type: 'file',
      placeholder: 'Photo',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'bio',
      type: 'textarea',
      placeholder: 'Biographie',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
  ];

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <div className="border-b-solid mb-0 rounded-t-2xl border-b-0 border-b-transparent bg-white p-6 pb-0">
              <h6>Mon compte</h6>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
              <div className="flex">
                <ul
                  className="mr-6 mt-8 flex w-full max-w-sm flex-shrink-0 flex-col border-r border-gray-300/50 bg-white
                  py-2"
                >
                  <li
                    className={`flex cursor-pointer items-center px-8 pt-1 pb-1 text-lg hover:text-primary
                    ${
                      activeTab === 'profile' ? 'text-primary' : 'text-gray-500'
                    }
					          capitalize`}
                    onClick={() => setActiveTab('profile')}
                  >
                    <button type="button" className="ml-2">
                      <svg
                        className="h-5 w-5 fill-current"
                        viewBox="0 0 256 512"
                      >
                        <path
                          d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                        />
                      </svg>
                    </button>
                    <span>Modifier mon compte</span>
                  </li>
                  <li
                    className={`flex cursor-pointer items-center px-8 pt-1 pb-1 text-lg hover:text-primary
                    ${
                      activeTab === 'mutation'
                        ? 'text-primary'
                        : 'text-gray-500'
                    }
					          capitalize`}
                    onClick={() => setActiveTab('mutation')}
                  >
                    <button type="button" className="ml-2">
                      <svg
                        className="h-5 w-5 fill-current"
                        viewBox="0 0 256 512"
                      >
                        <path
                          d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9
								0l-22.6-22.6c-9.4-9.4-9.4-24.6
								0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6
								0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136
								136c9.5 9.4 9.5 24.6.1 34z"
                        />
                      </svg>
                    </button>
                    <span>Demander une mutation</span>
                  </li>
                </ul>

                <div
                  className="mr-6 flex w-1/2 flex-shrink-0 flex-col rounded-lg
				bg-white py-2"
                >
                  {activeTab === 'profile' && (
                    <div className="flex min-h-screen p-6">
                      <div className="mx-auto max-w-3xl">
                        <h2 className="pb-6 text-center text-3xl font-bold">
                          Modifier votre profil
                        </h2>
                        <Formik
                          enableReinitialize
                          initialValues={user}
                          validationSchema={profileSchema}
                          onSubmit={(values) => {
                            if (!loading) {
                              const data = new FormData();
                              data.append('firstName', values.firstName);
                              data.append('lastName', values.lastName);
                              data.append('email', values.email);
                              data.append('phone', values.phone);
                              data.append('occupation', values.occupation);
                              data.append(
                                'birthDate',
                                new Date(values.birthDate).toISOString()
                              );
                              data.append('bio', values.bio || '');
                              const COUNTRY = values.country;
                              data.append('country', COUNTRY);
                              if (COUNTRY === 'BJ') {
                                data.append('club', values.club);
                                data.append('ligue', values.ligue);
                              }
                              if (values.profile)
                                data.append('profile', values.profile);
                              setLoading(true);
                              updateUser(user.id, data)
                                .then(() => {
                                  setLoading(false);
                                  setMessage([
                                    true,
                                    'Votre profil a été modifié avec succès',
                                  ]);

                                  if (user.email !== values.email) {
                                    logOut();
                                  }
                                })
                                .catch(() => {
                                  setLoading(false);
                                  setMessage([
                                    false,
                                    'Une erreur est survenue lors de la modification de votre profil',
                                  ]);
                                });
                            }
                          }}
                        >
                          {({ errors, touched, setFieldValue }) => (
                            <Form className="flex flex-col gap-4 pt-8">
                              <div className="flex flex-wrap justify-between gap-y-4">
                                {list.map((field) => {
                                  return (
                                    field.show && (
                                      <div
                                        key={field.name}
                                        className={`flex w-full flex-col md:${
                                          field.semiWidth && 'w-1/2'
                                        }`}
                                      >
                                        <div className="flex flex-col items-start justify-start space-y-1">
                                          <h3 className="text-xl leading-normal text-gray-900">
                                            {[field.placeholder]}
                                          </h3>
                                        </div>
                                        <div className="flex flex-col items-start justify-start space-y-1">
                                          <h3 className="text-xl leading-normal text-gray-900">
                                            {[field.title]}
                                          </h3>
                                        </div>
                                        <div className="pr-8">
                                          {formikField(field, setFieldValue)}
                                          {errors[field.name] &&
                                          touched[field.name] ? (
                                            <div className="text-red-400">
                                              {errors[field.name]}
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    )
                                  );
                                })}
                              </div>
                              <div
                                className={`text-center text-sm ${
                                  message[0] ? 'text-primary' : 'text-secondary'
                                }`}
                              >
                                {message[1]}
                              </div>
                              <div className="flex w-full justify-between">
                                <button
                                  type="button"
                                  className={`xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-2 ml-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-secondary bg-transparent bg-none px-4 py-3 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:border-secondary
                                  ${
                                    !loading && 'duration-300 hover:scale-105'
                                  }`}
                                  data-class="bg-white"
                                  onClick={() => navigate('/')}
                                >
                                  Annuler
                                </button>
                                <button
                                  disabled={!user.verified}
                                  type="submit"
                                  className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                                  data-class="bg-transparent"
                                >
                                  {loading ? 'En cours' : 'Modifier'}
                                  {loading && <ButtonLoading />}
                                </button>
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  )}

                  {activeTab === 'mutation' && (
                    <div className="flex min-h-screen p-6">
                      <div className="mx-auto max-w-3xl">
                        <h2 className="pb-6 text-center text-3xl font-bold">
                          Demander une mutation
                        </h2>

                        <MutationRequestForm />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
