/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable no-unused-vars */
import { publicRequest, userRequest } from '.';

export const addProject = async (data) => {
  return userRequest.post(`projects/`, data);
};

export const getProjects = async (offset, page) => {
  return publicRequest.get(`projects?offset=${offset}&page=${page}`);
};

export const getAllProjects = async () => {
  return publicRequest.get(`projects`);
};

export const updateProject = async (id, data) => {
  return userRequest.put(`projects/${id}`, data);
};
