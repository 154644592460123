/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unknown-property */
import 'xtendui';
import 'xtendui/src/slider';

// eslint-disable-next-line unused-imports/no-unused-imports
import { AreaChart } from '@tremor/react';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getPublicContests } from './api/contest';
import {
  getMyProfile,
  getRecentUsers,
  getStats,
  getUserLicensesStats,
  resend,
} from './api/user';

const Home = () => {
  const navigate = useNavigate();
  const homeRef = useRef(null);
  const [users, setUsers] = useState([]);
  const [connectUser, setConnectUser] = useState({});
  const [isSending, setisSending] = useState(false);
  const [isSent, setisSent] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState([]);
  const [stats, setStats] = useState({});
  const [contests, setContests] = useState([]);
  const [recentUsersLoading, setRecentUsersLoading] = useState(true);
  const [contestsLoading, setContestsLoading] = useState(false);

  useEffect(() => {
    setData([
      {
        name: 'Janvier',
        players: 4000,
        clubs: 2400,
        licences: 2400,
      },
      {
        name: 'Février',
        players: 3000,
        clubs: 1398,
        licences: 2210,
      },
      {
        name: 'Mars',
        players: 2000,
        clubs: 9800,
        licences: 2290,
      },
      {
        name: 'Avril',
        players: 2780,
        clubs: 3908,
        licences: 2000,
      },
      {
        name: 'Mai',
        players: 1890,
        clubs: 4800,
        licences: 2181,
      },
      {
        name: 'Juin',
        players: 2390,
        clubs: 3800,
        licences: 2500,
      },
      {
        name: 'Juillet',
        players: 3490,
        clubs: 4300,
        licences: 2100,
      },
      {
        name: 'Août',
        players: 3490,
        clubs: 4300,
        licences: 2100,
      },
      {
        name: 'Septembre',
        players: 3490,
        clubs: 4300,
        licences: 2100,
      },
      {
        name: 'Octobre',
        players: 3490,
        clubs: 4300,
        licences: 2100,
      },
      {
        name: 'Novembre',
        players: 3490,
        clubs: 4300,
      },
      {
        name: 'Décembre',
        players: 3490,
        clubs: 4300,
        licences: 2100,
      },
    ]);

    setCategories(['players', 'licences']);
  }, []);

  const renderCharacter = (string) => {
    if (string) {
      return (
        String(string?.slice(0, 1)).toUpperCase() +
        String(string?.slice(1)).toLowerCase()
      );
    }
    return '';
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const resendToUser = (e) => {
    e.preventDefault();

    setisSending(true);

    let CONNECTED_USER = {
      email: connectUser.email,
      firstName: connectUser.firstName,
      lastName: connectUser.lastName,
      roleId: connectUser.roleId,
    };

    if (connectUser.ligueId)
      CONNECTED_USER = {
        ...CONNECTED_USER,
        ligueId: connectUser.ligueId.id,
      };
    if (connectUser.clubId)
      CONNECTED_USER = {
        ...CONNECTED_USER,
        clubId: connectUser.clubId.id,
      };

    resend(CONNECTED_USER)
      .then(() => {
        setisSending(false);
        setisSent(true);
      })
      .catch((err) => {
        setisSending(false);
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
      });
  };

  const getAllRecentUsers = () => {
    getRecentUsers()
      .then((res) => {
        setUsers(
          res?.data.map((user) => {
            const firstName = renderCharacter(user?.firstName);
            const { profile } = user;
            return {
              id: user?.id,
              name: `${renderCharacter(user?.lastName)} ${firstName}`,
              shortName: firstName,
              isUpdate: user?.active,
              image_url: profile,
            };
          })
        );

        // Sorting datas
        if (connectUser.role === 'player' || connectUser.role === 'others') {
          setUsers(
            users.filter((user) => {
              return user.role.name === 'player' || user.role.name === 'other';
            })
          );
        }

        setRecentUsersLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setRecentUsersLoading(false);
      });
  };

  const getUserStats = () => {
    getStats()
      .then((res) => {
        setStats(res);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
      });
  };

  const getUserMonthlyLicensesStats = () => {
    getUserLicensesStats()
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          // logOut();
        }
      });
  };

  const getProfile = () => {
    getMyProfile()
      .then((res) => {
        const CONNECTED_USER = res?.data;
        const LICENCE = CONNECTED_USER?.licence;
        const VERIFIED = CONNECTED_USER?.verified;
        const OCCUPATION = CONNECTED_USER?.occupation;
        const BIO = CONNECTED_USER?.bio;
        const PHONE = CONNECTED_USER?.phone;
        const PROFILE = CONNECTED_USER?.profile;
        const COUNTRY = CONNECTED_USER?.country;
        const CLUB = CONNECTED_USER?.club;
        const LEAGUE = CONNECTED_USER?.ligue;
        let rate = 0;
        if (VERIFIED) {
          rate += 25;
          if ((OCCUPATION && PHONE) || (PHONE && BIO)) {
            rate += 25;
          }
          if (PROFILE) {
            rate += 25;
          }
          if (COUNTRY === 'BJ') {
            if (CLUB) {
              rate += 5;
            }
            if (LEAGUE) {
              rate += 5;
            }
          } else {
            rate += 25;
          }
          if (LICENCE) {
            rate += 5;
          }
        }
        setConnectUser({
          id: CONNECTED_USER?.id,
          email: CONNECTED_USER?.email,
          ligueId: CONNECTED_USER?.ligue,
          clubId: CONNECTED_USER?.club,
          roleId: CONNECTED_USER?.role.id,
          role: CONNECTED_USER.role.name || '',
          firstName: renderCharacter(CONNECTED_USER?.firstName),
          lastName: renderCharacter(CONNECTED_USER?.lastName),
          licence: LICENCE === null ? 'Aucune licence' : `Licence : ${LICENCE}`,
          verified: VERIFIED,
          occupation: OCCUPATION,
          phone: PHONE,
          profile: PROFILE,
          club: CLUB,
          league: LEAGUE,
          rate,
        });
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
      });
  };

  useEffect(() => {
    const percentageComplete = connectUser.rate / 100;
    const strokeDashOffsetValue = 100 - percentageComplete * 100;
    const progressBar = document.querySelector('.profile-progress-bar');
    setTimeout(() => {
      progressBar.style.strokeDashoffset = strokeDashOffsetValue;
    }, 1000);
  }, [connectUser]);

  useEffect(() => {
    getAllRecentUsers();
    getProfile();
    getUserStats();
  }, []);

  // Function to get all the contest from db
  const getAllContests = () => {
    getPublicContests(0)
      .then((res) => {
        setContests(res.data);
        setContestsLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setContestsLoading(false);
      });
  };

  useEffect(() => {
    getAllContests();
  }, [connectUser]);

  const renderType = (type) => {
    switch (type) {
      case 'one':
        return 'Tête à tête';
      case 'doubles':
        return 'Doublette';
      case 'triples':
        return 'Triplette';
      case 'shot':
        return 'Tir de précision';
      default:
        return '';
    }
  };

  return (
    <div className="flex flex-row flex-wrap gap-y-4" ref={homeRef}>
      <div className="h-72 w-full max-w-full px-3 lg:flex-none">
        <div className="border-black/12.5 shadow-soft-xl relative flex h-full min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border p-4">
          <div
            className="relative h-full overflow-hidden rounded-xl bg-cover bg-bottom"
            style={{
              backgroundImage:
                "url('https://images.pexels.com/photos/10071077/pexels-photo-10071077.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
            }}
          >
            <span className="absolute top-0 left-0 h-full w-full bg-gradient-to-tl from-gray-900 to-slate-800 bg-cover bg-center opacity-30" />
            <div className="relative z-10 flex h-full flex-auto flex-col p-4">
              <h5 className="m-auto mb-6 py-16 pt-2 text-center text-2xl font-bold text-white lg:text-3xl">
                SOMAFIX COUPE DE LA RENTRÉE: DU 17 AU 18 SEPTEMBRE À TOKPEGBE
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full max-w-full px-3 md:w-1/2 lg:flex-none">
        <div className="border-black/12.5 shadow-soft-xl relative flex h-full min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border p-4">
          {/* <AreaChart
            data={data}
            categories={categories}
            dataKey="name"
            colors={['indigo', 'cyan']}
            valueFormatter={undefined}
            startEndOnly={false}
            showXAxis
            showYAxis
            yAxisWidth="w-14"
            showTooltip
            showLegend
            showGridLines
            showAnimation
            height="h-96"
            marginTop="mt-0"
          /> */}

          <h2 className="text-base font-bold">Concours et championnats</h2>

          {contests.length === 0 && !contestsLoading && (
            <div className="p-4 md:p-6 lg:p-8">
              <h4 className="text-xl text-gray-300">
                Données non disponible pour le moment
              </h4>
            </div>
          )}

          {/* Tournament and challenges  Slider */}

          {contests.length > 0 && (
            <div className="xt-slider" data-xt-slider>
              <div className="p-4 md:p-6 lg:p-8">
                <div
                  className="xt-row xt-slides xt-row-4 justify-between transition duration-300 ease-out-expo on:duration-1000 on:ease-out-quint"
                  data-xt-slider-dragger
                >
                  {contests.map((contest) => (
                    <div className="group xt-slide" data-xt-slider-target>
                      <div className="xt-card xt-links-default">
                        <div className="break-inside relative mb-4 mr-3 flex w-full flex-col justify-between space-y-2 overflow-hidden rounded-xl bg-default p-4 text-sm text-white">
                          <span className="text-xs uppercase text-[#D2BDFF]">
                            {contest.contest.name}
                          </span>

                          <div className="flex flex-row items-center space-x-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              fill="currentColor"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v4h-2V7zm3 5h2v4h-2v-4zm-6 0h2v4H8v-4z" />
                            </svg>
                            <span>Type: </span>
                            <span className="ml-1 text-base font-medium">
                              {renderType(contest.contest.composition)}
                            </span>
                          </div>

                          <div className="flex flex-row items-center space-x-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              fill="currentColor"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-4.783 4.995L12 15a5 5 0 0 1-5-5V7a5 5 0 0 1 4.783-4.995L12 2zm0 2a3 3 0 0 0-2.995 2.824L9 7v3a3 3 0 0 0 5.995.176L15 10V7a3 3 0 0 0-3-3z" />
                            </svg>
                            <span>Nombre d'équipes: </span>
                            <span className="ml-1 text-base font-medium">
                              {contest.teamCount}
                            </span>
                          </div>

                          <div className="align-items-center flex justify-between">
                            <span
                              className={`${contest.contest.drawing_date !== null
                                  ? 'block'
                                  : 'hidden'
                                }`}
                            >
                              Début: {contest.contest.drawing_date}
                            </span>

                            <span
                              className={`${contest.contest.end_date !== null
                                  ? 'block'
                                  : 'hidden'
                                }`}
                            >
                              Fin: {contest.contest.end_date}
                            </span>
                          </div>

                          <div className="flex items-center justify-end">
                            <button
                              type="button"
                              className="flex items-center justify-center space-x-1 rounded-full bg-white px-4 py-2 text-xs font-medium text-black hover:bg-opacity-90"
                              onClick={() => {
                                navigate(`/contest/${contest.contest.id}`);
                              }}
                            >
                              <span>
                                Voir le{' '}
                                {contest.contest.type === 'tournament'
                                  ? 'championnat'
                                  : 'concours'}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#000000"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M5 12h13M12 5l7 7-7 7" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <nav
                  aria-label="Slider Navigation"
                  className="xt-list xt-list-2 w-full items-center justify-center pt-4"
                  data-xt-slider-pagination
                >
                  <button
                    type="button"
                    className="xt-button hidden h-5 min-w-[1.25rem] rounded-full bg-gray-100 p-2 text-3xs font-medium uppercase leading-snug tracking-wider text-gray-900 transition-all on:bg-gray-200 on:px-4 hover:bg-gray-200 active:bg-gray-300"
                    data-xt-slider-element
                    title="Slide xt-num"
                  />
                </nav>
              </div>
            </div>
          )}
          {/* Users slider */}
          {connectUser &&
            !recentUsersLoading &&
            connectUser.role !== 'player' &&
            connectUser.role !== 'other' && (
              <h2 className="text-base font-bold">Utilisateurs</h2>
            )}
          {connectUser &&
            users.length === 0 &&
            !recentUsersLoading &&
            connectUser.role !== 'player' &&
            connectUser.role !== 'other' && (
              <div className="p-4 md:p-6 lg:p-8">
                <h4 className="text-xl text-gray-300">
                  Données non disponible pour le moment
                </h4>
              </div>
            )}

          {connectUser &&
            !recentUsersLoading &&
            connectUser.role !== 'player' &&
            connectUser.role !== 'other' &&
            users.length > 0 && (
              <div className="xt-slider" data-xt-slider>
                <div className="p-4 md:p-6 lg:p-8">
                  <div
                    className="xt-row xt-slides xt-row-4 justify-between transition duration-300 ease-out-expo on:duration-1000 on:ease-out-quint"
                    data-xt-slider-dragger
                  >
                    {users.map((user) => (
                      <div className="group xt-slide" data-xt-slider-target onClick={() => {
                        navigate(`/user/${user.id}`)
                      }}>
                        <div className="xt-card xt-links-default">
                          <div
                            key={user.id}
                            className="flex flex-col items-center"
                            title={user.name}
                          >
                            <div
                              className={`h-14 w-14 ${user.isUpdate && 'bg-gradient'
                                } mb-2 select-none rounded-full`}
                            >
                              {user.image_url && (
                                <img
                                  src={user.image_url}
                                  alt={user.name}
                                  className="h-full w-full rounded-full object-cover"
                                />
                              )}
                              {!user.image_url && (
                                <span className="inline-block h-full w-full overflow-hidden rounded-full bg-gray-100">
                                  <svg
                                    className="h-full w-full text-gray-300"
                                    fill="currentColor"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                  </svg>
                                </span>
                              )}
                            </div>
                            <p className="text-xs font-semibold text-zinc-500">
                              {user.shortName}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <nav
                    aria-label="Slider Navigation"
                    className="xt-list xt-list-2 w-full items-center justify-center pt-4"
                    data-xt-slider-pagination
                  >
                    <button
                      type="button"
                      className="xt-button hidden h-5 min-w-[1.25rem] rounded-full bg-gray-100 p-2 text-3xs font-medium uppercase leading-snug tracking-wider text-gray-900 transition-all on:bg-gray-200 on:px-4 hover:bg-gray-200 active:bg-gray-300"
                      data-xt-slider-element
                      title="Slide xt-num"
                    />
                  </nav>
                </div>
              </div>
            )}
        </div>
      </div>

      <div className="flex w-full flex-col gap-y-4 md:w-1/2">
        <div className="flex">
          <div className="w-full max-w-full px-3 md:w-1/2 lg:flex-none">
            <div className="border-black/12.5 shadow-soft-xl relative flex h-full min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border p-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="mx-auto h-16 w-16"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z" />
              </svg>
            </div>
          </div>

          <div className="w-full max-w-full px-3 md:w-1/2 lg:flex-none">
            <div className="border-black/12.5 shadow-soft-xl relative flex h-full min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border p-16">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                className="mx-auto h-16 w-16"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M22.162 5.656a8.384 8.384 0 0 1-2.402.658A4.196 4.196 0 0 0 21.6 4c-.82.488-1.719.83-2.656 1.015a4.182 4.182 0 0 0-7.126 3.814 11.874 11.874 0 0 1-8.62-4.37 4.168 4.168 0 0 0-.566 2.103c0 1.45.738 2.731 1.86 3.481a4.168 4.168 0 0 1-1.894-.523v.052a4.185 4.185 0 0 0 3.355 4.101 4.21 4.21 0 0 1-1.89.072A4.185 4.185 0 0 0 7.97 16.65a8.394 8.394 0 0 1-6.191 1.732 11.83 11.83 0 0 0 6.41 1.88c7.693 0 11.9-6.373 11.9-11.9 0-.18-.005-.362-.013-.54a8.496 8.496 0 0 0 2.087-2.165z" />
              </svg>
            </div>
          </div>
        </div>

        <div className="flex w-full max-w-full gap-4 px-3 lg:flex-none">
          {(connectUser?.role === 'player' || connectUser?.role === 'other') && (
            <div className="border-black/12.5 shadow-soft-xl relative flex h-full w-full min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border px-3 md:w-1/2">
              <div className="mt-3.5 flex flex-col items-center">
                <h4 className="self-start !text-left text-lg font-bold text-default">
                  Mes statistiques
                </h4>
                <div className="mt-0.5 mb-6 flex w-full items-center justify-evenly">
                  <div className="flex flex-col items-center">
                    <p className="text-base font-bold text-[#11243D]">
                      {stats.played || 0}
                    </p>
                    <p className="text-sm font-semibold text-zinc-500">
                      Parties joués
                    </p>
                  </div>
                  <div className="flex flex-col items-center">
                    <p className="text-base font-bold text-[#11243D]">
                      {stats.earned || 0}
                    </p>
                    <p className="text-sm font-semibold text-zinc-500">
                      Parties gagnés
                    </p>
                  </div>
                </div>
              </div>
            </div>)}

          {connectUser?.id && (
            <div className="border-black/12.5 shadow-soft-xl relative flex h-full w-full min-w-0 flex-row items-center justify-between break-words rounded-2xl border-0 border-solid bg-white bg-clip-border p-4 px-3 md:w-1/2">
              <div className="w-full">
                <p className="mb-4 text-xl font-bold text-[#393939]">
                  {connectUser.verified
                    ? 'Complèter votre profil'
                    : 'Vérifier votre compte'}
                </p>
                <p className="font-semibold text-[#4D4A81]">
                  {connectUser.verified && <>{connectUser.rate}% terminés</>}
                  {!connectUser.verified && !isSending && !isSent && (
                    <p>
                      Vous n’avez pas reçu de mail ?{' '}
                      <button
                        type="button"
                        className="underline"
                        onClick={resendToUser}
                      >
                        cliquer ici
                      </button>{' '}
                      pour renvoyer
                    </p>
                  )}
                  {isSending && (
                    <div className="flex">
                      En cours
                      <span className="xt-spinner relative inset-0 m-auto ml-2 h-6 w-6 text-primary">
                        <svg
                          viewBox="0 0 240 240"
                          className="absolute"
                          preserveAspectRatio="xMinYMin meet"
                        >
                          <circle
                            className="origin-center stroke-current opacity-25"
                            fill="none"
                            strokeWidth="30"
                            cx="120"
                            cy="120"
                            r="100"
                            strokeDasharray="628"
                            strokeDashoffset="0"
                            pathLength="628"
                          />
                        </svg>
                        <svg viewBox="0 0 240 240">
                          <circle
                            className="xt-icon relative origin-center animate-xt-spinner stroke-current"
                            fill="none"
                            strokeWidth="30"
                            cx="120"
                            cy="120"
                            r="100"
                            strokeDasharray="628"
                            strokeDashoffset="628"
                            pathLength="628"
                          />
                        </svg>
                      </span>
                    </div>
                  )}
                  {!connectUser.verified && isSent && (
                    <p>Consulter à nouveau votre boîte mail</p>
                  )}
                </p>
              </div>
              {connectUser.verified && (
                <hr className="mx-2 h-20 w-0.5 bg-gray-300" />
              )}
              {connectUser.verified && (
                <div className="profile-stat relative">
                  <svg
                    className="w-16"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="-1 -1 34 34"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="15.9155"
                      className="progress-bar__background"
                    />

                    <circle
                      cx="16"
                      cy="16"
                      r="15.9155"
                      className="progress-bar__progress
                  profile-progress-bar"
                    />
                  </svg>
                  <span
                    className={`absolute top-4 text-lg font-extrabold text-default
                  ${connectUser.rate === 100 ? 'left-3' : 'left-4'}`}
                  >
                    {connectUser.rate}%
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
