import { userRequest } from '.';

export const postTeam = async (contestId, teams, transactionId) => {
  return userRequest.post(`teams`, {
    contestId,
    teams,
    transactionId,
  });
};

export const getTeams = async () => {
  return userRequest.get(`teams`);
};

export const getTeam = async (id) => {
  return userRequest.get(`teams/${id}`);
};

export const putTeam = async (id, team) => {
  return userRequest.put(`teams/${id}`, team);
};

export const deleteTeam = async (id) => {
  return userRequest.delete(`teams/${id}`);
};
