/* eslint-disable no-console */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/no-unresolved */
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { getClubs, postClub, putClub, searchClub } from './api/club';
import { getAllLigues } from './api/ligue';
import ButtonLoading from './components/ButtonLoading';

const ClubSchema = Yup.object().shape({
  name: Yup.string().required('Nom du club'),
  email: Yup.string().email('Email incorrect').required('Email requis'),
  description: Yup.string(),
  website: Yup.string(),
  facebook: Yup.string(),
  ligueId: Yup.string().required('Ligue requise'),
  twitter: Yup.string(),
  instagram: Yup.string(),
  youtube: Yup.string(),
  image: Yup.mixed(),
});

const validateWebsite = (value) => {
  let error;
  try {
    Boolean(new URL(value));
  } catch (e) {
    error = 'Veuillez entrer une adresse valide et complète (ex: monsite.com)';
  }
  return error;
};

const Club = () => {
  const navigate = useNavigate();
  const [league, setLeagues] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopUp, setShowPopUp] = useState(false);
  const [clubToUpdate, setClubToUpdate] = useState({});
  const [step, setStep] = useState('create');
  const [club, setClub] = useState({
    name: '',
    visible: true,
  });
  const [visibility, setVisibility] = useState(true);
  const [message, setMessage] = useState({});
  const [currentLimit, setNumberClub] = useState(0);
  const [clubs, setClubs] = useState([]);
  const clubRef = useRef(null);
  const [search, setSearch] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  const formikField = (field, setFieldValue) => {
    if (field.type === 'select') {
      if (field.hasValidation) {
        return (
          <Field
            as="select"
            name={field.name}
            className="w-full rounded border border-gray-300 bg-white p-3.5"
            validate={field.validate}
          >
            {field.listOptions.map((option) => {
              return <option value={option.value}>{option.name}</option>;
            })}
          </Field>
        );
      }
      return (
        <Field
          as="select"
          name={field.name}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    if (field.hasValidation) {
      if (field.type === 'textarea') {
        return (
          <Field
            name={field.name}
            as="textarea"
            placeholder={field.placeholder}
            className="w-full rounded border border-gray-300 bg-white p-3.5"
            validate={field.validate}
          />
        );
      }
      return (
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
          validate={field.validate}
        />
      );
    }
    if (field.type === 'textarea') {
      return (
        <Field
          as="textarea"
          name={field.name}
          placeholder={field.placeholder}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
        />
      );
    }
    if (field.type === 'file') {
      return (
        <input
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          accept="image/*"
          onChange={(event) => {
            const file = event.currentTarget.files[0];
            setFieldValue('image', file);
            setClub({
              ...club,
              profile: URL.createObjectURL(file),
              fromDisk: true,
            });
          }}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
        />
      );
    }
    return (
      <Field
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
      />
    );
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const updateClubs = (res, limit) => {
    setClubs(
      res?.data?.map((item) => {
        return {
          id: item?.club?.id,
          data: item.club,
          userCount: item?.userCount,
        };
      })
    );
    setNumberClub(limit);
  };

  const getAllLeagues = (data) => {
    getAllLigues().then((res) => {
      setLeagues([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((leagueRow) => {
          return {
            value: leagueRow?.id,
            name: leagueRow?.name,
          };
        }),
      ]);
      if (!data?.id) {
        setStep('create');
        setClubToUpdate({});
      } else {
        setStep('update');
        setClubToUpdate({
          id: data?.id,
          name: data?.data?.name,
          email: data?.data?.email ?? '',
          description: data?.data?.description ?? '',
          website: data?.data?.website ?? '',
          ligueId: data?.data?.ligueId,
          facebook: data?.data?.facebook ?? '',
          twitter: data?.data?.twitter ?? '',
          instagram: data?.data?.instagram ?? '',
          youtube: data?.data?.youtube ?? '',
        });
        setVisibility(data?.data?.visible);
      }
      setTimeout(() => {
        setShowPopUp(!showPopUp);
      }, 150);
    });
  };

  const listClub = () => {
    setLoading(true);
    const limit = currentLimit + 20;
    getClubs(limit)
      .then((res) => {
        updateClubs(res, limit);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
      });
  };

  useEffect(() => {
    listClub();
  }, []);

  const list = [
    {
      name: 'ligueId',
      type: 'select',
      title: 'Ligue',
      listOptions: league,
      placeholder: 'Ligue',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'name',
      type: 'text',
      title: 'Nom du club',
      placeholder: 'Les Tigres du Bénin',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'email',
      type: 'email',
      title: 'Email du club',
      placeholder: 'contactus@lestigres.bj',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'website',
      type: 'text',
      title: 'Site Web du club',
      placeholder: 'www.lestigres.bj',
      show: true,
      hasValidation: false,
      validate: validateWebsite,
      semiWidth: true,
    },
    {
      name: 'description',
      type: 'textarea',
      title: 'Description',
      placeholder:
        'Tel le tigre de Wolé Soyinka, laisser nos médailles parler à notre place',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'facebook',
      type: 'text',
      title: 'Facebook',
      placeholder: 'Les Tigres du Bénin',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'twitter',
      type: 'text',
      title: 'Twitter',
      placeholder: 'Les Tigres du Bénin',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'instagram',
      type: 'text',
      title: 'Instagram',
      placeholder: 'Les Tigres du Bénin',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'youtube',
      type: 'text',
      title: 'Youtube',
      placeholder: 'Les Tigres du Bénin',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'image',
      type: 'file',
      title: 'Emblème',
      placeholder: 'Les Tigres du Bénin',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
  ];

  const openClub = (id) => {
    navigate(`/club/${id}`);
  };

  const createClub = (newClub) => {
    if (!loading) {
      setLoading(true);

      postClub(newClub)
        .then((res) => {
          setClub(res.data?.data);
          setMessage([
            true,
            'Le club a été créé ! Définissez maintenant la visibilité',
          ]);
          setStep('visibility');
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          } else {
            setMessage([false, 'Une erreur est survenue. Veuillez réessayer']);
          }
          setLoading(false);
        });
    }
  };

  const displayPopUp = (data) => {
    setMessage([false, '']);
    getAllLeagues(data);
  };

  const updateVisibility = (e) => {
    setVisibility(e.target.checked);
  };

  const saveVisibility = () => {
    if (!loading) {
      setMessage({});

      setLoading(true);

      const data = {};
      // append newClub data
      Object.keys(club).forEach((key) => {
        data[key] = club[key];
      });
      data.visible = visibility;
      delete data?.id;
      delete data?.createdAt;
      delete data?.updatedAt;
      putClub(club?.id, data)
        .then(() => {
          setShowPopUp(false);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          } else {
            setMessage([false, 'Une erreur est survenue. Veuillez réessayer']);
          }
          setLoading(false);
        });
    }
  };

  const update = (id, data) => {
    setLoading(true);

    putClub(id, data)
      .then(() => {
        setNumberClub(0);
        listClub();
        setLoading(false);
        setMessage([true, 'Le club a été mis a jour !']);

        setTimeout(() => {
          displayPopUp();
        }, 1000);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        } else {
          setMessage([false, 'Une erreur est survenue. Veuillez réessayer']);
        }
        setLoading(false);
      });
  };

  const stepPopupCreate = () => {
    const actionButton = () => (step === 'create' ? 'Créer' : 'Modifier');

    if (step === 'visibility') {
      return (
        <div className="py-7 text-base sm:py-9">
          <div
            className={`mb-6 text-center text-xl ${
              message[0] ? 'text-primary' : 'text-red-500'
            }`}
          >
            {message[1]}
          </div>
          <div className="flex justify-between bg-gray-300 p-7">
            <p className="text-2xl">Visibilité du club : {club.name}</p>
          </div>
          <div className="p-7 text-base sm:p-9">
            <p>
              En rendant le club visible, vous permettez à n&#39;importe quel
              utilisateur de rechercher et de le visualiser <br />
              Il pourra ainsi voir le nom du président du club, l&#39;adresse du
              club, le numéro de téléphone...
            </p>
            <div className="w-full text-center">
              <label
                htmlFor="visible"
                className="inline-flex cursor-pointer items-baseline"
              >
                Visible:{' '}
                <strong className="ml-1">{visibility ? 'Oui' : 'Non'}</strong>
                <input
                  checked={visibility}
                  name="visible"
                  onChange={updateVisibility}
                  type="checkbox"
                  aria-label="Lorem ipsum"
                  className="checked:bg-primary-500 xt-check xt-switch ml-6 rounded-full border border-transparent bg-gray-200 transition-all"
                />
              </label>
            </div>
            <button
              onClick={saveVisibility}
              type="submit"
              className="close-button relative float-right mb-8 mr-8 inline-flex rounded-xl bg-primary px-6 py-2 text-white"
            >
              Confirmer
              {loading && <ButtonLoading />}
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="xt-h4 text-center">
          {step === 'create'
            ? 'Créer un Club'
            : `Modifier le club "${clubToUpdate.name}"`}
        </div>
        <Formik
          initialValues={clubToUpdate}
          validationSchema={ClubSchema}
          onSubmit={(values) => {
            if (!loading) {
              const data = values;
              const formData = new FormData();
              // append newClub data
              const id = data?.id;
              delete data?.id;
              Object.keys(data).forEach((key) => {
                formData.append(key, data[key]);
              });
              if (step === 'create') {
                createClub(formData);
                setStep('visibility');
              } else {
                formData.append('visible', visibility);
                update(id, formData);
              }
            }
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form className="flex flex-col gap-4 pt-8">
              <div className="flex flex-wrap justify-between gap-y-4">
                {list.map((field) => {
                  return (
                    field.show && (
                      <div
                        key={field.title}
                        className={`flex w-full flex-col md:px-2 md:${
                          field.semiWidth && 'w-1/2'
                        }`}
                      >
                        <div className="flex flex-col items-start justify-start space-y-1">
                          <h3 className="text-xl leading-normal text-gray-900">
                            {[field.title]}
                          </h3>
                        </div>
                        <div className="">
                          {formikField(field, setFieldValue)}
                          {errors[field.name] && touched[field.name] ? (
                            <div className="text-red-400">
                              {errors[field.name]}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
              {step !== 'update' && (
                <div
                  className={`text-center text-sm ${
                    message[0] ? 'text-transparent' : 'text-red-500'
                  }`}
                >
                  {message[1]}
                </div>
              )}
              {step === 'update' && (
                <div className="w-full">
                  <div className="flex flex-col items-start justify-start space-y-1">
                    <h3 className="text-xl leading-normal text-gray-900">
                      Visible : <strong>Oui</strong>
                    </h3>
                  </div>
                  <input
                    checked={visibility}
                    onChange={updateVisibility}
                    name="visible"
                    type="checkbox"
                    className="checked:bg-primary-500 xt-check xt-switch ml-6 rounded-full border border-transparent bg-gray-200 transition-all"
                  />
                  <div
                    className={`mb-6 text-center text-xl ${
                      message[0] ? 'text-primary' : 'text-red-500'
                    }`}
                  >
                    {message[1]}
                  </div>
                </div>
              )}
              <div className="flex w-full justify-between">
                <button
                  onClick={() => displayPopUp()}
                  type="button"
                  className={`xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-2 ml-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-secondary bg-transparent bg-none px-4 py-3 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:border-secondary
                                  ${
                                    !loading && 'duration-300 hover:scale-105'
                                  }`}
                  data-class="bg-white"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                  data-class="bg-transparent"
                >
                  {loading ? 'En cours' : actionButton()}
                  {loading && <ButtonLoading />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const searchClubs = (e, size) => {
    e.preventDefault();
    if (search.length > 2) {
      setSearchMode(true);
      setLoading(true);
      setNumberClub(0);
      setClubs([]);

      let limit = currentLimit + 20;

      if (size) limit = size;
      searchClub(search, limit)
        .then((res) => {
          updateClubs(res, limit);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          }
        });
    }
    if (search.length === 0) {
      setSearchMode(false);
    }
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section
              ref={clubRef}
              className="infinitescroll-container relative w-full md:min-h-screen"
            >
              <header className="mb-6 w-full px-10 py-10">
                <h1 className="text-4xl font-extrabold text-secondary">
                  Clubs
                </h1>
                <div className="xt-row mt-6 flex items-center justify-between">
                  <div className="max-w-xs">
                    <form
                      className="relative z-0 flex w-full -space-x-px"
                      onSubmit={(e) => {
                        searchClubs(e);
                      }}
                    >
                      <div className="relative w-full">
                        <svg
                          className="pointer-events-none absolute inset-y-0 left-0 h-full w-8 stroke-gray-400 pl-2.5"
                          viewBox="0 0 256 256"
                          aria-hidden="true"
                        >
                          <circle
                            cx="116"
                            cy="116"
                            r="84"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          />
                          <line
                            x1="175.4"
                            y1="175.4"
                            x2="224"
                            y2="224"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          />
                        </svg>
                        <span htmlFor="search-button" className="sr-only">
                          Rechercher un club
                        </span>
                        <input
                          id="search-button"
                          type="search"
                          placeholder="Rechercher un club..."
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (e.target.value.length === 0) {
                              setTimeout(() => {
                                listClub();
                              }, 500);
                            }
                          }}
                          className="block w-full rounded-l-md border-gray-200 pl-10 text-sm transition focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                        />
                      </div>
                      <button
                        type="submit"
                        className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-r border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-800 transition focus:z-10 focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 hover:border-gray-300 hover:bg-gray-100"
                      >
                        Rechercher
                      </button>
                    </form>
                  </div>
                  <button
                    type="button"
                    className="mt-6 flex rounded-full bg-primary py-4 px-7 font-semibold text-white md:mt-0"
                    onClick={() => displayPopUp()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                        fill="currentColor"
                      />
                    </svg>{' '}
                    Ajouter un club
                  </button>
                </div>
              </header>
              {typeof clubs !== 'undefined' &&
                clubs.length === 0 &&
                !loading &&
                !searchMode && (
                  <p className="px-10">
                    Il n&apos;y a pas encore de club pour le moment.
                    <br />
                    Si vous être un membre du bureau de la ligue ou président
                    d&apos;un club, et souhaitez créer un club, veuillez cliquer
                    sur le bouton &quot;Créer votre club&quot;.
                    <br />
                    Sinon, contactez le bureau de la ligue.
                  </p>
                )}
              {searchMode && loading && (
                <p className="px-10 text-center">Chargement...</p>
              )}
              {searchMode && !loading && clubs.length === 0 && (
                <p className="px-10 text-center">
                  Aucun club ne correspond à votre recherche
                </p>
              )}
              <InfiniteScroll
                dataLength={20}
                next={!searchMode ? listClub : searchClubs}
                hasMore={
                  typeof clubs !== 'undefined' && clubs.length === currentLimit
                }
                loader={
                  loading && <h4 className="text-center">Chargement...</h4>
                }
                endMessage={
                  currentLimit % 50 > 20 && (
                    <p style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                      <b>Vous avez atteint la fin !</b>
                    </p>
                  )
                }
                refreshFunction={!searchMode ? listClub : searchClubs}
              >
                <div className="flex flex-wrap justify-start gap-4 p-4">
                  {typeof clubs !== 'undefined' &&
                    clubs.map((ic) => {
                      return (
                        <div
                          key={ic.id}
                          className="break-inside relative mb-4 flex w-full max-w-[23rem] flex-col justify-between space-y-2 overflow-hidden rounded-xl bg-default p-4 text-sm text-white"
                        >
                          <span className="text-xs uppercase text-[#D2BDFF]">
                            {ic.data.name}
                          </span>
                          <div className="flex flex-row items-center space-x-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              fill="currentColor"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v4h-2V7zm3 5h2v4h-2v-4zm-6 0h2v4H8v-4z" />
                            </svg>
                            <span>Ligue: </span>
                            <span className="ml-1 text-base font-medium">
                              {ic.data?.ligue?.name}
                            </span>
                          </div>
                          <div className="flex flex-row items-center space-x-3">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              fill="currentColor"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-4.783 4.995L12 15a5 5 0 0 1-5-5V7a5 5 0 0 1 4.783-4.995L12 2zm0 2a3 3 0 0 0-2.995 2.824L9 7v3a3 3 0 0 0 5.995.176L15 10V7a3 3 0 0 0-3-3z" />
                            </svg>
                            <span>Joueurs: </span>
                            <span className="ml-1 text-base font-medium">
                              {ic.userCount}
                            </span>
                          </div>
                          <div className="flex items-center justify-between">
                            <button
                              type="button"
                              className="transtion-all text-opacity-90 duration-75 hover:font-bold hover:text-opacity-100"
                              onClick={() => displayPopUp(ic)}
                            >
                              Modifier
                            </button>
                            <button
                              type="button"
                              className="flex items-center justify-center space-x-1 rounded-full bg-white px-4 py-2 text-xs font-medium text-black hover:bg-opacity-90"
                              onClick={() => openClub(ic.data.id)}
                            >
                              <span>Entrer dans le club</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#000000"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path d="M5 12h13M12 5l7 7-7 7" />
                              </svg>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </InfiniteScroll>

              {showPopUp && (
                <div className="sticky-0 fixed top-0 left-0 z-[999] flex h-full w-full justify-center overflow-auto bg-gray-900 bg-opacity-80 px-3">
                  <div className="container relative my-20 min-h-full">
                    <div className="relative mx-auto max-w-3xl">
                      <div className="rounded-xl bg-white px-4 pt-16 pb-8 xl:pt-24 xl:pb-12">
                        {stepPopupCreate()}
                        <button
                          type="button"
                          className="absolute top-8 right-8 text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2"
                          onClick={() => displayPopUp()}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 6L6 18"
                              stroke="currentColor"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6 6L18 18"
                              stroke="currentColor"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Club;
