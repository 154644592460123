import { publicRequest, userRequest } from '.';

export const getAllLicences = async () => {
  return publicRequest.get('licences');
};

export const getLicences = async (offset) => {
  return userRequest.get(`licences?offset=${offset}`);
};

export const postLicence = async (license) => {
  return userRequest.post(`licences`, license);
};

export const putLicence = async (id, license) => {
  return userRequest.put(`licences/${id}`, license);
};

export const checkLicense = async (number) => {
  return publicRequest.get(`licences/verify/${number}`);
};
