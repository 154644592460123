/* eslint-disable react/no-unknown-property */
/* eslint-disable prettier/prettier */
// eslint-disable-next-line import/no-unresolved
import 'xtendui/src/overlay';

import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Xt } from 'xtendui';
import * as Yup from 'yup';

import { TOKEN } from './api';
import { getAllLigues } from './api/ligue';
import { getClubs, getUserRole, register, resend } from './api/user';
import countries from './assets/countries.json';
import ButtonLoading from './components/ButtonLoading';

const passwordMinLength = (value) => {
  let error;
  if (value.length < 6) {
    error = '6 caractères minimum';
  }
  return error;
};

const validatePassword = (value) => {
  return passwordMinLength(value);
};

const validateConfirmPassword = (value) => {
  return passwordMinLength(value);
};

const formikField = (field) => {
  if (field.type === 'select') {
    if (field.hasValidation) {
      return (
        <Field
          as="select"
          name={field.name}
          className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
          validate={field.validate}
          placeholder={field.placeholder}
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    return (
      <Field
        as="select"
        name={field.name}
        className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
        placeholder={field.placeholder}
      >
        {field.listOptions.map((option) => {
          return <option value={option.value}>{option.name}</option>;
        })}
      </Field>
    );
  }
  if (field.hasValidation) {
    return (
      <Field
        name={field.name}
        type={field.type}
        className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
        validate={field.validate}
        placeholder={field.placeholder}
      />
    );
  }
  return (
    <Field
      name={field.name}
      type={field.type}
      className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
      placeholder={field.placeholder}
    />
  );
};
const Register = () => {
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [leagues, setLeagues] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [leagueId, setLeagueId] = useState();
  const [isBenin, setIsBenin] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [isSending, setisSending] = useState(false);
  const ref = useRef(null);
  const registerRef = useRef(null);

  const LoginSchema = Yup.object().shape({
    firstName: Yup.string().required('Prénom requis'),
    lastName: Yup.string().required('Nom requis'),
    birthDate: Yup.string().required('Date de naissance'),
    email: Yup.string().email('Email incorrect').required('Email requis'),
    country: Yup.string().required('Pays requis'),
    ligue: Yup.string(),
    club: leagueId ? Yup.string().required('Club requis') : Yup.string(),
    password: Yup.string().required('Mot de passe requis'),
    confirm: Yup.string().required('Mot de passe requis'),
  });

  const getAllLeagues = () => {
    getAllLigues().then((res) => {
      setLeagues([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((league) => {
          return {
            value: league?.ligue?.id,
            name: league?.ligue?.name,
          };
        }),
      ]);
      setLeagueId(res.data[0].id);
    });
  };

  const validateCountry = (value) => {
    if (value === 'BJ') {
      setIsBenin(true);
      getAllLeagues();
    }
  };
  const requestClubs = (ligueId) => {
    getClubs(ligueId).then((res) => {
      setClubs([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((club) => {
          return {
            value: club?.club?.id,
            name: club?.club?.name,
          };
        }),
      ]);
    });
  };
  const validateLigue = (value) => {
    if (value) {
      setLeagueId(value);
    }
  };

  const list = [
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Nom',
      show: true,
      hasValidation: false,
    },
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'Prénom',
      show: true,
      hasValidation: false,
    },
    {
      name: 'birthDate',
      type: 'date',
      placeholder: 'Date de naissance',
      show: true,
      hasValidation: false,
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      show: true,
      hasValidation: false,
    },
    {
      name: 'country',
      type: 'select',
      listOptions: [
        {
          value: '',
          name: 'Sélectionner',
        },
        ...countries.fr,
      ],
      placeholder: 'Pays',
      show: true,
      hasValidation: true,
      validate: validateCountry,
    },
    {
      name: 'ligue',
      type: 'select',
      listOptions: leagues,
      placeholder: 'Ligue',
      show: isBenin,
      hasValidation: true,
      validate: validateLigue,
    },
    {
      name: 'club',
      type: 'select',
      listOptions: clubs,
      placeholder: 'Club',
      show: isBenin,
      hasValidation: false,
    },
    {
      name: 'password',
      type: 'password',
      placeholder: 'Mot de passe',
      show: true,
      hasValidation: true,
      validate: validatePassword,
    },
    {
      name: 'confirm',
      type: 'password',
      placeholder: 'Confirmer mot de passe',
      show: true,
      hasValidation: true,
      validate: validateConfirmPassword,
    },
  ];

  const verifyPasswordEqual = (password, confirmpassword) => {
    let success = true;
    if (password !== confirmpassword) {
      setMessage([false, 'Mot de passe doit être identique']);
      success = false;
    } else {
      setMessage([true, '']);
    }
    return success;
  };

  const showSuccess = () => {
    const overlay = registerRef.current?.querySelector(
      '#overlay--eventmethods'
    );

    const self = new Xt.Overlay(overlay, {
      targets: '#overlay--nested',
    });

    const els = self.elements;
    els[0].dispatchEvent(new CustomEvent('on.trigger.xt.overlay'));
  };

  const close = () => {
    const overlay = registerRef.current?.querySelector(
      '#overlay--eventmethods'
    );

    const self = new Xt.Overlay(overlay, {
      targets: '#overlay--nested',
    });

    const els = self.elements;
    els[0].dispatchEvent(new CustomEvent('destroy.trigger.xt.overlay'));
  };

  const addNewUser = (user) => {
    setLoading(true);
    register(user)
      .then(() => {
        setMessage([
          true,
          'Inscription réussie. Connectez vous pour continuer.',
        ]);
        setLoading(false);
        showSuccess();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setMessage([false, 'Cette adresse email est déjà utilisée par un autre utilisateur']);
        } else {
          setMessage([
            false,
            "Une erreur est survenue. Veuillez réessayer"
          ]);
        }
        setLoading(false);
      });
  };

  const resendToUser = () => {
    let user = ref.current.values;
    setisSending(true);
    getUserRole().then((res) => {
      user = {
        ...user,
        roleId: res.data.id,
      };
      if (!isBenin) {
        delete user.ligue;
        delete user.club;
      }
      delete user.confirm;
      resend(user)
        .then(() => {
          setIsSend(true);
          setisSending(false);
        })
        .catch(() => {
          setIsSend(false);
        });
    });
  };

  const init = () => {
    const resendButton =
      registerRef.current?.querySelector('#send-again-button');

    resendButton.addEventListener('click', resendToUser);
  };

  const destroy = () => {
    const resendButton =
      registerRef.current?.querySelector('#send-again-button');

    resendButton.removeEventListener('click', resendToUser);
  };

  useEffect(() => {
    init();
    close();
  }, []);

  useEffect(() => {
    return () => {
      destroy();
    };
  }, []);

  useEffect(() => {
    if (leagueId) requestClubs(leagueId);
  }, [leagueId]);

  useEffect(() => {
    if (TOKEN) navigator('/');
  }, [TOKEN]);

  return (
    <div
      ref={registerRef}
      className="ease-soft-in-out relative mt-0 transition-all duration-200"
    >
      <section className="mb-12">
        <div
          className="min-h-50-screen relative m-4 flex items-start overflow-hidden rounded-xl bg-cover bg-center pt-12 pb-56"
          style={{
            backgroundImage: 'url(/bg-clubs-associations.jpg)',
          }}
        >
          <span className="absolute top-0 left-0 h-full w-full bg-gradient-to-tl from-gray-900 to-slate-800 bg-cover bg-center opacity-60" />
          <div className="container z-10">
            <div className="-mx-3 flex flex-wrap justify-center">
              <div className="lg:flex-0 mx-auto mt-0 w-full max-w-full shrink-0 px-3 text-center lg:w-5/12">
                <h1 className="mt-12 mb-2 text-white">
                  Bienvenue sur LigueAlPetanque!
                </h1>
                <p className="text-white">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Aenean ac nisl tristique.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="-mx-3 -mt-48 flex flex-wrap md:-mt-56 lg:-mt-48">
            <div className="md:flex-0 mx-auto mt-0 w-full max-w-full shrink-0 px-3 md:w-7/12 lg:w-5/12 xl:w-4/12">
              <div className="shadow-soft-xl relative z-0 flex min-w-0 flex-col break-words rounded-2xl border-0 bg-white bg-clip-border">
                <img
                  src="/logo-2.png"
                  alt="logo"
                  className="mx-auto mt-8 h-32 w-32"
                />

                <div className="flex-auto p-6">
                  <Formik
                    innerRef={ref}
                    initialValues={{
                      firstName: '',
                      lastName: '',
                      birthDate: '',
                      email: '',
                      country: '',
                      ligue: '',
                      club: '',
                      password: '',
                      confirm: '',
                    }}
                    validationSchema={LoginSchema}
                    onSubmit={(values) => {
                      const success = verifyPasswordEqual(
                        values.password,
                        values.confirm
                      );
                      if (success) {
                        if (!loading) {
                          setLoading(true);
                          getUserRole()
                            .then((res) => {
                              const user = {
                                email: values.email,
                                password: values.password,
                                firstName: values.firstName,
                                lastName: values.lastName,
                                birthDate: new Date(values.birthDate),
                                country: values.country,
                                roleId: res.data.id,
                              };
                              if (isBenin) {
                                user.ligueId = values.ligue;
                                user.clubId = values.club;
                              }
                              addNewUser(user);
                            })
                            .catch((err) => {
                              if (err.response.status === 401) {
                                setMessage([false, 'Réessayez plus tard !!!']);
                              } else {
                                setMessage([
                                  false,
                                  'Erreur !!! Réessayez plus tard.',
                                ]);
                              }
                              setLoading(false);
                            });
                        }
                      }
                    }}
                  >
                    {({ errors, touched }) => (
                      <Form className="flex w-full flex-col space-y-4 ">
                        <div className="flex flex-wrap justify-between gap-y-4">
                          {list.map((field) => {
                            return (
                              field.show && (
                                <div
                                  key={field.name}
                                  className="flex w-full flex-col"
                                >
                                  {formikField(field)}
                                  {errors[field.name] && touched[field.name] ? (
                                    <div className="text-red-400">
                                      {errors[field.name]}
                                    </div>
                                  ) : null}
                                </div>
                              )
                            );
                          })}
                        </div>

                        <div
                          className={`w-full items-center justify-center text-center text-sm ${
                            message[0] ? 'text-primary' : 'text-secondary'
                          }`}
                        >
                          {message[1]}
                        </div>

                        <div className="min-h-6 mb-0.5 block">
                          <label
                            className="mb-2 cursor-pointer select-none text-sm font-normal"
                            htmlFor="terms"
                          >
                            En soumettant ce formulaire, j&apos;accepte les{' '}
                            <a
                              href={`${process.env.REACT_APP_WEB_SITE_URL}/politique-de-confidentialité`}
                              className="font-bold underline"
                            >
                              Conditions Générales d&apos;Utilisation
                            </a>
                          </label>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="active:opacity-85 hover:scale-102 hover:shadow-soft-xs leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mt-6 mb-2 inline-block w-full cursor-pointer rounded-lg border-0 bg-gradient-to-tl from-primary to-primary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary hover:bg-primary hover:text-white"
                          >
                            {loading ? 'En cours' : "S'inscrire"}
                            {loading && <ButtonLoading />}
                          </button>
                        </div>
                        <p className="mt-4 mb-0 text-sm text-center text-inherit leading-normal">
                          Vous avez déjà un compte ?{' '}
                          <a
                            href="/signin"
                            className="font-bold text-primary"
                          >
                            Connectez-vous
                          </a>
                        </p>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer className="pb-12">
        <div className="container">
          <div className="-mx-3 flex flex-wrap">
            <div className="lg:flex-0 mx-auto mb-6 w-full max-w-full flex-shrink-0 text-center lg:w-8/12">
              <a
                href="/verifier-licence"
                target="_blank"
                className="mb-2 mr-4 text-slate-400 hover:text-secondary sm:mb-0 xl:mr-12"
                rel="noreferrer"
              >
                Vérifier une licence
              </a>
              <a
                href={`${process.env.REACT_APP_WEB_SITE_URL}`}
                target="_blank"
                className="mb-2 mr-4 text-slate-400 hover:text-secondary sm:mb-0 xl:mr-12"
                rel="noreferrer"
              >
                Revenir sur l&apos;Acceuil
              </a>
            </div>
            <div className="lg:flex-0 mx-auto mt-2 mb-6 flex w-full max-w-full flex-shrink-0 items-center justify-center text-center lg:w-8/12">
              <a
                href="/"
                target="_blank"
                className="mr-6 text-slate-400 hover:text-secondary"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_8_2)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 12.067C0 18.033 4.333 22.994 10 24V15.333H7V12H10V9.333C10 6.333 11.933 4.667 14.667 4.667C15.533 4.667 16.467 4.8 17.333 4.933V8H15.8C14.333 8 14 8.733 14 9.667V12H17.2L16.667 15.333H14V24C19.667 22.994 24 18.034 24 12.067C24 5.43 18.6 0 12 0C5.4 0 0 5.43 0 12.067Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_8_2">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </a>
              <a
                href="/"
                target="_blank"
                className="mr-6 text-slate-400 hover:text-secondary"
                rel="noreferrer"
              >
                <svg
                  width="26"
                  height="21"
                  viewBox="0 0 26 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.11849 21C17.8717 21 23.207 12.9187 23.207 5.9228C23.207 5.69666 23.207 5.46729 23.1973 5.24115C24.236 4.4892 25.1325 3.55825 25.8448 2.49191C24.8745 2.91972 23.8463 3.20168 22.7935 3.32863C23.9024 2.66578 24.733 1.62283 25.1308 0.393637C24.0889 1.01088 22.9486 1.44418 21.7597 1.67457C20.9604 0.823345 19.9027 0.259434 18.7505 0.0701857C17.5983 -0.119063 16.4158 0.0769118 15.3863 0.627754C14.3567 1.1786 13.5375 2.05356 13.0556 3.11713C12.5736 4.18069 12.4559 5.37349 12.7205 6.51077C10.6121 6.40505 8.54948 5.85733 6.66641 4.90314C4.78333 3.94894 3.12186 2.60957 1.78975 0.971914C1.11349 2.13991 0.907109 3.5215 1.21252 4.83614C1.51793 6.15077 2.31223 7.29989 3.43412 8.05015C2.59343 8.02159 1.77121 7.79584 1.03379 7.39111V7.4638C1.03524 8.68736 1.45911 9.87289 2.23375 10.82C3.0084 11.7671 4.0863 12.4177 5.28526 12.6618C4.83017 12.7872 4.36006 12.8497 3.88802 12.8476C3.55525 12.8486 3.22314 12.8178 2.89623 12.7555C3.2351 13.8088 3.89493 14.7297 4.7833 15.3892C5.67167 16.0488 6.74409 16.4139 7.85035 16.4336C5.97104 17.9096 3.64961 18.7102 1.25993 18.7063C0.838858 18.7081 0.418077 18.6838 0 18.6336C2.42539 20.1799 5.24212 21.0009 8.11849 21V21Z"
                    fill="currentColor"
                  />
                </svg>
              </a>
            </div>
          </div>
          <div className="-mx-3 flex flex-wrap">
            <div className="flex-0 mx-auto mt-1 w-8/12 max-w-full px-3 text-center">
              <p className="mb-0 text-slate-400">
                Copyright © Tous droits réservés. @ {new Date().getFullYear()}
              </p>
            </div>
          </div>
        </div>
      </footer>

      <div id="overlay--nested" aria-label="Modal" className="group xt-overlay">
        <div className="xt-backdrop z-below bg-gray-800 opacity-0 transition group-in:opacity-25" />
        <div className="xt-overlay-container max-w-3xl">
          <div className="xt-overlay-inner -translate-y-4 opacity-0 transition group-in:translate-y-0 group-in:opacity-100 group-out:translate-y-4">
            <div className="xt-links-default xt-card rounded-2xl bg-white text-gray-900 shadow-xl">
              <button
                type="button"
                className="xt-dismiss xt-button absolute top-0 right-0 z-above p-5 text-2xl"
                aria-label="Close"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="xt-icon "
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              </button>
              <div className="p-7 text-base sm:p-9">
                <div className="xt-h4">Bienvenue</div>
                <p>
                  Votre compte a été créé avec succès. Un email de confirmation
                  vous a été envoyé. Mais vous pouvez toujours vous connecter
                </p>
                <p>
                  Cliquez sur le lien dans l&apos;email pour activer votre
                  compte.
                </p>

                <p>
                  Je n&apos;ai pas reçu l&apos;email de confirmation.{' '}
                  <button
                    type="button"
                    className="resend-button xt-link flex text-primary"
                    id="send-again-button"
                  >
                    Renvoyer à nouveau
                    {isSending && (
                      <span className="xt-spinner relative inset-0 m-auto ml-2 h-6 w-6 text-primary">
                        <svg
                          viewBox="0 0 240 240"
                          className="absolute"
                          preserveAspectRatio="xMinYMin meet"
                        >
                          <circle
                            className="origin-center stroke-current opacity-25"
                            fill="none"
                            strokeWidth="30"
                            cx="120"
                            cy="120"
                            r="100"
                            strokeDasharray="628"
                            strokeDashoffset="0"
                            pathLength="628"
                          />
                        </svg>
                        <svg viewBox="0 0 240 240">
                          <circle
                            className="xt-icon relative origin-center animate-xt-spinner stroke-current"
                            fill="none"
                            strokeWidth="30"
                            cx="120"
                            cy="120"
                            r="100"
                            strokeDasharray="628"
                            strokeDashoffset="628"
                            pathLength="628"
                          />
                        </svg>
                      </span>
                    )}
                  </button>
                </p>

                {isSend && (
                  <div className="text-left">
                    <p className="text-primary">
                      Un email de confirmation vous a été envoyé.
                    </p>
                  </div>
                )}

                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    className="xt-dismiss xt-button rounded-xl bg-secondary py-2 px-4 text-white"
                    aria-label="Close"
                  >
                    Fermer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span
        type="button"
        className="absolute top-0 right-0 z-below hidden"
        id="overlay--eventmethods"
      >
        Overlay
      </span>
    </div>
  );
};
export default Register;
