/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/no-unknown-property */
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { getAllLicences, postLicence, putLicence } from './api/license';
import { getUsers, searchUsers } from './api/user';
import ButtonLoading from './components/ButtonLoading';

const licenseSchema = Yup.object().shape({
  user: Yup.string().required('Veuillez choisir un utilisateur'),
  number: Yup.string().required('Veuillez saisir un numéro de licence'),
  expirationDate: Yup.string().required(
    "Veuillez saisir une date d'expiration"
  ),
});

const formikField = (field) => {
  if (field.hasValidation) {
    if (field.type === 'textarea') {
      return (
        <Field
          name={field.name}
          as="textarea"
          placeholder={field.placeholder}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
          validate={field.validate}
        />
      );
    }
    return (
      <Field
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
        validate={field.validate}
      />
    );
  }
  if (field.type === 'textarea') {
    return (
      <Field
        as="textarea"
        name={field.name}
        placeholder={field.placeholder}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
      />
    );
  }
  return (
    <Field
      name={field.name}
      type={field.type}
      placeholder={field.placeholder}
      className="w-full rounded border border-gray-300 bg-white p-3.5"
    />
  );
};

const Licenses = () => {
  const [licenses, setLicenses] = useState(null);
  const [showPopUp, setShowPopUp] = useState(false);
  const [step, setStep] = useState('create');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [search, setSearch] = useState('');
  const [openList, setOpenList] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [offset] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [licenseToUpdate, setLicenseToUpdate] = useState({});
  const formRef = useRef();

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const filterUsers = (users) => {
    let list = [];
    users.forEach((u) => {
      list = [
        ...list,
        {
          name: `${u.firstName} ${u.lastName}`,
          value: u.id,
        },
      ];
    });
    setUsersList(list);
  };

  const getAllUsers = () => {
    getUsers(offset, page)
      .then((res) => {
        filterUsers(res.data);
        // setTotalPage(res.data.totalPage);
        setTimeout(() => {}, 500);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setTimeout(() => {}, 500);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const list = [
    {
      name: 'number',
      type: 'text',
      title: 'N° License',
      placeholder: '8541841817848',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'expirationDate',
      type: 'date',
      title: "Date d'expiration",
      placeholder: 'Sélectionnez la date',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
  ];

  const displayPopUp = (data) => {
    setShowPopUp(!showPopUp);
    setMessage([true, '']);
    if (!data?.id) {
      setStep('create');
    } else {
      setStep('update');
      setLicenseToUpdate(data);
      // Set selected user id
      setSelectedUser(data.user[0].id);
    }
  };

  const onSearchPlayer = (e, size) => {
    e.preventDefault();
    setLoadingList(true);
    formRef.current.setFieldValue('user', e.target.value);

    if (e.target.value?.length > 0) {
      setOpenList(true);
      searchUsers(e.target.value, undefined, true)
        .then((res) => {
          setUsersList(res.data);
          setLoadingList(false);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          }
          setLoadingList(false);
        });
    } else {
      setLoadingList(false);
    }
  };

  const selectUser = (i) => {
    setOpenList(false);
    setSelectedUser(i);
    formRef.current.setFieldValue('user', `${i.lastName} ${i.firstName}`);
  };

  const getLicenses = () => {
    getAllLicences(50)
      .then((res) => {
        setLicenses(res.data);
      })
      .catch(() => {});
  };

  // Function to change the license status
  const changeLicenseStatus = (idLicense, license, newStatus) => {
    // request
    setIsProcessing(true);
    putLicence(idLicense, {
      status: newStatus,
    })
      .then((res) => {
        setIsProcessing(false);
        getLicenses();
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setIsProcessing(false);
      });
  };

  const stepPopupCreate = () => {
    return (
      <div className="w-full">
        <div className="xt-h4 text-center">
          {step !== 'update' ? 'Ajouter une licence' : 'Modifier une license'}
        </div>
        <Formik
          innerRef={formRef}
          initialValues={
            step === 'update'
              ? {
                  user: `${licenseToUpdate?.user[0].firstName} ${licenseToUpdate?.user[0].lastName}`,
                  number: licenseToUpdate?.number,
                  expirationDate: licenseToUpdate?.expiration_date.slice(0, 10),
                }
              : {
                  user: '',
                  number: '',
                  expirationDate: '',
                }
          }
          validationSchema={licenseSchema}
          onSubmit={(values, { setErrors, setValues }) => {
            let errors = {};
            if (!values.user) {
              errors = {
                ...errors,
                type: 'Veuillez sélectionner un utilisateur',
              };
            }

            setErrors(errors);

            if (Object.keys(errors).length > 0) {
              return;
            }

            setLoading(true);

            if (step === 'create') {
              postLicence({
                userId: selectedUser.id,
                number: values.number,
                expiration_date: new Date(values.expirationDate),
              })
                .then((res) => {
                  setLoading(false);
                  setMessage([true, 'License créée avec succès']);
                  setTimeout(() => {
                    setMessage([false, '']);
                    setShowPopUp(false);
                  }, 2000);
                })
                .catch((err) => {
                  setLoading(false);
                  if (
                    err.response?.status === 401 ||
                    err.response?.status === 403
                  ) {
                    logOut();
                  }
                  setMessage([false, err.response?.data?.message]);
                });
            } else {
              putLicence(licenseToUpdate, {
                userId: selectedUser.id,
                number: values.number,
                expiration_date: new Date(values.expirationDate),
              })
                .then((res) => {
                  setLoading(false);
                  setMessage([true, 'License modifiée avec succès']);
                  setLicenseToUpdate({});
                  setTimeout(() => {
                    setMessage([false, '']);
                    setShowPopUp(false);
                  }, 2000);
                })
                .catch((err) => {
                  setLoading(false);
                  if (
                    err.response?.status === 401 ||
                    err.response?.status === 403
                  ) {
                    logOut();
                  }
                  setMessage([false, err.response?.data?.message]);
                });
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className="flex flex-col gap-4 pt-8">
              <div className="flex flex-wrap justify-between gap-y-4 space-x-1">
                <div className="w-full">
                  <div className="relative w-full">
                    <div className="w-full rounded border border-gray-300 bg-white p-1.5">
                      <div className="relative flex items-center">
                        <input
                          type="text"
                          placeholder="Rechercher un utilisateur"
                          className="mr-8 w-full border-none bg-white focus:outline-none focus:ring-0"
                          value={formRef?.current?.values?.user}
                          onChange={(e) => {
                            onSearchPlayer(e);
                          }}
                        />
                        <div className="absolute top-2 right-10">
                          <button
                            type="button"
                            className="pointer-events-none absolute inset-y-0 left-0 h-full w-8 bg-white pl-2.5"
                          >
                            {!loadingList ? (
                              <svg
                                className="stroke-gray-400"
                                viewBox="0 0 256 256"
                                aria-hidden="true"
                              >
                                <circle
                                  cx="116"
                                  cy="116"
                                  r="84"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                />
                                <line
                                  x1="175.4"
                                  y1="175.4"
                                  x2="224"
                                  y2="224"
                                  fill="none"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="16"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                width="24"
                                height="24"
                                className="animate-spin"
                              >
                                <path fill="none" d="M0 0h24v24H0z" />
                                <path d="M3.055 13H5.07a7.002 7.002 0 0 0 13.858 0h2.016a9.001 9.001 0 0 1-17.89 0zm0-2a9.001 9.001 0 0 1 17.89 0H18.93a7.002 7.002 0 0 0-13.858 0H3.055z" />
                              </svg>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {openList && (
                      <div className="max-h-select absolute top-100 left-0 z-40 w-full overflow-y-auto rounded bg-white shadow">
                        {!loadingList && usersList?.length <= 0 && (
                          <div className="mx-2 -mt-1 flex-1 justify-center text-center">
                            <span>Aucun résultat </span>
                          </div>
                        )}
                        {usersList.map((user) => (
                          <button
                            type="button"
                            className={`group flex w-full cursor-pointer flex-col ${
                              selectedUser?.id === user?.id
                                ? 'font-medium text-primary'
                                : 'text-gray-700'
                            }`}
                            onClick={() => selectUser(user)}
                          >
                            <div className="relative flex w-full items-center border-x-2 border-transparent p-2 pl-2 hover:border-red-100 hover:bg-primary hover:text-white">
                              <div className="flex w-full items-center">
                                <div className="mx-2 -mt-1 flex-1 text-left">
                                  <div className="w-full truncate text-xs font-normal normal-case text-gray-500 group-hover:text-white">
                                    {user?.lastName} {user?.firstName}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  {errors.user && touched.user ? (
                    <div className="text-red-400">{errors.user}</div>
                  ) : null}
                </div>

                {list.map((field) => {
                  return (
                    field.show && (
                      <div
                        key={field.name}
                        className={`flex w-full flex-col md:${
                          field.semiWidth && 'ml-1 w-5/12'
                        }`}
                        onChange={() => {
                          setMessage({});
                        }}
                      >
                        <div className="flex flex-col items-start justify-start space-y-1">
                          <h3 className="text-xl leading-normal text-gray-900">
                            {[field.title]}
                          </h3>
                        </div>

                        <div className="relative w-full items-center">
                          {formikField(field)}
                          {errors[field.name] && touched[field.name] ? (
                            <div className="text-red-400">
                              {errors[field.name]}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
              <div
                className={`text-center text-sm ${
                  message[0] ? 'text-transparent' : 'text-red-500'
                }`}
              >
                {message[1]}
              </div>
              <div className="flex w-full justify-between">
                <button
                  onClick={() => displayPopUp()}
                  type="button"
                  className={`xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-2 ml-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-secondary bg-transparent bg-none px-4 py-3 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:border-secondary
                                  ${
                                    !loading && 'duration-300 hover:scale-105'
                                  }`}
                  data-class="bg-white"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                  data-class="bg-transparent"
                >
                  {loading
                    ? 'En cours'
                    : step === 'create'
                    ? 'Ajouter'
                    : 'Modifier'}
                  {loading && <ButtonLoading />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  useEffect(() => {
    // Fetching all the licenses
    getLicenses();
  }, []);

  // Pagination function
  const paginate = (s) => {
    switch (s) {
      // if page > 1 && step = -1 : prev
      case -1:
        if (page > 1) {
          const prevPage = page - 1;
          setPage(prevPage);
        }
        break;

      // page < totalPage && step = 1 : next
      case 1:
        if (page <= totalPage) {
          const nextPage = page + 1;
          setPage(nextPage);
        }
        break;

      // default
      default:
        break;
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'active':
        return (
          <span className="inline-block rounded-full bg-green-200 py-1 px-2 text-xs font-semibold uppercase text-green-600">
            Actif
          </span>
        );
      case 'inactive':
        return (
          <span className="inline-block rounded-full bg-gray-200 py-1 px-2 text-xs font-semibold uppercase text-gray-600">
            Inactif
          </span>
        );
      case 'suspended':
        return (
          <span className="inline-block rounded-full bg-red-200 py-1 px-2 text-xs font-semibold uppercase text-red-600">
            Suspendu
          </span>
        );
      case 'expired':
        return (
          <span className="inline-block rounded-full bg-yellow-200 py-1 px-2 text-xs font-semibold uppercase text-yellow-600">
            Expiré
          </span>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section className="relative w-full md:min-h-screen">
              <div className="body-font container text-gray-600">
                <div className="xt-row mt-6 flex items-center justify-between">
                  <h2 className="title-font py-8 text-left text-2xl font-medium text-gray-900 sm:text-3xl">
                    Licences
                  </h2>

                  <button
                    type="button"
                    className="mt-6 flex rounded-full bg-primary py-4 px-7 font-semibold text-white md:mt-0"
                    onClick={() => displayPopUp()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                        fill="currentColor"
                      />
                    </svg>{' '}
                    Ajouter
                  </button>
                </div>

                <div className="w-full overflow-x-auto">
                  <table
                    className="w-full border-collapse rounded border border-slate-200 text-left sm:border-separate"
                    cellSpacing="0"
                  >
                    <tbody>
                      <tr>
                        <th
                          scope="col"
                          className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                        >
                          #
                        </th>
                        <th
                          scope="col"
                          className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                        >
                          NUMÉRO
                        </th>
                        <th
                          scope="col"
                          className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                        >
                          STATUS
                        </th>
                        <th
                          scope="col"
                          className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                        >
                          DATE D&apos;EXPIRATION
                        </th>
                        <th
                          scope="col"
                          className="h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0"
                        >
                          Actions
                        </th>
                      </tr>
                      <tr />
                      {licenses &&
                        licenses.length > 0 &&
                        licenses.map((license) => {
                          return (
                            <tr key={license.id}>
                              <th className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0">
                                {`${license.user[0].lastName} ${license.user[0].firstName}`}
                              </th>
                              <th className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0">
                                {license.number}
                              </th>
                              <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0">
                                {renderStatus(license.status)}
                              </td>
                              <td className="h-12 border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0">
                                {Intl.DateTimeFormat('fr-FR', {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                                }).format(new Date(license.expiration_date))}
                              </td>
                              <td className="border-t border-l border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 first:border-l-0">
                                <div className="flex space-x-4 p-1">
                                  <button
                                    type="button"
                                    className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative my-1 mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-secondary bg-gradient-to-tl from-secondary to-default/10 p-1 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-secondary"
                                    data-class="bg-transparent"
                                    onClick={() => {
                                      changeLicenseStatus(
                                        license.id,
                                        license,
                                        license?.status === 'active'
                                          ? 'suspended'
                                          : 'active'
                                      );
                                    }}
                                  >
                                    {isProcessing && 'En cours'}
                                    {!isProcessing &&
                                      license.status === 'active' &&
                                      'Suspendre'}
                                    {!isProcessing &&
                                      license.status === 'suspended' &&
                                      'Activer'}
                                    {isProcessing && (
                                      <span className="xt-loader absolute inset-0 z-content overflow-hidden rounded-inherit">
                                        <span className="xt-filler absolute inset-0 m-auto text-white text-opacity-25">
                                          <span className="absolute block h-full w-full bg-secondary bg-opacity-5" />
                                          <span className="absolute block h-full animate-xt-filler-x bg-secondary bg-opacity-50" />
                                        </span>
                                      </span>
                                    )}
                                  </button>{' '}
                                  <button
                                    type="button"
                                    className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative my-1 mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 p-1 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                                    data-class="bg-transparent"
                                    onClick={() => displayPopUp(license)}
                                  >
                                    Modifier
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>

                  {loading && (
                    <div className="block w-full border border-t-0 border-slate-200 py-2 text-center">
                      <p>Chargement...</p>
                    </div>
                  )}
                  {licenses && licenses.length === 0 && (
                    <div className="border border-slate-200 py-4 text-center">
                      Aucune licence
                    </div>
                  )}

                  {/* Pagination */}
                  <nav
                    role="navigation"
                    aria-label="Pagination Navigation"
                    className="mt-8"
                  >
                    <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
                      <li>
                        <button
                          type="button"
                          aria-label="Goto Page 1"
                          className="inline-flex h-10 cursor-pointer items-center justify-center gap-4
                     rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700
                      transition duration-300 focus:bg-primary/10
                      focus:stroke-primary focus:text-primary focus-visible:outline-none
                       hover:bg-primary/10 hover:stroke-primary  hover:text-primary"
                          disabled={`${page === 1 ? 'disabled' : ''}`}
                          onClick={() => paginate(-1)}
                        >
                          <span className="order-2">Précédent</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="-mx-1 h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            role="graphics-symbol"
                            aria-labelledby="title-09 desc-09"
                          >
                            <title id="title-09">Page précédente</title>
                            <desc id="desc-09">lien à page précédente</desc>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        </button>
                      </li>

                      <li>
                        <button
                          type="button"
                          aria-label="Goto Page 3"
                          className="inline-flex h-10 cursor-pointer items-center justify-center gap-4
                     rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700
                      transition duration-300 focus:bg-primary/10
                      focus:stroke-primary focus:text-primary focus-visible:outline-none
                       hover:bg-primary/10 hover:stroke-primary  hover:text-primary"
                          onClick={() => paginate(1)}
                          disabled={`${page === totalPage ? 'disabled' : ''}`}
                        >
                          <span>Suivant </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="-mx-1 h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            role="graphics-symbol"
                            aria-labelledby="title-10 desc-10"
                          >
                            <title id="title-10">Page suivante</title>
                            <desc id="desc-10">lien à la page suivante</desc>
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>

                <div className="flex items-center justify-center">
                  {showPopUp && (
                    <div className="sticky-0 fixed inset-0 z-[999] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-80 px-3">
                      <div className="relative w-full max-w-md rounded-xl bg-white py-16 px-4 xl:py-24">
                        {stepPopupCreate()}
                        <button
                          type="button"
                          className="absolute top-8 right-8 text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2"
                          onClick={() => displayPopUp(-1)}
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M18 6L6 18"
                              stroke="currentColor"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M6 6L18 18"
                              stroke="currentColor"
                              strokeWidth="1.66667"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Licenses;
