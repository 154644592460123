/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { getDocuments, postDocument } from '../api/document';
import ButtonLoading from './ButtonLoading';

const DocumentForm = (props) => {
  const { title, type, activeTab } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({ type: '', text: '' });
  const [document, setDocument] = useState({
    file: null,
  });
  const [documents, setDocuments] = useState([]);
  const [offset] = useState(10);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(null);

  const assetsDir = process.env.REACT_APP_WEB_APP_URL_DOC_DIR;
  const documentSchema = Yup.object().shape({
    file: Yup.mixed().required('Veuillez choisir un fichier'),
  });
  const inputRef = useRef();

  const init = () => {
    switch (type) {
      case 'note':
        setDocument({
          name: 'note-circulaire',
        });
        break;
      case 'cr':
        setDocument({
          name: 'compte-rendu',
        });
        break;
      case 'pv':
        setDocument({
          name: 'proces-verbal',
        });
        break;
      default:
        setDocument({
          name: 'document',
        });
        break;
    }
  };

  const fetchDocuments = (o, p) => {
    getDocuments(o, p).then((res) => {
      if (res.data) {
        setDocuments(res.data.documents);
        setTotalPage(res.data.totalPage);
        setIsFetched(true);
        setIsFetching(false);
      }
    });
  };

  const fetch = async () => {
    setIsFetching(true);
    switch (type) {
      default:
        fetchDocuments(offset, page);
        break;
    }
  };

  // Pagination function
  const paginate = (step) => {
    switch (step) {
      // if page > 1 && step = -1 : prev
      case -1:
        if (page > 1) {
          const prevPage = page - 1;
          setPage(prevPage);
        }
        break;

      // page < totalPage && step = 1 : next
      case 1:
        if (page <= totalPage) {
          const nextPage = page + 1;
          setPage(nextPage);
        }
        break;

      // default
      default:
        break;
    }
  };

  useEffect(() => {
    init();
    if (activeTab === 'docList') {
      fetch();
    }
  }, [page]);

  const handleDocument = (data) => {
    setLoading(true);
    postDocument(data).then(
      () => {
        setLoading(false);
        setMessage({
          type: 'success',
          text: 'Le document a été ajouté avec succès',
        });
        props.setActiveTab('docList');
      },
      (err) => {
        setLoading(false);
        if (err.response?.status === 401) {
          setMessage({
            type: 'error',
            text: "Ce document a déjà été ajouté. Essayez d'en choisir un autre ou de le renommer",
          });
        } else {
          setMessage({
            type: 'error',
            text: "Une erreur est survenue lors de l'ajout du document",
          });
        }
      }
    );
  };

  return (
    <section className="body-font w-full text-gray-600">
      <div className="px-5">
        {activeTab === 'docForm' && (
          <div className="mb-2 space-y-4">
            <h2 className="title-font text-xl font-medium text-gray-900 sm:text-3xl">
              {title}
            </h2>
            <p className="text-lg font-thin text-slate-400">
              Taille maximale du fichier: 10 Mo
            </p>
            <Formik
              initialValues={document}
              validationSchema={documentSchema}
              onSubmit={(values) => {
                setLoading(true);
                const formData = new FormData();
                formData.append('name', values.file.name);
                formData.append('type', document.name);
                formData.append('file', values.file);

                handleDocument(formData);
              }}
            >
              {({ errors, touched, setFieldValue }) => (
                <Form>
                  <div className="mb-6">
                    <label
                      className="mb-2 block text-sm font-medium text-gray-900"
                      htmlFor="single_file"
                    >
                      Télécharger un fichier
                    </label>
                    <input
                      ref={inputRef}
                      id="single_file"
                      accept="image/*"
                      name="file"
                      type="file"
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        setFieldValue('file', file);
                        setDocument({
                          ...document,
                          file: URL.createObjectURL(file),
                        });
                      }}
                      className="h-10.5 block w-full cursor-pointer overflow-hidden rounded border border-gray-300 bg-gray-50 text-sm leading-9 text-gray-900 focus:outline-none"
                    />

                    {errors.file && touched.file ? (
                      <div
                        className="mt-1 text-sm text-red-400"
                        id="file_input_help"
                      >
                        {errors.file}
                      </div>
                    ) : null}
                  </div>

                  {message.type && (
                    <div
                      className={`my-4
                  ${
                    message.type === 'success'
                      ? 'text-green-500'
                      : 'text-red-500'
                  }`}
                    >
                      {message.text}
                    </div>
                  )}

                  <button
                    type="submit"
                    className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                    data-class="bg-transparent"
                  >
                    {loading ? 'Chargement...' : 'Télécharger'}
                    {loading && <ButtonLoading />}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        )}

        {activeTab === 'docList' && (
          <div>
            <h3>{title}</h3>

            <table
              className="w-full border-separate rounded border border-slate-200 text-left"
              cellSpacing="0"
            >
              <tbody>
                <tr>
                  <th className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell">
                    N°
                  </th>
                  <th
                    scope="col"
                    className="hidden h-12 flex-1 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="hidden h-12 flex-1 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                  >
                    Nom
                  </th>
                  <th
                    scope="col"
                    className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="hidden h-12 border-l bg-slate-100 stroke-slate-700 px-6 text-sm font-medium text-slate-700 first:border-l-0 sm:table-cell"
                  />
                </tr>
                {documents.map((doc, i) => (
                  <tr className="block border-b border-slate-200 last:border-b-0 sm:table-row sm:border-none">
                    <td
                      data-th="Name"
                      className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                    >
                      {i + 1}
                    </td>
                    <td
                      data-th="title"
                      className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                    >
                      {doc.name}
                    </td>
                    <td
                      data-th="title"
                      className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                    >
                      {doc.url.split('.')[0]}
                    </td>
                    <td
                      data-th="date"
                      className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                    >
                      {new Date(doc.createdAt).toLocaleDateString('fr-FR', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </td>
                    <td
                      data-th="action"
                      className="flex h-12 items-center border-slate-200 stroke-slate-500 px-6 text-sm text-slate-500 transition duration-300 before:inline-block before:w-24 before:font-medium before:text-slate-700 before:content-[attr(data-th)':'] first:border-l-0 sm:table-cell sm:border-t sm:border-l sm:before:content-none "
                    >
                      <a
                        href={`${assetsDir}${doc.url}`}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 underline"
                      >
                        Consulter
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!isFetched && isFetching && (
              <div className="block w-full border border-t-0 border-slate-200 py-2 text-center">
                <p>Chargement...</p>
              </div>
            )}
            {isFetched && !isFetching && documents.length === 0 && (
              <div className="block w-full border border-t-0 border-slate-200 py-2 text-center">
                <p>Aucun document disponible</p>
              </div>
            )}

            {/* Pagination */}
            <nav
              role="navigation"
              aria-label="Pagination Navigation"
              className="mt-8"
            >
              <ul className="flex list-none items-center justify-center text-sm text-slate-700 md:gap-1">
                <li>
                  <button
                    type="button"
                    aria-label="Goto Page 1"
                    className="inline-flex h-10 cursor-pointer items-center justify-center gap-4
                     rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700
                      transition duration-300 focus:bg-primary/10
                      focus:stroke-primary focus:text-primary focus-visible:outline-none
                       hover:bg-primary/10 hover:stroke-primary  hover:text-primary"
                    disabled={`${page === 1 ? 'disabled' : ''}`}
                    onClick={() => paginate(-1)}
                  >
                    <span className="order-2">Précédent</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="-mx-1 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      role="graphics-symbol"
                      aria-labelledby="title-09 desc-09"
                    >
                      <title id="title-09">Page précédente</title>
                      <desc id="desc-09">lien à page précédente</desc>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>
                </li>

                <li>
                  <button
                    type="button"
                    aria-label="Goto Page 3"
                    className="inline-flex h-10 cursor-pointer items-center justify-center gap-4
                     rounded stroke-slate-700 px-4 text-sm font-medium text-slate-700
                      transition duration-300 focus:bg-primary/10
                      focus:stroke-primary focus:text-primary focus-visible:outline-none
                       hover:bg-primary/10 hover:stroke-primary  hover:text-primary"
                    onClick={() => paginate(1)}
                    disabled={`${page === totalPage ? 'disabled' : ''}`}
                  >
                    <span>Suivant </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="-mx-1 h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      role="graphics-symbol"
                      aria-labelledby="title-10 desc-10"
                    >
                      <title id="title-10">Page suivante</title>
                      <desc id="desc-10">lien à la page suivante</desc>
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        )}
      </div>
    </section>
  );
};

DocumentForm.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default DocumentForm;
