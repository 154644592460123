import { userRequest } from '.';

export const getDocuments = async (offset, page) => {
  return userRequest.get(`documents?offset=${offset}&page=${page}`);
};

export const getDocument = async (name) => {
  return userRequest.get(`documents/${name}`);
};

export const postDocument = async (document) => {
  return userRequest.post(`documents`, document);
};

export const putDocument = async (id, document) => {
  return userRequest.put(`documents/${id}`, document);
};

export const deleteDocument = async (id) => {
  return userRequest.delete(`documents/${id}`);
};
