/* eslint-disable react/react-in-jsx-scope */
import MutationRequestForm from './components/MutationRequestForm';

const MutationRequest = () => {
  return (
    <section>
      <MutationRequestForm />
    </section>
  );
};

export default MutationRequest;
