import { publicRequest, userRequest } from '.';

export const getClubs = async (offset) => {
  return userRequest.get(`clubs?offset=${offset}`);
};

export const getClub = async (id) => {
  return publicRequest.get(`clubs/${id}`);
};

export const postClub = async (club) => {
  return userRequest.post(`clubs`, club);
};

export const putClub = async (id, club) => {
  return userRequest.put(`clubs/${id}`, club);
};

export const searchClub = async (query, offset) => {
  return userRequest.get(`clubs/search?query=${query}&offset=${offset}`);
};
