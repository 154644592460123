/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { checkLicense } from './api/license';
import ButtonLoading from './components/ButtonLoading';

const licenceSchema = Yup.object().shape({
  number: Yup.string().required('Le numéro de license est obligatoire'),
});

const VerifyLicense = () => {
  const [message, setMessage] = useState({});
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [verifiedLicense, setVerifiedLicense] = useState({});
  const [user, setUser] = useState(null);

  useEffect(() => {
    setRole(localStorage?.getItem('xrole'));
  }, [localStorage?.getItem('xrole')]);

  const renderStatus = (status) => {
    switch (status) {
      case 'active':
        return (
          <span className="inline-block rounded-full bg-green-200 py-1 px-2 text-xs font-semibold uppercase text-green-600">
            Actif
          </span>
        );
      case 'inactive':
        return (
          <span className="inline-block rounded-full bg-gray-200 py-1 px-2 text-xs font-semibold uppercase text-gray-600">
            Inactif
          </span>
        );
      case 'suspended':
        return (
          <span className="inline-block rounded-full bg-red-200 py-1 px-2 text-xs font-semibold uppercase text-red-600">
            Suspendu
          </span>
        );
      case 'expired':
        return (
          <span className="inline-block rounded-full bg-yellow-200 py-1 px-2 text-xs font-semibold uppercase text-yellow-600">
            Expiré
          </span>
        );
      default:
        return <span />;
    }
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section className="relative w-full md:min-h-screen">
              <div className="body-font text-gray-600">
                <div className="container mx-auto px-5 py-24">
                  <div className="mb-2 md:w-2/4">
                    <h2 className="title-font text-2xl font-medium text-gray-900 sm:text-3xl">
                      {role === 'player'
                        ? 'Vérifier ma licence'
                        : 'Vérifier une licence'}
                    </h2>
                    <Formik
                      initialValues={{ number: '' }}
                      validationSchema={licenceSchema}
                      onSubmit={(values) => {
                        if (!loading) {
                          setLoading(true);
                          checkLicense(values.number).then(
                            (res) => {
                              setVerifiedLicense(res.data.data);
                              if (res.data.data.user.length) {
                                setUser(res.data.data.user[0]);
                              }
                              setLoading(false);
                              setMessage({
                                text: '',
                                type: 'success',
                              });
                            },
                            (err) => {
                              setLoading(false);
                              if (err.response.status === 500) {
                                setMessage({
                                  text: 'Le service semble indisponible pour le moment, veuillez réessayer plus',
                                  type: 'error',
                                });
                              } else {
                                setMessage({
                                  text: "La license n'est pas valide !",
                                  type: 'error',
                                });
                              }
                            }
                          );
                        }
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          <label htmlFor="number" className="block">
                            Entrer le Numéro de licence
                          </label>
                          <div className="flex flex-col space-y-3">
                            <Field
                              name="number"
                              className="w-full max-w-md rounded-l border border-gray-300 bg-white p-3.5"
                            />

                            <button
                              type="submit"
                              className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                              data-class="bg-transparent"
                            >
                              Vérifier
                              {loading && <ButtonLoading />}
                            </button>
                          </div>

                          {errors.number && touched.number ? (
                            <div
                              className="mt-1 text-sm text-red-400"
                              id="number_input_help"
                            >
                              {errors.number}
                            </div>
                          ) : null}
                          <div
                            className={`my-4 ${message.type ? '' : 'hidden'}
                              ${
                                message.type === 'success'
                                  ? 'text-green-500'
                                  : 'text-red-500'
                              }`}
                          >
                            {message.text}
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>

              <div
                className={`mx-auto w-full p-6 ${
                  Object.keys(verifiedLicense).length > 0 ? 'block' : 'hidden'
                }`}
              >
                <div className="-mx-3 flex flex-wrap">
                  <div className="lg-max:mt-6 w-full max-w-full px-3">
                    <div className="shadow-soft-xl relative flex h-full min-w-0 flex-col break-words rounded-2xl border-0 bg-white bg-clip-border">
                      <div className="mb-0 rounded-t-2xl border-b-0 bg-white p-4 pb-0">
                        <div className="-mx-3 flex flex-wrap">
                          <div className="flex w-full max-w-full shrink-0 items-center px-3 md:w-8/12 md:flex-none">
                            <h6 className="mb-0">
                              {user?.lastName} {user?.firstName}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="flex-auto p-4">
                        <ul className="mb-0 flex flex-col rounded-lg pl-0">
                          <li className="relative block rounded-t-lg border-0 bg-white px-4 py-2 pt-0 pl-0 text-sm leading-normal text-inherit">
                            <strong className="text-slate-700">Email:</strong>{' '}
                            &nbsp; {user?.email}
                          </li>
                          {user?.country && (
                            <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                              <strong className="text-slate-700">Pays:</strong>{' '}
                              &nbsp;
                              {user?.country}
                            </li>
                          )}
                          {user?.phone && (
                            <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                              <strong className="text-slate-700">
                                Téléphone:
                              </strong>{' '}
                              &nbsp;
                              {user?.phone}
                            </li>
                          )}
                          {user?.club?.name && (
                            <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                              <strong className="text-slate-700">Club:</strong>{' '}
                              &nbsp;
                              {user?.club?.name}
                            </li>
                          )}
                          {user?.club?.country && (
                            <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                              <strong className="text-slate-700">Pays:</strong>{' '}
                              &nbsp;
                              {user?.country}
                            </li>
                          )}
                          <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                            <strong className="text-slate-700">Licence:</strong>{' '}
                            &nbsp; {verifiedLicense.number || 'Pas de licence'}
                          </li>
                        </ul>
                        <hr className="my-6 h-px bg-transparent bg-gradient-to-r from-transparent via-white to-transparent" />
                        <ul className="mb-0 flex flex-col rounded-lg pl-0">
                          <li className="relative block rounded-t-lg border-0 bg-white px-4 py-2 pt-0 pl-0 text-sm leading-normal text-inherit">
                            <strong className="text-slate-700">Statut:</strong>{' '}
                            &nbsp; {renderStatus(verifiedLicense.status)}
                          </li>
                          {verifiedLicense.expiration_date && (
                            <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                              <strong className="text-slate-700">
                                Date d'expiration:
                              </strong>{' '}
                              &nbsp;
                              {Intl.DateTimeFormat('fr-FR', {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                              }).format(
                                new Date(verifiedLicense.expiration_date)
                              )}
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyLicense;
