/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getContest, putContest } from './api/contest';
import {
  getStatisticsByContest,
  postStatistics,
  updateStatistic,
} from './api/statistic';
import { getAllPlayers, getMyProfile } from './api/user';
import ConfirmationModal from './components/ConfirmationModal';
import ContestToolbar from './components/ContestToolbar';
import DecisionTreeModal from './components/DecisionModal';
import PaymentModal from './components/PaymentModal';
import ScreenLoading from './components/ScreenLoading';
import StatsTeamsFiller from './components/StatsTeamsFiller';

const Contest = () => {
  const [players, setPlayers] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const [createTeam, setCreateTeam] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingComponent, setLoadingComponent] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [displayPaymentPopup, setDisplayPaymentPopup] = useState(false);
  const [contest, setContest] = useState({});
  const [message, setMessage] = useState({});
  const [teams, setTeams] = useState([]);
  const [currentLimit, setNumberPlayer] = useState(0);
  const contestRef = useRef(null);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [teamLimit, setTeamLimit] = useState(2);
  const [user, setUser] = useState([]);
  const [onLoad, setOnLoad] = useState(false);
  const [displayConfirmationPopup, setDisplayConfirmationPopup] =
    useState(false);
  const [isClosedModal, setIsClosedModal] = useState(false);
  const [confModalMessage, setConfModalMessage] = useState('');
  const [activeStatsTab, setStatsActiveTab] = useState('huit');
  const [displayStats, setDisplayStats] = useState(false);

  const [huitiemeStepsStats, setHuitiemeStepsStats] = useState({});
  const [quartStepsStats, setQuartStepsStats] = useState({});
  const [demiStepsStats, setDemiStepsStats] = useState({});
  const [finalStepsStats, setFinalStepsStats] = useState({});

  const [huitiemeStepsStatsTeams, setHuitiemeStepsStatsTeams] = useState([]);
  const [quartStepsStatsTeams, setQuartStepsStatsTeams] = useState([]);
  const [demiStepsStatsTeams, setDemiStepsStatsTeams] = useState([]);
  const [finalStepsStatsTeams, setFinalStepsStatsTeams] = useState([]);

  const [isNewStepHuitieme, setIsNewStepHuitieme] = useState(false);
  const [isNewStepQuart, setIsNewStepQuart] = useState(false);
  const [isNewStepDemi, setIsNewStepDemi] = useState(false);
  const [isNewStepFinal, setIsNewStepFinal] = useState(false);
  const [modalType, setModalType] = useState(false);

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const renderCharacter = (string) => {
    if (string) {
      return (
        String(string?.slice(0, 1)).toUpperCase() +
        String(string?.slice(1)).toLowerCase()
      );
    }
    return '';
  };

  const renderPlayerName = (firstName, lastName) => {
    return `${lastName} ${firstName}`;
  };

  const getProfile = () => {
    getMyProfile()
      .then((res) => {
        localStorage?.setItem('xrole', res?.data?.role?.name);
        const CONNECTED_USER = res?.data;
        const VERIFIED = CONNECTED_USER?.verified;
        setUser({
          ...user,
          id: CONNECTED_USER?.id,
          firstName: renderCharacter(CONNECTED_USER?.firstName),
          lastName: renderCharacter(CONNECTED_USER?.lastName),
          email: CONNECTED_USER?.email || '',
          phone: CONNECTED_USER?.phone || '',
          profile: CONNECTED_USER?.profile || '',
          occupation: CONNECTED_USER?.occupation || '',
          verified: VERIFIED,
          birthDate: CONNECTED_USER?.birthDate || '',
          bio: CONNECTED_USER?.bio || '',
          club: CONNECTED_USER?.club || '',
          ligue: CONNECTED_USER?.ligue || '',
        });
        setTimeout(() => {}, 500);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setTimeout(() => {}, 500);
      });
  };

  const updatePlayers = async (res, limit) => {
    const list = res?.data
      ?.map((player) => {
        const { club, licence } = player;
        // role player and principal true, then only it's club players

        return {
          id: player?.id,
          profile: player?.profile,
          name: `${renderCharacter(player?.lastName)} ${renderCharacter(
            player?.firstName
          )}`,
          occupation: renderCharacter(player?.occupation),
          role: renderCharacter(player?.role.name),
          club: club === null ? 'Aucun' : club.name,
          licence: licence === null ? 'Aucun' : licence,
          verified: player?.verified,
        };
      })
      .filter((player) => player.verified);

    setPlayers(_.uniqBy(list, 'id'));

    setNumberPlayer(limit);
  };

  const listPlayers = async () => {
    // Getting connected user profile
    await getProfile();

    setLoadingUsers(true);
    const limit = currentLimit + 20;
    getAllPlayers(user?.club?.id)
      .then((res) => {
        updatePlayers(res, limit);
        setLoadingUsers(false);
      })
      .catch((err) => {
        setLoadingUsers(false);
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
      });
  };

  const get = () => {
    getContest(id)
      .then((res) => {
        setContest(res.data);
        setLoading(false);
        setOnLoad(false);
        listPlayers();
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        } else {
          setMessage({
            type: 'error',
            message: 'Une erreur est survenue, veuillez rafraîchir la page',
          });
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id) get();
  }, [id]);

  const displayPopUp = (data) => {
    setShowPopUp(!showPopUp);
  };

  const showConfirmationPopup = (type) => {
    setModalType(type);
    setDisplayConfirmationPopup(!displayConfirmationPopup);
  };

  const closeModal = () => {
    setShowPopUp(false);
    setDisplayPaymentPopup(false);
    setDisplayConfirmationPopup(false);
  };

  const onSuccess = () => {
    setLoading(true);
    closeModal();
    setTeams([]);
    setSelectedPlayers([]);
    setCreateTeam(false);
    setNumberPlayer(20);
    setOnLoad(true);
    get();
  };

  const renderType = (type) => {
    switch (type) {
      case 'one':
        return 'Tête à tête';
      case 'doubles':
        return 'Doublette';
      case 'triples':
        return 'Triplette';
      case 'shot':
        return 'Tir de précision';
      default:
        return '';
    }
  };

  const renderTeamLimit = (type) => {
    switch (type) {
      case 'one':
        return 1;
      case 'doubles':
        return 2;
      case 'triples':
        return 3;
      case 'shot':
        return 1;

      default:
        return '';
    }
  };

  const isTeamRegistered = (player) => {
    let check = false;
    contest.teams.forEach((team) => {
      team.players.forEach((p) => {
        if (p.id === player.id) check = true;
      });
    });
    return check;
  };

  const isTeamMember = (player) => {
    let check = false;
    teams.forEach((team) => {
      if (team.includes(player)) check = true;
    });
    return check;
  };

  const teamMemberIndex = (player) => {
    let index = -1;
    teams.forEach((team) => {
      if (team.includes(player)) index = teams.indexOf(team);
    });
    if (index !== -1) return index + 1;
    return index;
  };

  const buildTeam = (player) => {
    // Retrieving for the team limit
    setTeamLimit(renderTeamLimit(contest.composition));

    // Add player
    if (
      !selectedPlayers.includes(player) &&
      !isTeamMember(player) &&
      !isTeamRegistered(player) &&
      selectedPlayers.length <= teamLimit - 1
    )
      setSelectedPlayers([...selectedPlayers, player]);
  };

  const isPlayerSelected = (player) => {
    return selectedPlayers.findIndex((p) => p?.id === player?.id) !== -1;
  };

  const deleteTeam = (idx) => {
    setTeams(teams.filter((t, i) => i !== idx));
  };

  // Function to filter stats by type
  function filterStats(datas, type) {
    return datas.filter((data) => {
      return data.type === type;
    });
  }

  // Function to check if stats are already defined
  const newStatsChecker = () => {
    // Checking datas states and updating trackers
    huitiemeStepsStatsTeams.length === 0 ? setIsNewStepHuitieme(true) : '';
    quartStepsStatsTeams.length === 0 ? setIsNewStepQuart(true) : '';
    demiStepsStatsTeams.length === 0 ? setIsNewStepDemi(true) : '';
    finalStepsStatsTeams.length === 0 ? setIsNewStepFinal(true) : '';
  };

  // Function to retrieve stats datas from the api
  const getStatistics = () => {
    getStatisticsByContest(id, 100)
      .then((res) => {
        let datas = res.data;

        // Filtering datas to only keep contest related ones
        datas = datas.filter((data) => {
          return data.contestId === id;
        });

        // Retrieving 8th part datas
        setHuitiemeStepsStats(filterStats(datas, 'eightfinal')[0] || {});

        if (
          typeof huitiemeStepsStats === 'object' &&
          Object.keys(huitiemeStepsStats).length !== 0
        )
          setHuitiemeStepsStatsTeams(huitiemeStepsStats.teams);

        // Retrieving 4th part datas
        setQuartStepsStats(filterStats(datas, 'quarterfinal')[0] || {});

        if (
          typeof quartStepsStats === 'object' &&
          Object.keys(quartStepsStats).length !== 0
        )
          setQuartStepsStatsTeams(quartStepsStats.teams);

        // Retrieving 2nd part datas
        setDemiStepsStats(filterStats(datas, 'semifinal')[0] || {});

        if (
          typeof demiStepsStats === 'object' &&
          Object.keys(demiStepsStats).length !== 0
        )
          setDemiStepsStatsTeams(demiStepsStats.teams);

        // Retrieving final part datas
        setFinalStepsStats(filterStats(datas, 'final')[0] || {});

        if (
          typeof finalStepsStats === 'object' &&
          Object.keys(finalStepsStats).length !== 0
        )
          setFinalStepsStatsTeams(finalStepsStats.teams);

        newStatsChecker();
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        } else {
          setMessage({
            type: 'error',
            message: 'Une erreur est survenue, veuillez rafraîchir la page',
          });
        }
        setLoading(false);
      });
  };

  // Function to save stats using api call
  function statisticSaver(state, teamsList, statType, statistic) {
    if (state) {
      // create the state object
      const data = {
        contestId: id,
        teams: teamsList,
        type: statType,
      };

      data.teams = data.teams.map((team) => team.id);

      // Posting the stat
      postStatistics(data)
        .then(() => {
          setConfModalMessage({
            type: 'success',
            text: 'Données du concours envoyé avec succès !',
          });

          setTimeout(() => {
            setConfModalMessage({});
          }, 500);

          get();
          setTimeout(() => {
            setLoading(false);
            closeModal();
          }, 500);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          }
          setLoading(false);
        });
    } else {
      // Updating the statistic teams values
      let data = {
        ...statistic,
        teams: teamsList,
      };

      data = data.teams.map((team) => team.id);

      // Updating the stat
      updateStatistic(statistic.id, data)
        .then(() => {
          setConfModalMessage({
            type: 'success',
            text: 'Données du concours mis à jour avec succès !',
          });

          setTimeout(() => {
            setConfModalMessage({});
          }, 500);

          get();
          setTimeout(() => {
            setLoading(false);
            closeModal();
          }, 500);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          }
        });
    }
  }

  // Function to post stats values
  const postingStatistics = () => {
    // Action according to the contest type
    if (contest.type === 'tournament') {
      // When tournament
      // Check each stats and save
      statisticSaver(
        isNewStepHuitieme,
        huitiemeStepsStatsTeams,
        'eightfinal',
        huitiemeStepsStats
      );
      statisticSaver(
        isNewStepQuart,
        quartStepsStatsTeams,
        'quarterfinal',
        quartStepsStats
      );
      statisticSaver(
        isNewStepDemi,
        demiStepsStatsTeams,
        'semifinal',
        demiStepsStats
      );
      statisticSaver(
        isNewStepFinal,
        finalStepsStatsTeams,
        'final',
        finalStepsStats
      );
    } else {
      // When challenge
      // Only save final data
      statisticSaver(
        isNewStepFinal,
        finalStepsStatsTeams,
        'final',
        finalStepsStats
      );
    }
  };

  useEffect(() => {
    if (id) getStatistics();
  }, [id]);

  // Function to get appropriate format for contest update tasks
  const makeContest = (value) => {
    return {
      name: contest?.name || '',
      description: contest?.description || 'ok',
      start_date: contest?.start_date || '',
      status: value,
      type: contest?.type || '',
      composition: contest?.composition || '',
      address: contest?.address || 'ok',
      shape: contest?.shape || 'ok',
    };
  };

  const updateContest = (value) => {
    const idc = contest?.id;
    // Post of statistics when value is finished
    if (value === 'finished') {
      postingStatistics();
      return;
    }

    // Update contest
    putContest(idc, makeContest(value))
      .then((res) => {
        if (res.data.success) {
          setConfModalMessage({
            type: 'success',
            text: 'Le status du concours a été mis à jour avec succès !',
          });

          setTimeout(() => {
            setConfModalMessage({});
          }, 500);

          get();
          setTimeout(() => {
            setLoading(false);
            closeModal();
          }, 500);
        } else {
          setMessage({
            type: 'error',
            text: 'Une erreur est survenue. Veuillez réessayer.',
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setConfModalMessage({
            type: 'error',
            text: error.response.data.message,
          });
        } else if (
          error.response?.status === 401 ||
          error.response?.status === 403
        ) {
          logOut();
          setConfModalMessage({
            type: 'error',
            text: 'Votre session a expiré. Veuillez vous reconnecter.',
          });
        } else {
          setConfModalMessage({
            type: 'error',
            text: 'Une erreur est survenue. Veuillez réessayer.',
          });
        }
      });
  };

  const getTeamPlayers = (teamId) => {
    return contest?.teams.find((t) => t.id === teamId)?.players;
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="flex bg-gray-50 py-4 sm:hidden">
            <button
              type="button"
              className="ml-auto flex items-center justify-center space-x-1 rounded-full bg-primary px-4 py-2 text-xs font-medium text-white hover:bg-opacity-90"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M10 19.748V16.4c0-1.283.995-2.292 2.467-2.868A8.482 8.482 0 0 0 9.5 13c-1.89 0-3.636.617-5.047 1.66A8.017 8.017 0 0 0 10 19.748zm8.88-3.662C18.485 15.553 17.17 15 15.5 15c-2.006 0-3.5.797-3.5 1.4V20a7.996 7.996 0 0 0 6.88-3.914zM9.55 11.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5zm5.95 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
              <p>{contest?.teams?.length ?? 0} équipes</p>
            </button>
          </div>
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col overflow-hidden break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section
              ref={contestRef}
              className="infinitescroll-container relative w-full md:min-h-screen"
            >
              <ContestToolbar
                openSetting={displayPopUp}
                openConfirmationPopup={showConfirmationPopup}
                createTeam={createTeam}
                setCreateTeam={setCreateTeam}
                contest={contest}
                teams={teams}
                count={contest?.teams?.length ?? 0}
                setIsClosedModal={setIsClosedModal}
                displayStats={displayStats}
                setDisplayStats={setDisplayStats}
              />
              {teams.length === 0 &&
                !createTeam &&
                !displayStats &&
                !loading &&
                localStorage.getItem('xrole') !== 'player' &&
                localStorage.getItem('xrole') !== 'other' &&
                contest.teams?.length === 0 && (
                  <p className="mt-16 px-10">
                    Aucune équipe inscrite pour le moment ...
                    <br />
                    Cliquez sur Créer une équipe ...
                  </p>
                )}

              {!createTeam && !displayStats && teams.length > 0 && (
                <div className="flex justify-between">
                  <h5 className="m-4">Mes inscriptions en cours</h5>

                  <button
                    type="button"
                    className="m-4 mt-2.5 flex rounded-full bg-primary py-2 px-2.5 text-xs font-semibold text-white"
                    onClick={() => setDisplayPaymentPopup(true)}
                  >
                    Terminer
                  </button>
                </div>
              )}

              <div className="flex flex-wrap">
                {!createTeam &&
                  !displayStats &&
                  teams?.map((list, i) => {
                    return (
                      <div
                        className="m-4 w-full max-w-xs items-center justify-between space-y-2 rounded-md border border-slate-300"
                        key={i}
                      >
                        <div className="my-2 flex w-full items-center justify-between space-x-2 border-b border-slate-300 px-3 py-2">
                          <button
                            type="button"
                            className="mr-auto flex flex-1 items-center justify-center text-gray-800"
                          >
                            {`Equipe ${i + 1}`}
                          </button>
                          <button
                            type="button"
                            onClick={() => {
                              deleteTeam(i);
                            }}
                            className="flex h-10 w-10 items-center justify-center rounded-3xl bg-red-500"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24"
                              height="24"
                              className="h-5 w-5"
                            >
                              <path fill="none" d="M0 0h24v24H0z" />
                              <path
                                d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm2-2v2h6V4H9z"
                                fill="rgba(255,255,255,1)"
                              />
                            </svg>
                          </button>
                        </div>

                        {list.map((player, idx) => {
                          return (
                            <div
                              className="flex items-center justify-between space-x-2 p-4 px-3 py-2"
                              key={idx}
                            >
                              {player?.name}
                            </div>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>

              {!createTeam &&
                !displayStats &&
                contest.teams?.length > 0 &&
                contest?.status !== 'finished' && (
                  <h5 className="m-4 mt-8 text-2xl">Toutes les équipes</h5>
                )}
              <div className="flex flex-wrap">
                {!createTeam &&
                  !displayStats &&
                  contest.teams?.length > 0 &&
                  contest?.status !== 'finished' &&
                  contest.teams?.map((list, i) => {
                    return (
                      <div
                        className="m-4 w-full max-w-xs items-center justify-between space-y-2 rounded-md border border-slate-300"
                        key={i}
                      >
                        <div className="my-2 flex w-full items-center justify-between space-x-2 border-b border-slate-300 px-3 py-2">
                          <button
                            type="button"
                            className="mr-auto flex flex-1 items-center justify-center text-gray-800"
                          >
                            {`Equipe ${i + 1}`}
                          </button>
                        </div>

                        {list.players.map((player, idx) => {
                          return (
                            <div
                              className="flex items-center justify-between space-x-2 p-4 px-3 py-2"
                              key={idx}
                            >
                              {renderPlayerName(
                                player?.firstName,
                                player?.lastName
                              )}
                            </div>
                          );
                        })}

                        {list.players.length === 0 && (
                          <div className="flex items-center justify-between space-x-2 p-4 px-3 py-2 text-amber-400">
                            Aucun joueur enregistré
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>

              {!loading && (
                <div
                  className={`justify-between space-x-2 p-3 pb-32 font-sans sm:pb-8 ${
                    createTeam ? 'md:flex' : 'hidden'
                  }`}
                >
                  <div className="mt-8">
                    <div className="min-h-12 max-w-sm overflow-hidden rounded-md border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2">
                      {selectedPlayers.length <= 0 ? (
                        <p className="text-black">
                          Sélectionnez les joueurs pour les ajouter à
                          l&apos;équipe
                        </p>
                      ) : (
                        <div className="my-2 flex items-center justify-between space-x-2 px-3 py-2">
                          <button
                            type="button"
                            className="flex h-10 w-12 items-center justify-center rounded-3xl bg-green-400 text-white"
                          >
                            <p>{teams.length + 1}</p>
                          </button>
                          <p>Constituez l&apos;équipe</p>
                        </div>
                      )}
                      {selectedPlayers.length >= 0 &&
                        selectedPlayers.map((selectedPlayer) => {
                          return (
                            <div
                              className="my-2 flex items-center justify-between space-x-2 rounded-md border border-slate-300 px-3 py-2"
                              key={selectedPlayer.id}
                            >
                              <div>{selectedPlayer.name}</div>
                              <div>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSelectedPlayers(
                                      selectedPlayers.filter((sp) => {
                                        return sp !== selectedPlayer;
                                      })
                                    );
                                  }}
                                  className="flex h-10 w-10 items-center justify-center rounded-3xl bg-red-500"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="h-5 w-5"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path
                                      d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm2-2v2h6V4H9z"
                                      fill="rgba(255,255,255,1)"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      {selectedPlayers.length === teamLimit && (
                        <button
                          type="submit"
                          className="my-2 w-full rounded-sm bg-green-500 px-3 py-2 text-white"
                          onClick={() => {
                            setTeams([...teams, selectedPlayers]);
                            setCreateTeam(false);
                            setSelectedPlayers([]);
                          }}
                        >
                          Confirmer
                        </button>
                      )}
                    </div>
                  </div>

                  {!onLoad && (
                    <div className="mx-auto w-full max-w-6xl">
                      <div className="mt-8 grid h-fit w-full max-w-6xl grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
                        {players.map((player) => {
                          return (
                            <button
                              type="button"
                              className={`bg-lightgreen my-2 flex h-16 max-h-fit items-center justify-start space-x-5 overflow-hidden rounded-sm border border-dashed border-slate-300 px-1
                            ${
                              isPlayerSelected(player) ? 'border-green-500' : ''
                            }`}
                              key={player.id}
                              onClick={() => {
                                buildTeam(player);
                              }}
                            >
                              <div>
                                <button
                                  type="button"
                                  className={`flex w-fit items-center justify-center rounded-3xl p-2 text-white
                                ${
                                  (isTeamMember(player) ||
                                    isTeamRegistered(player)) &&
                                  'bg-gray-500'
                                }
                                ${
                                  isPlayerSelected(player)
                                    ? 'bg-green-500'
                                    : 'bg-default'
                                }`}
                                >
                                  {isTeamMember(player) ? (
                                    <span className="px-2">
                                      {teamMemberIndex(player)}
                                    </span>
                                  ) : (
                                    <>
                                      <span
                                        className={`px-2 ${
                                          isPlayerSelected(player)
                                            ? 'block'
                                            : 'hidden'
                                        }`}
                                      >
                                        {teams.length + 1}
                                      </span>

                                      <svg
                                        className={`${
                                          isPlayerSelected(player) ||
                                          isTeamMember(player)
                                            ? 'hidden'
                                            : 'inline-block'
                                        }`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                      >
                                        <path fill="none" d="M0 0h24v24H0z" />
                                        <path
                                          d="M4 22a8 8 0 1 1 16 0h-2a6 6 0 1 0-12 0H4zm8-9c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z"
                                          fill="rgba(255,255,255,1)"
                                        />
                                      </svg>
                                    </>
                                  )}
                                </button>
                              </div>

                              <div
                                className={`ml-1 truncate 
                                ${
                                  isTeamMember(player)
                                    ? 'text-slate-400'
                                    : 'text-slate-800'
                                }`}
                              >
                                {player.name}
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Stats section */}
              {/* Stats for tournament */}
              {displayStats &&
                contest.status !== 'finished' &&
                contest.type !== 'challenge' && (
                  <div className="my-4 md:my-12 md:mx-4">
                    <h3>Statistiques</h3>
                    <div className="flex items-center justify-between">
                      <div className="flex flex-row space-x-2">
                        <button
                          type="button"
                          className="flex items-center rounded-full bg-default p-2 text-sm text-white hover:bg-red-500"
                          onClick={() => {
                            setDisplayStats(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                      <div>
                        <div className="border-b border-gray-200">
                          <div
                            className="-mb-px flex space-x-8"
                            aria-orientation="horizontal"
                            role="tablist"
                          >
                            <button
                              id="tab-huit"
                              className={`whitespace-nowrap border-b-2 py-6 text-sm font-medium ${
                                activeStatsTab === 'huit'
                                  ? 'border-secondary text-secondary'
                                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800'
                              } `}
                              // className="{ 'border-secondary text-secondary': selected, 'border-transparent hover:border-gray-300': !(selected) } text-gray-700 hover:text-gray-800"
                              aria-controls="tab-panel-huit"
                              role="tab"
                              type="button"
                              tabIndex="-1"
                              aria-selected="false"
                              onClick={() => setStatsActiveTab('huit')}
                            >
                              1/8 de finale
                            </button>

                            <button
                              id="tab-quart"
                              className={`whitespace-nowrap border-b-2 py-6 text-sm font-medium ${
                                activeStatsTab === 'quart'
                                  ? 'border-secondary text-secondary'
                                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800'
                              } `}
                              // className="{ 'border-secondary text-secondary': selected, 'border-transparent hover:border-gray-300': !(selected) } text-gray-700 hover:text-gray-800"
                              aria-controls="tab-panel-quart"
                              role="tab"
                              type="button"
                              tabIndex="-1"
                              aria-selected="false"
                              onClick={() => setStatsActiveTab('quart')}
                            >
                              1/4 de finale
                            </button>

                            <button
                              id="tab-demi"
                              className={`whitespace-nowrap border-b-2 py-6 text-sm font-medium ${
                                activeStatsTab === 'demi'
                                  ? 'border-secondary text-secondary'
                                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800'
                              } `}
                              // className="{ 'border-secondary text-secondary': selected, 'border-transparent hover:border-gray-300': !(selected) } text-gray-700 hover:text-gray-800"
                              aria-controls="tab-panel-demi"
                              role="tab"
                              type="button"
                              tabIndex="-1"
                              aria-selected="false"
                              onClick={() => setStatsActiveTab('demi')}
                            >
                              1/2 de finale
                            </button>

                            <button
                              id="tab-finale"
                              className={`whitespace-nowrap border-b-2 py-6 text-sm font-medium ${
                                activeStatsTab === 'finale'
                                  ? 'border-secondary text-secondary'
                                  : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800'
                              } `}
                              // className="{ 'border-secondary text-secondary': selected, 'border-transparent hover:border-gray-300': !(selected) } text-gray-700 hover:text-gray-800"
                              aria-controls="tab-panel-finale"
                              role="tab"
                              type="button"
                              tabIndex="-1"
                              aria-selected="false"
                              onClick={() => setStatsActiveTab('finale')}
                            >
                              Finale
                            </button>
                          </div>
                        </div>

                        <div
                          id="tab-panel-huit"
                          className={`-mb-10 ${
                            activeStatsTab === 'huit' ? 'block' : 'hidden'
                          }`}
                          aria-labelledby="tab-huit"
                          role="tabpanel"
                          tabIndex="0"
                        >
                          <h3 className="sr-only">1/8 de finale</h3>

                          <div>
                            <h6 className="m-4">
                              Sélectionner les équipes qualifiés
                            </h6>
                            <div className="flex flex-wrap">
                              {/* Teams selection system */}
                              <div
                                className={`huitiemeStepsStatsTeams.length <= 0 ? 'block : 'hidden`}
                              >
                                <StatsTeamsFiller
                                  teams={
                                    huitiemeStepsStatsTeams.length === 8
                                      ? huitiemeStepsStatsTeams
                                      : contest.teams
                                  }
                                  contestTeams={contest.teams}
                                  stepTeams={huitiemeStepsStatsTeams}
                                  setStepTeams={setHuitiemeStepsStatsTeams}
                                  stepTeamsLimit={8}
                                  renderPlayerName={renderPlayerName}
                                  badgeText="1/8"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          id="tab-panel-quart"
                          className={`-mb-10 ${
                            activeStatsTab === 'quart' ? 'block' : 'hidden'
                          }`}
                          aria-labelledby="tab-quart"
                          role="tabpanel"
                          tabIndex="0"
                        >
                          <h3 className="sr-only">1/4 de finale</h3>

                          <div>
                            <h6 className="m-4">
                              Sélectionner les équipes qualifiés
                            </h6>
                            <div className="flex flex-wrap">
                              {/* Teams selection system */}
                              <div
                                className={`quartStepsStatsTeams.length <= 0 ? 'block : 'hidden`}
                              >
                                <StatsTeamsFiller
                                  teams={
                                    quartStepsStatsTeams.length === 4
                                      ? quartStepsStatsTeams
                                      : huitiemeStepsStatsTeams
                                  }
                                  contestTeams={contest.teams}
                                  stepTeams={quartStepsStatsTeams}
                                  setStepTeams={setQuartStepsStatsTeams}
                                  stepTeamsLimit={4}
                                  renderPlayerName={renderPlayerName}
                                  badgeText="1/4"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          id="tab-panel-demi"
                          className={`-mb-10 ${
                            activeStatsTab === 'demi' ? 'block' : 'hidden'
                          }`}
                          aria-labelledby="tab-demi"
                          role="tabpanel"
                          tabIndex="0"
                        >
                          <h3 className="sr-only">1/2 de finale</h3>

                          <div>
                            <h6 className="m-4">
                              Sélectionner les équipes qualifiés
                            </h6>
                            <div className="flex flex-wrap">
                              {/* Teams selection system */}
                              <div
                                className={`demiStepsStatsTeams.length <= 0 ? 'block : 'hidden`}
                              >
                                <StatsTeamsFiller
                                  teams={
                                    demiStepsStatsTeams.length === 2
                                      ? demiStepsStatsTeams
                                      : quartStepsStatsTeams
                                  }
                                  contestTeams={contest.teams}
                                  stepTeams={demiStepsStatsTeams}
                                  setStepTeams={setDemiStepsStatsTeams}
                                  stepTeamsLimit={2}
                                  renderPlayerName={renderPlayerName}
                                  badgeText="1/2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          id="tab-panel-finale"
                          className={`-mb-10 ${
                            activeStatsTab === 'finale' ? 'block' : 'hidden'
                          }`}
                          aria-labelledby="tab-finale"
                          role="tabpanel"
                          tabIndex="0"
                        >
                          <h3 className="sr-only">Finale</h3>

                          <div>
                            <h6 className="m-4">
                              Sélectionner les équipes qualifiés
                            </h6>
                            <div className="flex flex-wrap">
                              {/* Teams selection system */}
                              <div
                                className={`finalStepsStatsTeams.length <= 0 ? 'block : 'hidden`}
                              >
                                <StatsTeamsFiller
                                  teams={
                                    finalStepsStatsTeams.length === 1
                                      ? finalStepsStatsTeams
                                      : demiStepsStatsTeams
                                  }
                                  contestTeams={contest.teams}
                                  stepTeams={finalStepsStatsTeams}
                                  setStepTeams={setFinalStepsStatsTeams}
                                  stepTeamsLimit={1}
                                  renderPlayerName={renderPlayerName}
                                  badgeText="F"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {/* Stats for challenges */}
              {displayStats &&
                contest.status !== 'finished' &&
                contest.type === 'challenge' && (
                  <div className="my-4 md:my-12 md:mx-4">
                    <div className="flex items-center justify-between">
                      <h3>Statistiques</h3>
                      <div className="flex flex-row space-x-2">
                        <button
                          type="button"
                          className="flex items-center rounded-full bg-default p-2 text-sm text-white hover:bg-red-500"
                          onClick={() => {
                            setDisplayStats(false);
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path
                              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                              fill="rgba(255,255,255,1)"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                      <div>
                        <div className="border-b border-gray-200">
                          <div
                            className="-mb-px flex space-x-8"
                            aria-orientation="horizontal"
                            role="tablist"
                          >
                            <button
                              id="tab-finale"
                              className={`whitespace-nowrap border-b-2 border-secondary py-6 text-sm 
                            font-medium text-secondary `}
                              // className="{ 'border-secondary text-secondary': selected, 'border-transparent hover:border-gray-300': !(selected) } text-gray-700 hover:text-gray-800"
                              aria-controls="tab-panel-finale"
                              role="tab"
                              type="button"
                              tabIndex="-1"
                              aria-selected="false"
                              onClick={() => setStatsActiveTab('finale')}
                            >
                              Vainqueur
                            </button>
                          </div>
                        </div>

                        <div
                          id="tab-panel-finale"
                          className="-mb-10"
                          aria-labelledby="tab-finale"
                          role="tabpanel"
                          tabIndex="0"
                        >
                          <h3 className="sr-only">Finale</h3>

                          <div>
                            <h6 className="m-4">
                              Sélectionner l&apos;équipe gagnante
                            </h6>
                            <div className="flex flex-wrap">
                              {/* Teams selection system */}
                              <div
                                className={`finalStepsStatsTeams.length <= 0 ? 'block : 'hidden`}
                              >
                                <StatsTeamsFiller
                                  teams={
                                    finalStepsStatsTeams.length === 1
                                      ? finalStepsStatsTeams
                                      : contest.teams
                                  }
                                  contestTeams={contest.teams}
                                  stepTeams={finalStepsStatsTeams}
                                  setStepTeams={setFinalStepsStatsTeams}
                                  stepTeamsLimit={1}
                                  renderPlayerName={renderPlayerName}
                                  badgeText=""
                                  contest={contest}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {contest.status === 'finished' && (
                <div className="my-12 mx-4 flex flex-col">
                  {contest?.statistic?.map((stat) => (
                    <div
                      key={stat.id}
                      className={`mx-auto mt-5 mb-16 w-full border border-slate-300 md:w-11/12
                      ${stat?.type === 'final' && 'order-1'}
                      ${stat?.type === 'semifinal' && 'order-2'}
                      ${stat?.type === 'quarterfinal' && 'order-3'}
                      ${stat?.type === 'eightfinal' && 'order-4'}
                  `}
                    >
                      {/* Step header */}
                      <div className="w-full border-b border-slate-300 bg-slate-100 bg-opacity-20 py-2 px-3">
                        {contest?.statistic?.length > 1 &&
                          stat?.type === 'eightfinal' &&
                          'Huitième de finale'}
                        {contest?.statistic?.length > 1 &&
                          stat?.type === 'quarterfinal' &&
                          'Quart de finale'}
                        {contest?.statistic?.length > 1 &&
                          stat?.type === 'semifinal' &&
                          'Demi finale'}
                        {contest?.statistic?.length > 1 &&
                          stat?.type === 'final' &&
                          'Finale'}
                        {contest?.statistic?.length === 1 && 'Vainqueur'}
                      </div>
                      <div className="flex flex-wrap">
                        {/* Teams selection system */}
                        {stat?.teams?.length > 0 &&
                          stat?.teams?.map((team) => (
                            <div
                              key={team}
                              className="flex w-full items-center justify-start border border-slate-100 py-3 px-1 md:w-1/2"
                            >
                              {getTeamPlayers(team)?.map((player) => (
                                <div
                                  key={player?.id}
                                  className="mx-4 flex w-full items-center justify-start space-x-4 md:w-1/2"
                                >
                                  {player.profile && (
                                    <img
                                      src={`${process.env.REACT_APP_WEB_APP_URL_ASSET_DIR}${player.profile}`}
                                      alt="PROFILE"
                                      className="h-24 w-24 rounded-full object-cover"
                                    />
                                  )}

                                  {!player.profile && (
                                    <span className="inline-block h-8 w-8 overflow-hidden rounded-full bg-gray-100 object-cover">
                                      <svg
                                        className="h-full w-full text-gray-300"
                                        fill="currentColor"
                                        viewBox="0 0 24 24"
                                      >
                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                      </svg>
                                    </span>
                                  )}
                                  <p>
                                    {player?.lastName} {player?.lastName}
                                  </p>
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {/* End stats section */}

              {loading && (
                <div className="">
                  <ScreenLoading />
                </div>
              )}
              <div className="flex items-center justify-center">
                {showPopUp && contest?.type && (
                  <DecisionTreeModal
                    type={contest.type}
                    loading={loadingComponent}
                    onOpen={showPopUp}
                    onClose={closeModal}
                    contest={contest}
                    teams={teams}
                    load={get}
                  />
                )}

                {displayPaymentPopup && (
                  <PaymentModal
                    loading={loadingComponent}
                    onOpen={displayPaymentPopup}
                    onClose={closeModal}
                    contest={contest}
                    teams={teams}
                    onSuccess={onSuccess}
                  />
                )}

                {displayConfirmationPopup && (
                  <ConfirmationModal
                    statsReady={finalStepsStatsTeams.length > 0}
                    loading={loadingComponent}
                    onOpen={displayConfirmationPopup}
                    onClose={closeModal}
                    contest={contest}
                    onSuccess={closeModal}
                    updateContest={updateContest}
                    isClosedModal={isClosedModal}
                    setIsClosedModal={setIsClosedModal}
                    message={confModalMessage}
                    setMessage={setConfModalMessage}
                    type={modalType}
                  />
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contest;
