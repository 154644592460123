/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */

import { Field, Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { send } from './api/claim';
import ButtonLoading from './components/ButtonLoading';

const Claim = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const formSchema = Yup.object().shape({
    object: Yup.string().required('Ne peut être vide'),
    message: Yup.string().required('Ne peut être vide'),
  });

  const formikField = (field, setFieldValue) => {
    if (field.type === 'textarea') {
      return (
        <Field
          as="textarea"
          name={field.name}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
        />
      );
    }
    return (
      <Field
        name={field.name}
        type={field.type}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
      />
    );
  };

  const list = [
    {
      name: 'object',
      type: 'text',
      placeholder: 'Objet de la plainte',
      show: true,
      semiWidth: false,
    },
    {
      name: 'message',
      type: 'textarea',
      placeholder: 'Détails de la plainte',
      show: true,
      semiWidth: false,
    },
  ];

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section className="relative w-full md:min-h-screen">
              <div className="body-font text-gray-600">
                <div className="container mx-auto px-5 py-24">
                  <div className="mb-2 md:w-2/4">
                    <h2 className="title-font text-2xl font-medium text-gray-900 sm:text-3xl">
                      Plaintes ou réclamations
                    </h2>
                    <Formik
                      enableReinitialize
                      initialValues={{
                        object: '',
                        message: '',
                      }}
                      validationSchema={formSchema}
                      onSubmit={(values) => {
                        setMessage({});
                        setLoading(true);
                        const data = {
                          object: values.object,
                          message: values.message,
                        };

                        send(data)
                          .then((response) => {
                            setLoading(false);
                            if (response.data.success) {
                              setMessage({
                                type: 'success',
                                text: 'Votre demande a été bien envoyée. Un membre de la ligue prendra contact avec vous sous peu',
                              });
                            }
                          })
                          .catch((err) => {
                            setLoading(false);
                            if (
                              err.response?.status === 401 ||
                              err.response?.status === 403
                            ) {
                              logOut();
                            } else
                              setMessage({
                                type: 'error',
                                text: "Votre demande n'a pas pu être envoyée. Veuillez réessayer ultérieurement",
                              });
                          });
                      }}
                    >
                      {({ errors, touched, setFieldValue }) => (
                        <Form className="flex flex-col gap-4 pt-8">
                          <div className="flex flex-wrap justify-between gap-y-4">
                            {list.map((field) => {
                              return (
                                field.show && (
                                  <div
                                    key={field.name}
                                    className={`flex w-full flex-col md:${
                                      field.semiWidth && 'md:w-1/2'
                                    }`}
                                  >
                                    <div className="flex flex-col items-start justify-start">
                                      <h3 className="text-xl leading-normal text-gray-900">
                                        {[field.placeholder]}
                                      </h3>
                                    </div>
                                    <div className="flex flex-col">
                                      {formikField(field, setFieldValue)}
                                      {errors[field.name] &&
                                      touched[field.name] ? (
                                        <div className="text-red-400">
                                          {errors[field.name]}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )
                              );
                            })}
                          </div>

                          {message.type && (
                            <div
                              className={`my-4
                  ${
                    message.type === 'success'
                      ? 'text-green-500'
                      : 'text-red-500'
                  }`}
                            >
                              {message.text}
                            </div>
                          )}

                          <div className="flex space-x-16">
                            <button
                              type="button"
                              className={`xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-2 ml-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-secondary bg-transparent bg-none px-4 py-3 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:border-secondary
                                  ${
                                    !loading && 'duration-300 hover:scale-105'
                                  }`}
                              data-class="bg-white"
                              onClick={() => navigate('/')}
                            >
                              Annuler
                            </button>
                            <button
                              type="submit"
                              className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                              data-class="bg-transparent"
                            >
                              {loading ? 'En cours' : 'Envoyer'}
                              {loading && <ButtonLoading />}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Claim;
