import React from 'react';

const ButtonLoading = () => {
  return (
    <span className="xt-loader absolute inset-0 z-content overflow-hidden rounded-inherit">
      <span className="xt-filler absolute inset-0 m-auto text-white text-opacity-25">
        <span className="absolute block h-full w-full bg-primary bg-opacity-5" />
        <span className="absolute block h-full animate-xt-filler-x bg-primary bg-opacity-50" />
      </span>
    </span>
  );
};

export default ButtonLoading;
