/* eslint-disable react/no-unknown-property */
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { TOKEN } from './api';
import { login } from './api/user';
import ButtonLoading from './components/ButtonLoading';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Email incorrect').required('Email requis'),
  password: Yup.string().required('Mot de passe obligatoire'),
});
const SignIn = () => {
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const changePasswordType = () => {
    setShowPassword(!showPassword);
  };
  const connect = (user) => {
    setMessage([]);
    const root =
      typeof window !== 'undefined' && localStorage?.getItem('persist:root')
        ? JSON.parse(localStorage?.getItem('persist:root'))
        : {};
    setLoading(true);
    login(user)
      .then((res) => {
        localStorage.setItem(
          'persist:root',
          JSON.stringify({
            ...root,
            user: {
              accessToken: res?.data?.token,
            },
            xstate: true,
          })
        );
        localStorage?.setItem('xrole', res?.data?.role?.name);

        setTimeout(() => {
          setMessage([true, 'Connexion réussie']);
          window.location.href = '/';
        }, 700);
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          setMessage([false, 'Email ou mot de passe incorrect']);
        } else {
          setMessage([
            false,
            'Erreur !!! Authentification a échoué ! Réessayez à nouveau.',
          ]);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (TOKEN) navigator('/');
  }, [TOKEN]);

  return (
    <div className="leading-default m-0 bg-white text-start font-sans text-base font-normal text-slate-500 antialiased">
      <div className="ease-soft-in-out mt-0 transition-all duration-200">
        <section>
          <div className="relative flex min-h-screen items-center overflow-hidden bg-cover bg-center p-0">
            <div className="container z-10">
              <div className="-mx-3 mt-0 flex flex-wrap">
                <div className="md:flex-0 mx-auto flex w-full max-w-full shrink-0 flex-col px-3 md:w-6/12 lg:w-5/12 xl:w-4/12">
                  <div>
                    <div className="relative flex min-w-0 flex-col break-words rounded-2xl border-0 bg-transparent bg-clip-border shadow-none">
                      <div className="mb-0 rounded-t-2xl border-b-0 bg-transparent p-6 pb-0">
                        <img
                          src="/logo-2.png"
                          alt="logo"
                          className="mx-auto mb-4 block w-32"
                        />
                        <h3 className="relative z-10 bg-gradient-to-tl from-primary to-primary/70 bg-clip-text text-center font-bold text-transparent">
                          Connectez-vous
                        </h3>
                        <p className="mb-0 text-center">
                          <span className="text-sm text-gray-500">
                            Entrez vos informations pour vous connecter
                          </span>
                        </p>
                      </div>
                      <div className="flex-auto p-6">
                        <Formik
                          initialValues={{
                            email: '',
                            password: '',
                          }}
                          validationSchema={LoginSchema}
                          onSubmit={(values) => {
                            if (!loading) {
                              connect(values);
                            }
                          }}
                          classNam="w-full"
                        >
                          {({ errors, touched }) => (
                            <Form className="flex w-full flex-col space-y-4">
                              <div className="flex flex-col items-start justify-start space-y-1">
                                <h3 className="text-xl leading-normal text-gray-900">
                                  Email
                                </h3>
                              </div>
                              <Field
                                name="email"
                                type="email"
                                placeholder="mymail@gmail.com"
                                className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                              />
                              {errors.email && touched.email ? (
                                <div className="text-red-400">
                                  {errors.email}
                                </div>
                              ) : null}

                              <div className="flex flex-col items-start justify-start space-y-1">
                                <h3 className="text-xl leading-normal text-gray-900">
                                  Password
                                </h3>
                              </div>
                              <div className="relative w-full">
                                <Field
                                  className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                                  name="password"
                                  type={showPassword ? 'text' : 'password'}
                                  placeholder=""
                                />
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="gray"
                                  className="bi bi-eye absolute top-1/2 right-3 -translate-y-1/2 cursor-pointer"
                                  viewBox="0 0 16 16"
                                  onClick={changePasswordType}
                                >
                                  <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                                  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                                </svg>
                              </div>
                              {errors.password && touched.password ? (
                                <div className="text-red-400">
                                  {errors.password}
                                </div>
                              ) : null}
                              <a
                                className="text-right text-sm leading-none text-secondary"
                                href="/forgot-password"
                              >
                                Mot de passe oublié?
                              </a>
                              <br />
                              {message.length > 0 && (
                                <div
                                  className={`mb-4 text-center text-sm ${
                                    message[0] ? 'text-primary' : 'text-red-500'
                                  }`}
                                >
                                  {message[1]}
                                </div>
                              )}
                              <button
                                type="submit"
                                className="active:opacity-85 hover:scale-102 hover:shadow-soft-xs leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mt-6 mb-2 inline-block w-full cursor-pointer rounded-lg border-0 bg-gradient-to-tl from-primary to-primary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary hover:bg-primary hover:text-white"
                              >
                                {loading ? 'En cours' : 'Se connecter'}
                                {loading && <ButtonLoading />}
                              </button>
                            </Form>
                          )}
                        </Formik>
                        <p className="mt-4 mb-0 text-center text-sm leading-normal">
                          Vous n&apos;avez pas de compte ?{' '}
                          <a
                            href="/register"
                            className="font-bold text-primary"
                          >
                            Créer un compte
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="border-t-solid rounded-b-2xl border-t-0 bg-transparent p-6 px-1 pt-0 text-center lg:px-2">
                    <footer className="">
                      <div className="container">
                        <div className="-mx-3 flex flex-wrap">
                          <div className="lg:flex-0 mx-auto mt-2 mb-6 flex w-full max-w-full flex-shrink-0 items-center justify-center text-center lg:w-8/12">
                            <a
                              href="/"
                              target="_blank"
                              className="mr-6 text-slate-400 hover:text-secondary"
                              rel="noreferrer"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g clipPath="url(#clip0_8_2)">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 12.067C0 18.033 4.333 22.994 10 24V15.333H7V12H10V9.333C10 6.333 11.933 4.667 14.667 4.667C15.533 4.667 16.467 4.8 17.333 4.933V8H15.8C14.333 8 14 8.733 14 9.667V12H17.2L16.667 15.333H14V24C19.667 22.994 24 18.034 24 12.067C24 5.43 18.6 0 12 0C5.4 0 0 5.43 0 12.067Z"
                                    fill="currentColor"
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_8_2">
                                    <rect width="24" height="24" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </a>
                            <a
                              href="/"
                              target="_blank"
                              className="mr-6 text-slate-400 hover:text-secondary"
                              rel="noreferrer"
                            >
                              <svg
                                width="26"
                                height="21"
                                viewBox="0 0 26 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.11849 21C17.8717 21 23.207 12.9187 23.207 5.9228C23.207 5.69666 23.207 5.46729 23.1973 5.24115C24.236 4.4892 25.1325 3.55825 25.8448 2.49191C24.8745 2.91972 23.8463 3.20168 22.7935 3.32863C23.9024 2.66578 24.733 1.62283 25.1308 0.393637C24.0889 1.01088 22.9486 1.44418 21.7597 1.67457C20.9604 0.823345 19.9027 0.259434 18.7505 0.0701857C17.5983 -0.119063 16.4158 0.0769118 15.3863 0.627754C14.3567 1.1786 13.5375 2.05356 13.0556 3.11713C12.5736 4.18069 12.4559 5.37349 12.7205 6.51077C10.6121 6.40505 8.54948 5.85733 6.66641 4.90314C4.78333 3.94894 3.12186 2.60957 1.78975 0.971914C1.11349 2.13991 0.907109 3.5215 1.21252 4.83614C1.51793 6.15077 2.31223 7.29989 3.43412 8.05015C2.59343 8.02159 1.77121 7.79584 1.03379 7.39111V7.4638C1.03524 8.68736 1.45911 9.87289 2.23375 10.82C3.0084 11.7671 4.0863 12.4177 5.28526 12.6618C4.83017 12.7872 4.36006 12.8497 3.88802 12.8476C3.55525 12.8486 3.22314 12.8178 2.89623 12.7555C3.2351 13.8088 3.89493 14.7297 4.7833 15.3892C5.67167 16.0488 6.74409 16.4139 7.85035 16.4336C5.97104 17.9096 3.64961 18.7102 1.25993 18.7063C0.838858 18.7081 0.418077 18.6838 0 18.6336C2.42539 20.1799 5.24212 21.0009 8.11849 21V21Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </a>
                          </div>
                        </div>
                        <div className="-mx-3 flex flex-wrap">
                          <div className="flex-0 mx-auto mt-1 w-8/12 max-w-full px-3 text-center">
                            <p className="mb-0 text-slate-400">
                              Copyright © Tous droits réservés. @{' '}
                              {new Date().getFullYear()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </div>
                </div>
                <div className="lg:flex-0 w-full max-w-full shrink-0 px-3 md:w-6/12">
                  <div className="-skew-x-10 absolute top-0 -right-40 -mr-32 hidden h-full w-3/5 overflow-hidden rounded-bl-xl md:block">
                    <div
                      className="skew-x-10 absolute inset-x-0 top-0 z-0 -ml-16 h-full bg-cover"
                      style={{
                        backgroundImage: 'url(/compet-login.jpg)',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default SignIn;
