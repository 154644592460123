import { userRequest } from '.';

export const postInvitation = async (invitation) => {
  return userRequest.post(`invitations`, invitation);
};

export const getUserInvitations = async (offset) => {
  return userRequest.get(`invitations/me?offset=${offset}`);
};

export const getMyClubInvitations = async (offset) => {
  return userRequest.get(`invitations/club?offset=${offset}`);
};

export const getInvitations = async (offset) => {
  return userRequest.get(`invitations?offset=${offset}`);
};
