import { userRequest } from '.';

export const getContests = async (offset) => {
  return userRequest.get(`contests?offset=${offset}`);
};

export const getPublicContests = async (offset) => {
  return userRequest.get(`contests/public?offset=${offset}`);
};

export const getContest = async (id) => {
  return userRequest.get(`contests/${id}`);
};

export const getContestsByType = async (type, offset) => {
  return userRequest.get(`contests/type/${type}?offset=${offset}`);
};

export const postContest = async (contest) => {
  return userRequest.post(`contests`, contest);
};

export const putContest = async (id, contest) => {
  return userRequest.put(`contests/${id}`, contest);
};

export const searchClub = async (query, type, offset) => {
  return userRequest.get(
    `clubs/search?query=${query}&type=${type}&offset=${offset}`
  );
};

export const searchContest = async (query, type, offset) => {
  return userRequest.get(
    `contests/search?query=${query}&type=${type}&offset=${offset}`
  );
};
