/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { getContestsByType, searchContest } from './api/contest';
import DecisionTreeModal from './components/DecisionModal';

const Competitions = () => {
  const navigate = useNavigate();
  const [showPopUp, setShowPopUp] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingCompooent, setLoadingComponent] = useState(false);
  const [message, setMessage] = useState({});
  const [currentLimit, setNumberCompetition] = useState(0);
  const [competitions, setCompetitions] = useState([]);
  const [competition, setCompetition] = useState({});
  const championshipRef = useRef(null);
  const [search, setSearch] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const updateContests = (res, limit) => {
    setCompetitions(
      res?.data?.map((item) => {
        return {
          id: item?.contest?.id,
          data: item.contest,
          teamCount: item?.teamCount,
        };
      })
    );
    setNumberCompetition(limit);
  };

  const getContests = () => {
    setLoading(true);
    const limit = currentLimit + 20;
    getContestsByType('challenge', limit)
      .then((res) => {
        updateContests(res, limit);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    getContests();
  }, []);

  const list = [
    {
      name: 'name',
      type: 'text',
      title: 'Nom de la contest',
      placeholder: 'Les Tigres du Bénin',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
  ];

  const displayPopUp = (data) => {
    setShowPopUp(!showPopUp);
    setCompetition(data);
  };

  const openContest = (id) => {
    navigate(`/contest/${id}`);
  };

  const closeModal = () => {
    setShowPopUp(false);
  };

  const renderType = (type) => {
    switch (type) {
      case 'one':
        return 'Tête à tête';
      case 'doubles':
        return 'Doublette';
      case 'triples':
        return 'Triplette';
      case 'shot':
        return 'Tir de précision';
      default:
        return '';
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'open':
        return 'Ouvert';
      case 'closed':
        return 'Annulé';
      case 'drawing':
        return 'En cours';
      case 'finished':
        return 'Terminé';
      default:
        return '';
    }
  };

  const onSearchCompetitions = (e, size) => {
    e.preventDefault();
    if (search.length > 2) {
      setSearchMode(true);
      setLoading(true);
      setCompetitions([]);

      let limit = currentLimit + 20;

      if (size) limit = size;
      searchContest(search, 'challenge', limit)
        .then((res) => {
          updateContests(res, limit);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          }
        });
    }
    if (search.length === 0) {
      setSearchMode(false);
    }
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section
              ref={championshipRef}
              className="infinitescroll-container relative w-full md:min-h-screen"
            >
              <header className="mb-6 w-full px-10 py-10">
                <h1 className="text-4xl font-extrabold text-secondary">
                  Concours
                </h1>
                <div className="xt-row mt-6 flex items-center justify-between">
                  <div className="max-w-xs">
                    <form
                      className="relative z-0 flex w-full -space-x-px"
                      onSubmit={(e) => {
                        setNumberCompetition(20);
                        setSearchMode(true);
                        onSearchCompetitions(e, 20);
                      }}
                    >
                      <div className="relative w-full">
                        <svg
                          className="pointer-events-none absolute inset-y-0 left-0 h-full w-8 stroke-gray-400 pl-2.5"
                          viewBox="0 0 256 256"
                          aria-hidden="true"
                        >
                          <circle
                            cx="116"
                            cy="116"
                            r="84"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          />
                          <line
                            x1="175.4"
                            y1="175.4"
                            x2="224"
                            y2="224"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          />
                        </svg>
                        <span htmlFor="search-button" className="sr-only">
                          Rechercher une compétition
                        </span>
                        <input
                          id="search-button"
                          type="search"
                          placeholder="Rechercher une compétition..."
                          className="block w-full rounded-l-md border-gray-200 pl-10 text-sm transition focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (e.target.value.length === 0) {
                              setTimeout(() => {
                                getContests();
                              }, 500);
                            }
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-r border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-800 transition focus:z-10 focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 hover:border-gray-300 hover:bg-gray-100"
                      >
                        Rechercher
                      </button>
                    </form>
                  </div>
                  <button
                    type="button"
                    className="mt-6 flex rounded-full bg-primary py-4 px-7 font-semibold text-white md:mt-0"
                    onClick={displayPopUp}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                        fill="currentColor"
                      />
                    </svg>{' '}
                    Créer un concours
                  </button>
                </div>
              </header>
              {typeof competitions !== 'undefined' &&
                competitions.length === 0 &&
                !loading &&
                !searchMode && (
                  <p className="px-10">
                    Il n&apos;y a pas encore de concours pour le moment.
                  </p>
                )}
              {searchMode && loading && (
                <p className="px-10 text-center">Chargement...</p>
              )}
              {searchMode && !loading && competitions.length === 0 && (
                <p className="px-10 text-center">
                  Aucun concours ne correspond à votre recherche
                </p>
              )}
              <InfiniteScroll
                dataLength={20}
                next={!searchMode ? getContests : onSearchCompetitions}
                hasMore={competitions.length === currentLimit}
                loader={
                  loading && <h4 className="text-center">Chargement...</h4>
                }
                endMessage={
                  currentLimit % 50 > 20 && (
                    <p style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                      <b>Vous avez atteint la fin !</b>
                    </p>
                  )
                }
                refreshFunction={
                  !searchMode ? getContests : onSearchCompetitions
                }
              >
                <div className="flex flex-wrap justify-start gap-4 p-4">
                  {competitions.map((contest) => {
                    return (
                      <div
                        key={contest.id}
                        className="break-inside relative mb-4 flex w-full max-w-[23rem] flex-col justify-between space-y-2 overflow-hidden rounded-xl bg-default p-4 text-sm text-white"
                      >
                        <div className="flex flex-row items-center justify-between space-x-3">
                          <span className="text-xs uppercase text-[#D2BDFF]">
                            {contest.data.name}
                          </span>
                          <span className="ml-1 text-base font-medium">
                            {renderType(contest.data.composition)}
                          </span>
                        </div>
                        <div className="flex flex-row items-center space-x-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-4.783 4.995L12 15a5 5 0 0 1-5-5V7a5 5 0 0 1 4.783-4.995L12 2zm0 2a3 3 0 0 0-2.995 2.824L9 7v3a3 3 0 0 0 5.995.176L15 10V7a3 3 0 0 0-3-3z" />
                          </svg>
                          <span>Equipes: </span>
                          <span className="ml-1 text-base font-medium">
                            {contest.teamCount}
                          </span>
                        </div>
                        <div className="flex items-center justify-between">
                          <button
                            type="button"
                            className="flex items-center justify-center space-x-1 rounded-full bg-white px-4 py-2 text-xs font-medium text-black hover:bg-opacity-90"
                            onClick={() => openContest(contest.data.id)}
                          >
                            <span>Entrer</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#000000"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M5 12h13M12 5l7 7-7 7" />
                            </svg>
                          </button>
                          <span className="flex items-center justify-center space-x-1 rounded-full bg-white/30 px-4 py-2 text-xs font-medium text-white hover:bg-opacity-90">
                            {renderStatus(contest.data.status)}
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
              <div className="flex items-center justify-center">
                {showPopUp && (
                  <DecisionTreeModal
                    type="challenge"
                    loading={loadingCompooent}
                    onOpen={showPopUp}
                    onClose={closeModal}
                    load={getContests}
                  />
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competitions;
