/* eslint-disable react/no-unknown-property */
import 'xtendui';
// eslint-disable-next-line import/no-unresolved
import 'xtendui/src/mousefollow';

import React from 'react';

const ScreenLoading = () => {
  return (
    <div
      className="data-xt-mousefollow absolute inset-0 z-50 flex h-screen w-full items-center justify-center transition-all duration-500"
      data-xt-mousefollow
    >
      <div className="flex items-center justify-center space-x-1 text-slate-700 md:text-2xl">
        <div className="flex select-none items-end">
          <span className="mr-1.5">Chargement en cours</span>
          <div className="loader">
            <div className="dot dot1" />
            <div className="dot dot2" />
            <div className="dot dot3" />
            <div className="dot dot4" />
          </div>
        </div>
      </div>
      <span
        className="xt-loader group xt-mousefollow fixed"
        data-xt-mousefollow-target
      >
        <span className="xt-spinner text-primary-500 absolute inset-0 m-auto -mt-1 ml-4 h-6 w-6 opacity-0 transition group-in:opacity-100">
          <svg
            viewBox="0 0 240 240"
            className="absolute"
            preserveAspectRatio="xMinYMin meet"
          >
            <circle
              className="origin-center stroke-current opacity-25"
              fill="none"
              strokeWidth="30"
              cx="120"
              cy="120"
              r="100"
              strokeDasharray="628"
              strokeDashoffset="0"
              pathLength="628"
            />
          </svg>
          <svg viewBox="0 0 240 240">
            <circle
              className="xt-icon relative origin-center animate-xt-spinner stroke-current"
              fill="none"
              strokeWidth="30"
              cx="120"
              cy="120"
              r="100"
              strokeDasharray="628"
              strokeDashoffset="628"
              pathLength="628"
            />
          </svg>
        </span>
      </span>
    </div>
  );
};

export default ScreenLoading;
