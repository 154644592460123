/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-alert */
/* eslint-disable prefer-const */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/aria-role */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import ButtonLoading from './ButtonLoading';

const ConfirmationModal = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    if (show) {
      setShow(false);

      setTimeout(() => {
        if (props.onOpen) {
          props.onClose();
        }
      }, 50);
    }
  };

  const modalRef = useDetectClickOutside({ onTriggered: handleClose });

  useEffect(() => {
    setShow(true);
  }, []);

  function onSubmit(e) {
    e.preventDefault();

    if (loading) return;

    setLoading(true);

    switch (props?.type) {
      case 'start':
        props.updateContest('drawing');
        break;
      case 'finish':
        props.updateContest('finished');
        break;
      case 'cancel':
        props.updateContest('closed');
        break;
      default:
        props.updateContest('open');
        break;
    }
    props.setMessage('');
  }

  return (
    <div
      className={`decision-tree sticky-0 fixed inset-0 z-[999] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-80 px-3 transition-all
      ${show ? 'bg-gray-900' : 'bg-transparent'}`}
    >
      <div
        ref={modalRef}
        className="relative mx-auto w-full max-w-md overflow-hidden rounded-3xl bg-white"
      >
        <div className="max-h-[80vh] overflow-y-scroll p-10 px-4 pt-4 pb-16 shadow transition-all scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200 xl:pb-8">
          <header id="header-3a" className="gap- flex items-center">
            <h1 className="text-xl font-bold">
              {!props.contest.statistic?.length &&
                props.contest.status === 'drawing' &&
                'Confirmer'}

              {props.contest.status === 'open' &&
                `Démarrer ${
                  props.contest.type === 'challenge'
                    ? ' la concours'
                    : 'le championnat'
                }`}
            </h1>
            <button
              type="button"
              className="ml-auto inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-5 text-sm font-medium tracking-wide text-gray-500 transition duration-300 focus:text-primary focus-visible:outline-none disabled:cursor-not-allowed"
              aria-label="close dialog"
              onClick={handleClose}
            >
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  role="graphics-symbol"
                  aria-labelledby="title-79 desc-79"
                >
                  <title id="title-79">Close</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </header>

          {/* Section when teams count doesn't allow status change actions */}
          {props.contest.teams.length < 8 && (
            <div className="mx-4 flex flex-col py-6">
              <p className="my-3 text-red-700">
                Vous devez enregistrer au moins 8 (huit) équipes pour pouvoir
                commencer le{' '}
                {props.contest.type === 'challenge'
                  ? 'concours'
                  : 'championnat'}
              </p>

              <button
                onClick={() => props.onClose()}
                className="ml-auto w-fit rounded-xl bg-secondary px-3 py-2 text-white"
              >
                Fermer
              </button>
            </div>
          )}

          <div
            className={`flex-col gap-4 pt-8 ${
              props.contest.teams.length >= 8 ? 'flex' : 'hidden'
            }`}
          >
            <div className="pb-9">
              <form onSubmit={(e) => onSubmit(e)}>
                {props.type === 'start' && (
                  <p className="mb-4">
                    Vous êtes sur le point de lancer le{' '}
                    {props.contest.type === 'challenge'
                      ? 'concours'
                      : 'championnat'}
                    . Êtes vous sûr de vouloir continuer ?
                    <br />
                  </p>
                )}
                {props.type === 'cancel' && (
                  <p className="mb-4">
                    Vous êtes sur le point d&apos;annuler le{' '}
                    {props.contest.type === 'challenge'
                      ? 'concours'
                      : 'championnat'}
                    . Êtes vous sûr de vouloir continuer ?
                    <br />
                  </p>
                )}
                {props.type === 'finish' && props.statsReady && (
                  <p className="mb-4">
                    Vous êtes sur le point de clôturer le{' '}
                    {props.contest.type === 'challenge'
                      ? 'concours'
                      : 'championnat'}
                    . Une fois envoyé, les résultats seront rendus publics.
                    <br />
                  </p>
                )}
                {props.type === 'finish' && !props.statsReady && (
                  <p className="mb-4">
                    Il n'est pas possible de clôturer le{' '}
                    {props.contest.type === 'challenge'
                      ? 'concours'
                      : 'championnat'}{' '}
                    sans avoir émis les statistiques.
                    <br />
                  </p>
                )}
                <br />
                <br />
                {props.message?.type && (
                  <div
                    className={`text-center text-sm ${
                      props.message.type === 'success'
                        ? 'text-green-500'
                        : 'text-red-500'
                    }`}
                  >
                    {props.message.text}
                    <br />
                    <br />
                  </div>
                )}

                {((props.type === 'finish' && props.statsReady) ||
                  props.type === 'start') && (
                  <div className="flex">
                    <button
                      onClick={props.onClose}
                      className="relative mx-auto rounded-xl bg-secondary px-3 py-2 text-white"
                    >
                      Revenir en arrière
                    </button>
                    <button
                      type="submit"
                      className={`close-button relative mx-auto rounded-xl bg-primary px-3 py-2 text-white
                      ${!loading && 'duration-300 hover:scale-105'}`}
                    >
                      {loading && 'En cours'}
                      {!loading && 'Confirmer'}
                      {loading && <ButtonLoading />}
                    </button>
                  </div>
                )}

                {props.type === 'finish' && !props.statsReady && (
                  <div className="flex justify-center">
                    <button
                      onClick={props.onClose}
                      className="relative rounded-xl bg-secondary px-3 py-2 text-white"
                    >
                      J'ai compris
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
          {props.loading && (
            <div className="xt-loader absolute inset-0 z-content overflow-hidden rounded-inherit">
              <div className="xt-spinner text-primary-500 absolute inset-0 m-auto h-6 w-6">
                <svg
                  viewBox="0 0 240 240"
                  className="absolute scale-150"
                  preserveAspectRatio="xMinYMin meet"
                >
                  <circle
                    className="origin-center stroke-current opacity-25"
                    fill="none"
                    strokeWidth="30"
                    cx="120"
                    cy="120"
                    r="100"
                    strokeDasharray="628"
                    strokeDashoffset="0"
                    pathLength="628"
                  />
                </svg>
                <svg viewBox="0 0 240 240">
                  <circle
                    className="relativext-icon origin-center animate-xt-spinner stroke-current"
                    fill="none"
                    strokeWidth="30"
                    cx="120"
                    cy="120"
                    r="100"
                    strokeDasharray="628"
                    strokeDashoffset="628"
                    pathLength="628"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ConfirmationModal.propTypes = {
  onOpen: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  contest: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    number_of_team: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    notify: PropTypes.bool,
    public: PropTypes.bool,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    drawing_date: PropTypes.string,
    composition: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    shape: PropTypes.string,
    price: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    teams: PropTypes.array.isRequired,
    statistic: PropTypes.arrayOf({
      id: PropTypes.string,
      type: PropTypes.string,
      teams: PropTypes.shape({}),
      contest_id: PropTypes.string,
    }),
  }),
  updateContest: PropTypes.func,
  isClosedModal: PropTypes.bool,
  setIsClosedModal: PropTypes.func,
  message: PropTypes.bool,
  setMessage: PropTypes.func,
  type: PropTypes.string,
};

export default ConfirmationModal;
