/* eslint-disable prefer-const */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable consistent-return */
/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

const StatsTeamsFiller = ({
  setStepTeams,
  stepTeams,
  stepTeamsLimit,
  contestTeams,
  teams,
  renderPlayerName,
  badgeText,
  contest,
}) => {
  const [teamsIds, setTeamsIds] = useState([]);

  const isTeamRegistered = (value) => {
    if (stepTeams.includes(value)) return true;
    return false;
  };

  // Function to reference each team
  const teamsReferencing = () => {
    let references = [];
    contestTeams.forEach((team) => {
      references.push(team.id);
    });
    return references;
  };

  // Function to decode each reference to get the correct id
  const decodeReference = (id) => {
    return teamsIds.indexOf(id) + 1;
  };

  useEffect(() => {
    setTeamsIds(teamsReferencing());
  }, []);

  return (
    <div className="flex justify-between space-x-2 p-3 pb-32 font-sans sm:pb-8">
      <div className="min-h-12 w-full max-w-xs overflow-hidden rounded-md border border-solid border-gray-300 bg-white bg-clip-padding px-4 py-2">
        <p className="text-black">
          {contest?.status === 'drawing' && contest?.type === 'challenge'
            ? 'Sélectionnez'
            : 'Sélectionnez les équipes'}
        </p>
        {stepTeams.length >= 0 &&
          stepTeams.map((team) => {
            return (
              <div
                className="my-2 flex items-center justify-between space-x-2 rounded-md border border-slate-300 px-3 py-2"
                key={team.id}
              >
                <div>{`Équipe ${decodeReference(team.id)}`}</div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setStepTeams(
                        stepTeams.filter((sp) => {
                          return sp !== team;
                        })
                      );
                    }}
                    className="flex h-10 w-10 items-center justify-center rounded-3xl bg-red-500"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                      className="h-5 w-5"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M7 6V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5zm2-2v2h6V4H9z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            );
          })}
      </div>

      <div className="grid h-fit w-full max-w-6xl grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
        {teams?.length > 0 &&
          teams?.map((list, i) => {
            return (
              <button
                className={`bg-lightgreen space-Y-5 my-2 flex h-44 max-h-fit w-full flex-col items-center justify-start overflow-hidden rounded-sm border border-dashed border-slate-300 px-1
                  ${
                    isTeamRegistered(list)
                      ? 'border-green-400 bg-green-100'
                      : 'border-slate-300 hover:bg-slate-100'
                  }`}
                key={i}
                onClick={() => {
                  !isTeamRegistered(list)
                    ? setStepTeams([...stepTeams, list])
                    : '';
                }}
                disabled={stepTeams.length === stepTeamsLimit}
              >
                <div
                  className={`my-2 flex w-full items-center justify-between space-x-2 border-b
                      py-2
                      ${
                        isTeamRegistered(list)
                          ? 'border-green-400'
                          : 'border-slate-300 hover:bg-slate-100'
                      }
                `}
                >
                  <button
                    type="button"
                    className={`mr-auto flex flex-1 items-center
                           text-gray-800
                           ${
                             isTeamRegistered(list)
                               ? 'justify-around space-x-4'
                               : 'justify-center space-x-0'
                           }
                           `}
                  >
                    <div>{`${decodeReference(list.id)}`}</div>
                    {badgeText && (
                      <div
                        className={`rounded-full bg-green-400 p-3 ${
                          isTeamRegistered(list) ? 'block' : 'hidden'
                        }`}
                      >
                        {badgeText}
                      </div>
                    )}
                  </button>
                </div>

                {list.players.map((player, idx) => {
                  return (
                    <div
                      className="flex items-center justify-between space-x-2 p-4 px-3 py-2"
                      key={idx}
                    >
                      {renderPlayerName(player?.firstName, player?.lastName)}
                    </div>
                  );
                })}

                {list.players.length === 0 && (
                  <div className="flex items-center justify-between space-x-2 p-4 px-3 py-2 text-amber-400">
                    Aucun joueur enregistré
                  </div>
                )}
              </button>
            );
          })}

        {teams?.length === 0 && (
          <h3 className="align-items-center text-red-700">
            Aucune équipe n'est encore parvenue à cette étape
          </h3>
        )}
      </div>
    </div>
  );
};

// StatsTeamsFiller.propTypes = {
//   setStepTeams: checkPropTypes.func.isRequired,
// };

export default StatsTeamsFiller;
