/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import { Field, Form, Formik } from 'formik';
import {
  addKkiapayCloseListener,
  addKkiapayListener,
  openKkiapayWidget,
  removeKkiapayListener,
} from 'kkiapay';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { send } from './api/donation';
import { getAllProjects } from './api/projet';
import { getMyProfile } from './api/user';
import countries from './assets/countries.json';
import ButtonLoading from './components/ButtonLoading';

const Donation = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [user, setUser] = useState({
    fromDisk: false,
  });
  const [entrepriseChecked, setEntrepriseChecked] = useState(false);
  const [contactChecked, setContactChecked] = useState(false);
  const profileSchema = Yup.object().shape({
    source: Yup.string().required('Source requise'),
    projet: Yup.string().required('Choisissez un projet'),
    montant: Yup.string().required('Montant requis'),
    optionPaiement: Yup.string().required('Option de paiement requis'),
  });
  const [projects, setProjects] = useState([]);
  const [formData, setFormData] = useState();
  const formRef = useRef(null);
  const [transactionId, setTransactionId] = useState('');

  const fetchProjects = () => {
    getAllProjects()
      .then((res) => {
        if (res.data) {
          setProjects([
            {
              value: '',
              name: 'Sélectionner',
            },
            ...res.data.projects
              .filter((project) => project.status === 'open')
              .map((project) => {
                return {
                  value: project?.id,
                  name: project?.name,
                };
              }),
          ]);
        }
      })
      .catch(() => {
        setMessage({
          type: 'error',
          text: 'Une erreur est survenue lors de la récupération des projects',
        });
      });
  };

  const formikField = (field) => {
    if (field.type === 'select') {
      if (field.hasValidation) {
        return (
          <Field
            as="select"
            name={field.name}
            className="w-full rounded border border-gray-300 bg-white p-3.5"
            validate={field.validate}
          >
            {field.listOptions.map((option) => {
              return <option value={option.value}>{option.name}</option>;
            })}
          </Field>
        );
      }
      return (
        <Field
          as="select"
          name={field.name}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
          onChange={(e) => {
            formRef.current.setFieldValue(field.name, e.target.value);
          }}
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    if (field.hasValidation) {
      if (field.type === 'textarea') {
        return (
          <Field
            name={field.name}
            as="textarea"
            placeholder={field.placeholder}
            className="w-full rounded border border-gray-300 bg-white p-3.5"
            validate={field.validate}
          />
        );
      }
      return (
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
          validate={field.validate}
        />
      );
    }
    if (field.type === 'radio') {
      const elts = field.listOptions.map((opt) => {
        return (
          <label htmlFor={field.name}>
            <Field type="radio" name={field.name} value={opt} />
            <p className="ml-2 inline">{opt}</p>
          </label>
        );
      });
      return (
        <div
          role="group"
          aria-labelledby="radio-group"
          className="flex items-center justify-start space-x-2"
          onChange={(e) => {
            if (field.name === 'source') {
              if (e.target.value === 'Entreprise') setEntrepriseChecked(true);
              else setEntrepriseChecked(false);
            } else if (e.target.value === 'Entrer en contact')
              setContactChecked(true);
            else setContactChecked(false);
          }}
        >
          {elts}
        </div>
      );
    }
    if (field.type === 'number') {
      return (
        <Field
          type="number"
          step="1"
          min="0"
          name={field.name}
          placeholder={field.placeholder}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
        />
      );
    }
    return (
      <Field
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
      />
    );
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const renderCharacter = (string) => {
    if (string) {
      return (
        String(string?.slice(0, 1)).toUpperCase() +
        String(string?.slice(1)).toLowerCase()
      );
    }
    return '';
  };

  const getProfile = () => {
    getMyProfile()
      .then((res) => {
        localStorage?.setItem('xrole', res?.data?.role?.name);
        const CONNECTED_USER = res?.data;
        const VERIFIED = CONNECTED_USER?.verified;
        setUser({
          ...user,
          id: CONNECTED_USER?.id,
          firstName: renderCharacter(CONNECTED_USER?.firstName),
          lastName: renderCharacter(CONNECTED_USER?.lastName),
          email: CONNECTED_USER?.email || '',
          phone: CONNECTED_USER?.phone || '',
          profile: CONNECTED_USER?.profile || '',
          occupation: CONNECTED_USER?.occupation || '',
          verified: VERIFIED,
          birthDate: CONNECTED_USER?.birthDate || '',
          country: CONNECTED_USER?.country || countries.fr[0].value,
          bio: CONNECTED_USER?.bio || '',
          club: CONNECTED_USER?.club || '',
          ligue: CONNECTED_USER?.ligue || '',
          license: CONNECTED_USER?.license || '',
        });
        setTimeout(() => {}, 500);
        if (!VERIFIED) {
          setMessage([
            false,
            'Votre compte doit être vérifié pour pouvoir être effectuer cette action',
          ]);
        }
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setTimeout(() => {}, 500);
      });
  };

  const list = [
    {
      name: 'source',
      type: 'radio',
      listOptions: ['Nom', 'Entreprise'],
      placeholder: 'Source',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'nom',
      type: 'text',
      placeholder: "Nom de l'entreprise",
      show: entrepriseChecked,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'projet',
      type: 'select',
      listOptions: projects,
      placeholder: 'Choix du projet',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'montant',
      type: 'number',
      placeholder: 'Montant',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'optionPaiement',
      type: 'radio',
      listOptions: ['Paiement immédiat', 'Entrer en contact'],
      placeholder: 'Option de paiement',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'numero',
      type: 'text',
      placeholder: 'Numéro',
      show: contactChecked,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'email',
      type: 'email',
      placeholder: 'Email',
      show: contactChecked,
      hasValidation: false,
      semiWidth: true,
    },
  ];

  const successHandler = (response) => {
    setTransactionId(response?.transactionId);
  };

  useEffect(() => {
    getProfile();
    fetchProjects();

    addKkiapayListener('success', successHandler);
    addKkiapayListener('error', (error) => {
      setLoading(false);
    });
    addKkiapayCloseListener(() => {
      setLoading(false);
    });
    return () => {
      removeKkiapayListener('success', successHandler);
    };
  }, []);

  useEffect(() => {
    if (transactionId && !loading) {
      setLoading(true);
      formData.append('Id de transaction', transactionId);
      send(formData)
        .then(() => {
          setTransactionId('');
          setTimeout(() => {
            setLoading(false);
          }, 500);
          setMessage([
            true,
            'Votre don a été envoyée. Merci pour votre contribution',
          ]);
        })
        .catch(() => {
          setLoading(false);
          setMessage([false, 'Une erreur est survenue, veuillez réessayer']);
        });
    }
  }, [loading]);

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section className="relative w-full md:min-h-screen">
              <div className="body-font text-gray-600">
                <div className="container mx-auto px-5 py-24">
                  <div className="mb-2 md:w-2/4">
                    <h2 className="title-font text-2xl font-medium text-gray-900 sm:text-3xl">
                      Faire un don
                    </h2>
                    <Formik
                      innerRef={formRef}
                      enableReinitialize
                      initialValues={{
                        nom: '',
                        source: '',
                        projet: '',
                        montant: '',
                        optionPaiement: '',
                        numero: '',
                        email: user?.email || '',
                      }}
                      validationSchema={profileSchema}
                      onSubmit={(values, { setErrors, setValues }) => {
                        let errors = {};

                        if (!loading) {
                          setMessage([]);
                          const data = new FormData();

                          if (values.source === 'Nom') {
                            data.append('Nom', user.firstName);
                            data.append('Prenom', user.lastName);
                          } else if (!user.nom) {
                            errors = {
                              ...errors,
                              nom: 'Ce champ est obligatoire',
                            };
                          } else {
                            data.append('Entreprise', user.nom);
                          }
                          data.append(
                            'Projet',
                            projects.find((p) => p.value === values.projet)
                              ?.name
                          );
                          data.append('Montant', values.montant);
                          data.append(
                            'Option de paiement',
                            values.optionPaiement
                          );

                          if (values.optionPaiement === 'Entrer en contact') {
                            if (!values.numero) {
                              errors = {
                                ...errors,
                                numero: 'Champ obligatoire',
                              };
                            } else {
                              data.append('Numero', values.numero);
                            }
                            if (!values.email) {
                              errors = {
                                ...errors,
                                email: 'Champ obligatoire',
                              };
                            } else {
                              data.append('Email', values.email);
                            }
                          }

                          setErrors(errors);
                          if (Object.keys(errors).length > 0) return;

                          setLoading(true);

                          if (values.optionPaiement === 'Paiement immédiat') {
                            setFormData(data);
                            setTransactionId('');
                            openKkiapayWidget({
                              email: user.email || '',
                              phone: user.phone || '',
                              amount: Number(values.montant || 0),
                              api_key: process.env.REACT_APP_KKIAPAY_API_KEY,
                              sandbox: true,
                            });
                          } else {
                            send(data)
                              .then(() => {
                                setLoading(false);
                                setMessage([
                                  true,
                                  'Votre don a été envoyée. Merci pour votre contribution',
                                ]);
                              })
                              .catch(() => {
                                setLoading(false);
                                setMessage([
                                  false,
                                  'Une erreur est survenue, veuillez réessayer',
                                ]);
                              });
                          }
                        }
                      }}
                    >
                      {({ errors, touched, setFieldValue }) => (
                        <Form className="flex flex-col gap-4 pt-8">
                          <div className="flex flex-wrap justify-between gap-y-4">
                            {list.map((field) => {
                              return (
                                field.show && (
                                  <div
                                    key={field.name}
                                    className={`flex w-full flex-col md:${
                                      field.semiWidth && 'w-1/2'
                                    }`}
                                  >
                                    <div className="flex flex-col items-start justify-start space-y-1">
                                      <h3 className="text-xl leading-normal text-gray-900">
                                        {[field.placeholder]}
                                      </h3>
                                    </div>
                                    <div className="flex flex-col items-start justify-start space-y-1">
                                      <h3 className="text-xl leading-normal text-gray-900">
                                        {[field.title]}
                                      </h3>
                                    </div>
                                    <div className="pr-8">
                                      {formikField(field, setFieldValue)}
                                      {errors[field.name] &&
                                      touched[field.name] ? (
                                        <div className="text-red-400">
                                          {errors[field.name]}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                )
                              );
                            })}
                          </div>
                          <div
                            className={`text-center text-sm ${
                              message[0] ? 'text-primary' : 'text-secondary'
                            }`}
                          >
                            {message[1]}
                          </div>
                          <div className="mr-8 flex justify-end">
                            <button
                              type="submit"
                              disabled={!user?.id}
                              className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                              data-class="bg-transparent"
                            >
                              {loading ? 'En cours' : 'Valider'}
                              {loading && <ButtonLoading />}
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donation;
