/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */

import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { getAllLigues } from '../api/ligue';
import { send } from '../api/mutation';
import { getAllClubs, getClubs, getMyProfile } from '../api/user';
import countries from '../assets/countries.json';
import ButtonLoading from './ButtonLoading';

const MutationRequestForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [leagues, setLeagues] = useState([]);
  const [clubs, setClubs] = useState([]);
  const [leagueId, setLeagueId] = useState();
  const [user, setUser] = useState({
    fromDisk: false,
  });
  const [showMutationFields, setShowMutationFields] = useState(false);
  const [showClubField, setShowClubField] = useState(true);
  const formRef = useRef(null);

  const requestAllClubs = () => {
    // Function to fetch all clubs
    getAllClubs().then((res) => {
      setClubs([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((clubRow) => {
          return {
            value: clubRow?.club?.id,
            name: clubRow?.club?.name,
          };
        }),
      ]);
    });
  };

  const requestClubs = (ligueId) => {
    getClubs(ligueId).then((res) => {
      setClubs([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((clubData) => {
          return {
            value: clubData?.club?.id,
            name: clubData?.club?.name,
          };
        }),
      ]);
    });
  };

  const profileSchema = Yup.object().shape({
    license: Yup.string().required(
      'Veuillez renseigner votre numéro de licence'
    ),
    lastName: Yup.string().required('Nom requis'),
    firstName: Yup.string().required('Prénom requis'),
    birthDate: Yup.string().required('Date de naissance requis'),
    address: Yup.string().required('Adresse requis'),
    category: Yup.string().required('Sélectionner une option'),
    country: Yup.string().required('Pays requis'),
    ligue: Yup.string(),
    club: leagueId ? Yup.string().required('Club requis') : Yup.string(),
    changeLeague: Yup.string().required('Sélectionner une option'),
  });

  const mutationFieldsActions = (value) => {
    if (value) {
      setShowMutationFields(true);
      setShowClubField(false);
    } else {
      setShowMutationFields(false);
      setShowClubField(true);
    }
  };

  const formikField = (field, setFieldValue) => {
    if (field.type === 'select') {
      if (field.hasValidation) {
        return (
          <Field
            as="select"
            name={field.name}
            className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
            validate={field.validate}
          >
            {field.listOptions.map((option) => {
              return <option value={option.value}>{option.name}</option>;
            })}
          </Field>
        );
      }
      return (
        <Field
          as="select"
          name={field.name}
          className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
          onChange={(e) => {
            formRef.current.setFieldValue(field.name, e.target.value);
            if (field.name === 'nextLeague' && e.target.value !== '')
              requestClubs(e.target.value);
          }}
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    if (field.hasValidation) {
      if (field.type === 'textarea') {
        return (
          <Field
            name={field.name}
            as="textarea"
            placeholder={field.placeholder}
            className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
            validate={field.validate}
          />
        );
      }
      return (
        <Field
          name={field.name}
          type={field.type}
          placeholder={field.placeholder}
          className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
          validate={field.validate}
        />
      );
    }
    if (field.type === 'textarea') {
      return (
        <Field
          as="textarea"
          name={field.name}
          placeholder={field.placeholder}
          className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
        />
      );
    }
    if (field.type === 'file') {
      return (
        <div>
          <div className="flex items-center">
            <Field
              id="dropzone-file"
              name="file"
              type="file"
              accept="image/png, image/jpeg, image/jpg, application/pdf"
              multiple
              onChange={(event) => {
                setFieldValue('proof', event.currentTarget.files);
              }}
              className="form-control z-10"
            />
          </div>
        </div>
      );
    }
    if (field.type === 'radio') {
      if (!field.mutationField) {
        return (
          <div
            role="group"
            aria-labelledby="radio-group"
            className="flex items-center justify-start space-x-2"
          >
            <label>
              <Field type="radio" name={field.name} value="M" />
              <p className="ml-2 inline">Masculin</p>
            </label>
            <label>
              <Field type="radio" name={field.name} value="F" />
              <p className="ml-2 inline">Féminin</p>
            </label>
          </div>
        );
      }
      if (field.mutationField) {
        return (
          <div
            role="group"
            aria-labelledby="radio-group"
            className="flex items-center justify-start space-x-2"
            onChange={(e) => {
              e.target.value === 'oui'
                ? mutationFieldsActions(true)
                : mutationFieldsActions(false);
            }}
          >
            <label>
              <Field type="radio" name={field.name} value="oui" />
              <p className="ml-2 inline">Oui</p>
            </label>
            <label>
              <Field type="radio" name={field.name} value="non" />
              <p className="ml-2 inline">Non</p>
            </label>
          </div>
        );
      }
    }
    if (field.type === 'checkbox') {
      return (
        <label>
          <Field type={field.type} name={field.name} id={field.name} />
          <p className="ml-2 inline">{field.purpose}</p>
        </label>
      );
    }
    return (
      <Field
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        className="focus:shadow-soft-primary-outline leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 text-sm font-normal text-gray-700 transition-all focus:border-primary focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
      />
    );
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const getAllLeagues = () => {
    getAllLigues().then((res) => {
      setLeagues([
        {
          value: '',
          name: 'Sélectionner',
        },
        ...res.data.map((leagueRow) => {
          return {
            value: leagueRow?.ligue?.id,
            name: leagueRow?.ligue?.name,
          };
        }),
      ]);
    });
  };

  const renderCharacter = (string) => {
    if (string) {
      return (
        String(string?.slice(0, 1)).toUpperCase() +
        String(string?.slice(1)).toLowerCase()
      );
    }
    return '';
  };

  const getProfile = () => {
    getMyProfile()
      .then((res) => {
        localStorage?.setItem('xrole', res?.data?.role?.name);
        const CONNECTED_USER = res?.data;
        const VERIFIED = CONNECTED_USER?.verified;
        setUser({
          ...user,
          id: CONNECTED_USER?.id,
          firstName: renderCharacter(CONNECTED_USER?.firstName),
          lastName: renderCharacter(CONNECTED_USER?.lastName),
          email: CONNECTED_USER?.email || '',
          phone: CONNECTED_USER?.phone || '',
          profile: CONNECTED_USER?.profile || '',
          occupation: CONNECTED_USER?.occupation || '',
          verified: VERIFIED,
          birthDate: CONNECTED_USER?.birthDate || '',
          country: CONNECTED_USER?.country || countries.fr[0].value,
          bio: CONNECTED_USER?.bio || '',
          club: CONNECTED_USER?.club || '',
          ligue: CONNECTED_USER?.ligue || '',
          license: CONNECTED_USER?.license || '',
        });

        if (CONNECTED_USER?.ligue?.id) {
          setLeagueId(CONNECTED_USER?.ligue?.id);
        } else {
          requestAllClubs();
        }

        setTimeout(() => {}, 500);
        if (!VERIFIED) {
          setMessage([
            false,
            'Votre compte doit être vérifié pour pouvoir être effectuer cette action',
          ]);
        }
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setTimeout(() => {}, 500);
      });
  };

  const list = [
    {
      name: 'license',
      type: 'text',
      placeholder: 'Numéro de licence',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'lastName',
      type: 'text',
      placeholder: 'Nom',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'firstName',
      type: 'text',
      placeholder: 'Prénoms',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'category',
      type: 'radio',
      placeholder: 'Catégorie',
      show: true,
      hasValidation: false,
      semiWidth: true,
      mutationField: false,
    },
    {
      name: 'birthDate',
      type: 'date',
      placeholder: 'Date de naissance',
      show: true,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'club',
      type: 'select',
      listOptions: clubs,
      placeholder: 'Club',
      show: showClubField,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'address',
      type: 'textarea',
      placeholder: 'Adresse',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'changeLeague',
      type: 'radio',
      placeholder: 'Changement de ligue',
      show: true,
      hasValidation: false,
      semiWidth: false,
      mutationField: true,
    },
    {
      name: 'nextLeague',
      type: 'select',
      listOptions: leagues,
      placeholder: 'Prochaine ligue',
      show: showMutationFields,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'nextClub',
      type: 'select',
      listOptions: clubs,
      placeholder: 'Prochain club',
      show: showMutationFields,
      hasValidation: false,
      semiWidth: true,
    },
    {
      name: 'proof',
      type: 'file',
      placeholder:
        'Preuve du paiement des droits de cotisation, la licence, pièce d’identité',
      show: showMutationFields,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'confirmPresident',
      type: 'checkbox',
      purpose: `Je confirme que le président du club ${user?.club} est bien informé de ma démission et de cette demande`,
      show: showMutationFields,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'confirmationClubRules',
      type: 'checkbox',
      purpose: `Je confirme que je suis en règle vis-à-vis du club ${user?.club?.name}`,
      show: showMutationFields,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'confirmPresidentConsent',
      type: 'checkbox',
      purpose: `Le président du club ${user?.club?.name} est favorable à cette mutation`,
      show: showMutationFields,
      hasValidation: false,
      semiWidth: false,
    },
    {
      name: 'consent',
      type: 'checkbox',
      purpose:
        'Je reconnais que les frais de mutation ne sont pas remboursables',
      show: showMutationFields,
      hasValidation: false,
      semiWidth: false,
    },
  ];

  useEffect(() => {
    getAllLeagues();
    getProfile();
  }, []);

  useEffect(() => {
    if (leagueId) requestClubs(leagueId);
  }, [leagueId]);

  useEffect(() => {}, [showMutationFields]);

  const renderDate = (date) => {
    return date
      ? Intl.DateTimeFormat('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date(date))
      : '';
  };

  return (
    <Formik
      innerRef={formRef}
      enableReinitialize
      initialValues={{
        ...user,
        license: user?.license?.id || '',
        club: user?.club?.id || '',
        birthDate: user?.birthDate ? user?.birthDate?.split('T')[0] : '',
        category: '',
        address: '',
        changeLeague: '',
        proof: '',
        nextLeague: '',
        nextClub: '',
        confirmPresident: false,
        confirmationClubRules: false,
        confirmPresidentConsent: false,
        consent: false,
      }}
      validationSchema={profileSchema}
      onSubmit={(values, { setErrors, setValues }) => {
        let errors = {};

        if (!loading) {
          setMessage([]);
          const data = new FormData();
          data.append('Nom', values.firstName);
          data.append('Prenom', values.lastName);
          data.append('Licence', values.license);
          data.append('Date de naissance', renderDate(values.birthDate));
          data.append('Categorie', values.category);
          data.append('Adresse', values.address);
          data.append('Changement de ligue', values.changeLeague);
          const COUNTRY = values.country;
          data.append('Pays', COUNTRY);
          if (user?.club?.id !== values.club) {
            data.append('Club', values.club);
          } else if (values.changeLeague === 'non') {
            errors = {
              ...errors,
              club: 'Vous ne pouvez pas choisir votre club actuel',
            };
          }
          if (values.ligue) data.append('Ligue', values.ligue);

          if (values.changeLeague === 'oui') {
            if (!values.nextLeague) {
              errors = {
                ...errors,
                nextLeague: 'Sélectionner une nouvelle ligue',
              };
            }
            if (!values.nextClub) {
              errors = {
                ...errors,
                nextClub: 'Sélectionner un nouveau club',
              };
            }
            if (!values.proof) {
              errors = {
                ...errors,
                proof: 'Charger les pièces justificatives',
              };
            }

            if (
              !values.confirmPresident ||
              !values.confirmationClubRules ||
              !values.confirmPresidentConsent ||
              !values.consent
            ) {
              errors = {
                ...errors,
                consent: 'Accepter les conditions avant de continuer',
              };
            }
          }

          setErrors(errors);

          if (Object.keys(errors).length > 0) return;

          if (values.changeLeague === 'oui') {
            data.append(
              'Prochaine ligue',
              leagues.find((l) => l.value === values.nextLeague)?.name
            );
            data.append(
              'Prochain club',
              clubs.find((c) => c.value === values.nextClub)?.name
            );
          }

          Array.from(values.proof).forEach((file) => {
            data.append('document', file);
          });

          setLoading(true);
          send(data)
            .then(() => {
              setLoading(false);
              setMessage([
                true,
                'Votre demande a été envoyée. Vous recevrez une réponse dans les plus brefs délais',
              ]);
            })
            .catch(() => {
              setLoading(false);
              setMessage([
                false,
                "Une erreur est survenue lors de l'envoi de votre demande, veuillez réessayer",
              ]);
            });
        }
      }}
    >
      {({ errors, touched, setFieldValue }) => (
        <Form className="flex flex-col gap-4 pt-8">
          <div className="flex flex-wrap justify-between gap-y-4">
            {list.map((field) => {
              return (
                field.show && (
                  <div
                    key={field.name}
                    className={`flex w-full flex-col px-4 md:${
                      field.semiWidth && 'w-1/2'
                    }`}
                  >
                    <div className="flex flex-col items-start justify-start space-y-1">
                      <h3 className="text-xl leading-normal text-gray-900">
                        {[field.placeholder]}
                      </h3>
                    </div>
                    <div className="flex flex-col items-start justify-start space-y-1">
                      <h3 className="text-xl leading-normal text-gray-900">
                        {[field.title]}
                      </h3>
                    </div>
                    <div className="">
                      {formikField(field, setFieldValue)}
                      {errors[field.name] && touched[field.name] ? (
                        <div className="text-red-400">{errors[field.name]}</div>
                      ) : null}
                    </div>
                  </div>
                )
              );
            })}
          </div>
          <div
            className={`text-center text-sm ${
              message[0] ? 'text-primary' : 'text-secondary'
            }`}
          >
            {message[1]}
          </div>
          <div className="flex w-full justify-between">
            <button
              type="button"
              className={`xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-2 ml-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-secondary bg-transparent bg-none px-4 py-3 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:border-secondary
                                  ${
                                    !loading && 'duration-300 hover:scale-105'
                                  }`}
              data-class="bg-white"
              onClick={() => navigate('/')}
            >
              Annuler
            </button>
            <button
              disabled={!user.verified}
              type="submit"
              className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
              data-class="bg-transparent"
            >
              {loading ? 'En cours' : 'Envoyer ma demande'}
              {loading && <ButtonLoading />}
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default MutationRequestForm;
