/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Aside = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hasPanelOpened, setHasPanelOpened] = useState(false);

  useEffect(() => {
    // is path is /
    if (location.pathname !== '/') {
      setHasPanelOpened(true);
    }
  }, [location.pathname]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <aside className="sidebar max-w-62.5 ease-nav-brand z-990 fixed inset-y-0 my-4 ml-4 block w-full -translate-x-full flex-wrap items-center justify-between overflow-y-auto rounded-2xl border-0 bg-white p-0 pr-1 antialiased shadow-none transition-transform duration-200 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-gray-300 xl:left-0 xl:translate-x-0 xl:bg-transparent">
      <div className="h-19.5">
        <i
          className="fas fa-times absolute top-0 right-0 hidden cursor-pointer p-4 text-slate-400 opacity-50 xl:hidden"
          sidenav-close
        />
        <div className="m-0 block whitespace-nowrap px-8 py-6 text-sm text-slate-700">
          <img
            src="/logo-2.png"
            className="ease-nav-brand inline h-full max-h-8 max-w-full transition-all duration-200"
            alt="main_logo"
          />
          <span className="ease-nav-brand ml-1 font-semibold transition-all duration-200">
            LigueAlPetanque
          </span>
        </div>
      </div>

      <hr className="mt-0 h-px bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent" />

      <div className="block max-h-screen w-auto grow basis-full items-center overflow-hidden">
        <ul className="mb-0 flex w-full flex-col pl-0">
          <li className="mt-0.5 w-full">
            <button
              type="button"
              className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
            ${
              isActive('/')
                ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                : 'text-black'
            }`}
              onClick={() => navigate('/')}
            >
              <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.07874 16.1354H14.8937"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.40002 13.713C2.40002 8.082 3.01402 8.475 6.31902 5.41C7.76502 4.246 10.015 2 11.958 2C13.9 2 16.195 4.235 17.654 5.41C20.959 8.475 21.572 8.082 21.572 13.713C21.572 22 19.613 22 11.986 22C4.35903 22 2.40002 22 2.40002 13.713Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                Tableau de bord
              </span>
            </button>
          </li>

          {localStorage?.getItem('xrole') !== 'player' &&
            localStorage?.getItem('xrole') !== 'other' && (
              <>
                <li className="mt-0.5 w-full">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
                  ${
                    isActive('/championships')
                      ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                      : 'text-black'
                  }`}
                    onClick={() => navigate('/championships')}
                  >
                    <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M13 16.938V19h5v2H6v-2h5v-2.062A8.001 8.001 0 0 1 4 9V3h16v6a8.001 8.001 0 0 1-7 7.938zM6 5v4a6 6 0 1 0 12 0V5H6zM1 5h2v4H1V5zm20 0h2v4h-2V5z" />
                      </svg>
                    </div>
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      Championnats
                    </span>
                  </button>
                </li>
                <li className="mt-0.5 w-full">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
                  ${
                    isActive('/competitions')
                      ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                      : 'text-black'
                  }`}
                    onClick={() => navigate('/competitions')}
                  >
                    <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M17 15.245v6.872a.5.5 0 0 1-.757.429L12 20l-4.243 2.546a.5.5 0 0 1-.757-.43v-6.87a8 8 0 1 1 10 0zm-8 1.173v3.05l3-1.8 3 1.8v-3.05A7.978 7.978 0 0 1 12 17a7.978 7.978 0 0 1-3-.582zM12 15a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                      </svg>
                    </div>
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      Concours
                    </span>
                  </button>
                </li>
              </>
            )}

          {localStorage?.getItem('xrole') === 'player' &&
            localStorage?.getItem('xrole') !== 'other' && (
              <li className="mt-0.5 w-full">
                <button
                  type="button"
                  className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
            ${
              isActive('/club')
                ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                : 'text-black'
            }`}
                  onClick={() => navigate('/club')}
                >
                  <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0L24 0 24 24 0 24z" />
                      <path d="M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm10 6c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM6 14c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm8.5-12C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2zm0 2C12.567 4 11 5.567 11 7.5s1.567 3.5 3.5 3.5S18 9.433 18 7.5 16.433 4 14.5 4z" />
                    </svg>
                  </div>
                  <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                    Mon Club
                  </span>
                </button>
              </li>
            )}

          {localStorage?.getItem('xrole') !== 'player' &&
            localStorage?.getItem('xrole') !== 'other' && (
              <>
                <li className="mt-0.5 w-full">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
                  ${
                    isActive('/clubs')
                      ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                      : 'text-black'
                  }`}
                    onClick={() => navigate('/clubs')}
                  >
                    <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0L24 0 24 24 0 24z" />
                        <path d="M16 16c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zM6 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm10 6c-.552 0-1 .448-1 1s.448 1 1 1 1-.448 1-1-.448-1-1-1zM6 14c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2zm8.5-12C17.538 2 20 4.462 20 7.5S17.538 13 14.5 13 9 10.538 9 7.5 11.462 2 14.5 2zm0 2C12.567 4 11 5.567 11 7.5s1.567 3.5 3.5 3.5S18 9.433 18 7.5 16.433 4 14.5 4z" />
                      </svg>
                    </div>
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      Clubs
                    </span>
                  </button>
                </li>
                <li className="mt-0.5 w-full">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
                  ${
                    isActive('/leagues')
                      ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                      : 'text-black'
                  }`}
                    onClick={() => navigate('/leagues')}
                  >
                    <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM11 7h2v4h-2V7zm3 5h2v4h-2v-4zm-6 0h2v4H8v-4z" />
                      </svg>
                    </div>
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      Ligues
                    </span>
                  </button>
                </li>
              </>
            )}

          {localStorage?.getItem('xrole') !== 'admin' &&
            localStorage?.getItem('xrole') !== 'player' && (
              <li className="mt-0.5 w-full">
                <button
                  type="button"
                  className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
                  ${
                    isActive('/players')
                      ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                      : 'text-black'
                  }`}
                  onClick={() => navigate('/players')}
                >
                  <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-4.783 4.995L12 15a5 5 0 0 1-5-5V7a5 5 0 0 1 4.783-4.995L12 2zm0 2a3 3 0 0 0-2.995 2.824L9 7v3a3 3 0 0 0 5.995.176L15 10V7a3 3 0 0 0-3-3z" />
                    </svg>
                  </div>
                  <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                    Joueurs
                  </span>
                </button>
              </li>
            )}

          {localStorage?.getItem('xrole') === 'admin' && (
            <li className="mt-0.5 w-full">
              <button
                type="button"
                className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
            ${
              isActive('/users')
                ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                : 'text-black'
            }`}
                onClick={() => navigate('/users')}
              >
                <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-4.783 4.995L12 15a5 5 0 0 1-5-5V7a5 5 0 0 1 4.783-4.995L12 2zm0 2a3 3 0 0 0-2.995 2.824L9 7v3a3 3 0 0 0 5.995.176L15 10V7a3 3 0 0 0-3-3z" />
                  </svg>
                </div>
                <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                  Utilisateurs
                </span>
              </button>
            </li>
          )}

          <li className="mt-0.5 w-full">
            <button
              type="button"
              className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
            ${
              isActive('/mon-compte')
                ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                : 'text-black'
            }`}
              onClick={() => navigate('/mon-compte')}
            >
              <div className="shadow-soft-2xl mr-2 flex h-10 w-10 items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center xl:p-2.5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 14v2a6 6 0 0 0-6 6H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm0-2c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm2.595 7.812a3.51 3.51 0 0 1 0-1.623l-.992-.573 1-1.732.992.573A3.496 3.496 0 0 1 17 14.645V13.5h2v1.145c.532.158 1.012.44 1.405.812l.992-.573 1 1.732-.992.573a3.51 3.51 0 0 1 0 1.622l.992.573-1 1.732-.992-.573a3.496 3.496 0 0 1-1.405.812V22.5h-2v-1.145a3.496 3.496 0 0 1-1.405-.812l-.992.573-1-1.732.992-.572zM18 19.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                </svg>
              </div>
              <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                Mon compte
              </span>
            </button>
          </li>

          {/* Activity */}
          {/* <li className="mb-5">
            <a className="flex items-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.74982 12.7756C2.74982 5.8196 5.06882 3.5016 12.0238 3.5016C18.9798 3.5016 21.2988 5.8196 21.2988 12.7756C21.2988 19.7316 18.9798 22.0496 12.0238 22.0496C5.06882 22.0496 2.74982 19.7316 2.74982 12.7756Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.02521 9.32399H21.0332"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.4285 13.261H16.4375"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.0291 13.261H12.0381"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.62142 13.261H7.63042"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.4285 17.113H16.4375"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.0291 17.113H12.0381"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.62142 17.113H7.63042"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.033 2.05009V5.31209"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.02472 2.05009V5.31209"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
              <span className="ml-3 text-xs font-semibold lg:text-base text-zinc-500">
                Activity
              </span>
            </a>
          </li>
          <li className="mb-16">
            <a className="flex items-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.4">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 9.5C13.3809 9.5 14.5 10.6191 14.5 12C14.5 13.3809 13.3809 14.5 12 14.5C10.6191 14.5 9.5 13.3809 9.5 12C9.5 10.6191 10.6191 9.5 12 9.5Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M20.168 7.25025V7.25025C19.4845 6.05799 17.9712 5.65004 16.7885 6.33852C15.7598 6.93613 14.4741 6.18838 14.4741 4.99218C14.4741 3.61619 13.3659 2.5 11.9998 2.5V2.5C10.6337 2.5 9.52546 3.61619 9.52546 4.99218C9.52546 6.18838 8.23977 6.93613 7.21199 6.33852C6.02829 5.65004 4.51507 6.05799 3.83153 7.25025C3.14896 8.4425 3.55399 9.96665 4.73769 10.6541C5.76546 11.2527 5.76546 12.7473 4.73769 13.3459C3.55399 14.0343 3.14896 15.5585 3.83153 16.7498C4.51507 17.942 6.02829 18.35 7.21101 17.6625H7.21199C8.23977 17.0639 9.52546 17.8116 9.52546 19.0078V19.0078C9.52546 20.3838 10.6337 21.5 11.9998 21.5V21.5C13.3659 21.5 14.4741 20.3838 14.4741 19.0078V19.0078C14.4741 17.8116 15.7598 17.0639 16.7885 17.6625C17.9712 18.35 19.4845 17.942 20.168 16.7498C20.8515 15.5585 20.4455 14.0343 19.2628 13.3459H19.2618C18.2341 12.7473 18.2341 11.2527 19.2628 10.6541C20.4455 9.96665 20.8515 8.4425 20.168 7.25025Z"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </svg>
              <span className="ml-3 text-xs font-semibold lg:text-base text-zinc-500">
                Settings
              </span>
            </a>
          </li> */}

          <hr className="mb-1" />
          {localStorage?.getItem('xrole') === 'player' &&
            localStorage?.getItem('xrole') !== 'other' && (
              <ul className="mb-0 flex flex-col pl-0">
                <li className="mt-0.5 flex w-full items-center justify-between">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
                    ${
                      isActive('/faire-un-don')
                        ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                        : 'text-black'
                    }
                    `}
                    onClick={() => navigate('/faire-un-don')}
                  >
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      Faire un don
                    </span>
                  </button>
                </li>
                <li className="mt-0.5 flex w-full items-center justify-between">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
                    ${
                      isActive('/demander-une-reclamation')
                        ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                        : 'text-black'
                    }
                    `}
                    onClick={() => navigate('/demander-une-reclamation')}
                  >
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      Faire une réclamation
                    </span>
                  </button>
                </li>
                {/* {localStorage?.getItem('xprincipal') === 'false' && (
                    <li className="mt-0.5 flex w-full items-center justify-between">
                      <button
                        type="button"
                        className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
                    ${
                      isActive('/notfound')
                        ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-6 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                        : 'text-black'
                    }
                    `}
                        onClick={() => navigate('notfound')}
                      >
                        <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                          Demander une licence
                        </span>
                      </button>
                    </li>
                  )} */}
                <li className="mt-0.5 flex w-full items-center justify-between">
                  <a
                    className="py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm text-black transition-colors"
                    href="https://liguealpetanque.com/contacts/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      Contacter la ligue
                    </span>
                  </a>
                </li>
              </ul>
            )}

          {localStorage?.getItem('xrole') !== 'admin' &&
            localStorage?.getItem('xrole') !== 'player' &&
            localStorage?.getItem('xrole') !== 'other' && (
              <ul className="mb-0 flex flex-col pl-0">
                <li className="mt-0.5 flex w-full items-center justify-between">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
              ${
                isActive('/documents')
                  ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-2 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                  : 'text-black'
              }`}
                    onClick={() => navigate('/documents')}
                  >
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      Documents
                    </span>
                  </button>
                </li>
                <li className="mt-0.5 flex w-full items-center justify-between">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
              ${
                isActive('/verifier-licence')
                  ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-2 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                  : 'text-black'
              }`}
                    onClick={() => navigate('/verifier-licence')}
                  >
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      {localStorage?.getItem('xrole') === 'player'
                        ? 'Vérifier ma licence'
                        : 'Vérifier une licence'}
                    </span>
                  </button>
                </li>
              </ul>
            )}

          {localStorage?.getItem('xrole') !== 'player' &&
            localStorage?.getItem('xrole') !== 'other' && (
              <li className="mt-0.5 flex w-full items-center justify-between">
                <button
                  type="button"
                  className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
              ${
                isActive('/projets')
                  ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-2 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                  : 'text-black'
              }`}
                  onClick={() => navigate('/projets')}
                >
                  <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                    Projets
                  </span>
                </button>
              </li>
            )}

          {localStorage?.getItem('xrole') !== 'player' &&
            localStorage?.getItem('xrole') !== 'other' && (
              <ul className="mb-0 flex flex-col pl-0">
                <li className="mt-0.5 flex w-full items-center justify-between">
                  <button
                    type="button"
                    className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
              ${
                isActive('/licences')
                  ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-2 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                  : 'text-black'
              }`}
                    onClick={() => navigate('/licences')}
                  >
                    <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                      {localStorage?.getItem('xprincipal') === 'true'
                        ? 'Gérer les licences'
                        : 'Licences'}
                    </span>
                  </button>
                </li>
                {/* <hr className="" /> */}
                {/* <li className="mt-0.5 flex w-full items-center justify-between">
                <button
                  type="button"
                  className={`py-2.7 ease-nav-brand my-0 mx-4 flex items-center whitespace-nowrap px-4 text-sm transition-colors
              ${
                isActive('/invitations')
                  ? 'shadow-soft-md bg-150 bg-x-25 leading-pro hover:shadow-soft-2xl hover:scale-102 my-4 inline-block w-full select-none rounded-l-lg border-0 bg-gradient-to-tl from-secondary to-secondary/70 px-2 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all ease-in'
                  : 'text-black'
              }`}
                  onClick={() => navigate('/invitations')}
                >
                  <span className="ease-soft pointer-events-none ml-1 opacity-100 duration-300">
                    Invitations
                  </span>
                </button>
              </li> */}
              </ul>
            )}
        </ul>
      </div>
    </aside>
  );
};

export default Aside;
