import { userRequest } from '.';

export const getAllStatistics = async (offset) => {
  return userRequest.get(`statistics?offset=${offset}`);
};

export const getStatisticsByContest = async (id, offset) => {
  return userRequest.get(`statistics/contest/${id}?offset=${offset}`);
};

export const postStatistics = async (stats) => {
  return userRequest.post(`statistics`, stats);
};

export const updateStatistic = async (id, stats) => {
  return userRequest.put(`statistics/${id}`, stats);
};
