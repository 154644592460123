/* eslint-disable react/no-unknown-property */
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as Yup from 'yup';

import { getLigues, postLigue, putLigue, searchLigue } from './api/ligue';
import ButtonLoading from './components/ButtonLoading';

const LeaguesSchema = Yup.object().shape({
  name: Yup.string().required('Entrer le nom de la ligue'),
});
const formikField = (field) => {
  if (field.type === 'select') {
    if (field.hasValidation) {
      return (
        <Field
          as="select"
          name={field.name}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
          validate={field.validate}
        >
          {field.listOptions.map((option) => {
            return <option value={option.value}>{option.name}</option>;
          })}
        </Field>
      );
    }
    return (
      <Field
        as="select"
        name={field.name}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
      >
        {field.listOptions.map((option) => {
          return <option value={option.value}>{option.name}</option>;
        })}
      </Field>
    );
  }
  if (field.hasValidation) {
    if (field.type === 'textarea') {
      return (
        <Field
          name={field.name}
          as="textarea"
          placeholder={field.placeholder}
          className="w-full rounded border border-gray-300 bg-white p-3.5"
          validate={field.validate}
        />
      );
    }
    return (
      <Field
        name={field.name}
        type={field.type}
        placeholder={field.placeholder}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
        validate={field.validate}
      />
    );
  }
  if (field.type === 'textarea') {
    return (
      <Field
        as="textarea"
        name={field.name}
        placeholder={field.placeholder}
        className="w-full rounded border border-gray-300 bg-white p-3.5"
      />
    );
  }
  return (
    <Field
      name={field.name}
      type={field.type}
      placeholder={field.placeholder}
      className="w-full rounded border border-gray-300 bg-white p-3.5"
    />
  );
};

const Leagues = () => {
  const [showPopUp, setShowPopUp] = useState(false);
  const [step, setStep] = useState('create');
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState({});
  const [leagueToUpdate, setLeagueToUpdate] = useState({});
  const [league, setLeague] = useState({
    name: '',
    visible: true,
  });
  const [visibility, setVisibility] = useState(true);
  const [currentLimit, setNumberPlayer] = useState(0);
  const [ligues, setLigues] = useState([]);
  const leagueRef = useRef(null);
  const [search, setSearch] = useState('');
  const [searchMode, setSearchMode] = useState(false);

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const updateLigues = (res, limit) => {
    setLigues(
      res?.data?.map((item) => {
        return {
          id: item?.ligue?.id,
          data: item.ligue,
          clubCount: item?.clubCount,
        };
      })
    );
    setNumberPlayer(limit);
  };

  const listLigue = () => {
    setLoading(true);
    const limit = currentLimit + 20;
    getLigues(limit)
      .then((res) => {
        updateLigues(res, limit);
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setLoading(false);
      });
  };

  const createLeague = (newLeague) => {
    setMessage({});
    setLoading(true);

    postLigue(newLeague)
      .then((res) => {
        setLeague(res.data?.data);
        setMessage([
          true,
          'La ligue a été créée ! Définissez maintenant la visibilité',
        ]);
        setNumberPlayer(0);
        listLigue();
        setStep('visibility');
        setLoading(false);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        } else if (err.response?.status === 404) {
          setMessage([false, 'La ligue existe déjà']);
        } else {
          setMessage([false, 'Une erreur est survenue. Veuillez réessayer']);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    listLigue();
  }, []);

  const list = [
    {
      name: 'name',
      type: 'text',
      title: 'Nom de la ligue',
      placeholder: 'Les Tigres du Bénin',
      show: true,
      hasValidation: false,
      semiWidth: false,
    },
  ];

  const displayPopUp = (data) => {
    setShowPopUp(!showPopUp);
    setMessage([true, '']);
    if (!data?.id) {
      setStep('create');
      setLeagueToUpdate({});
    } else {
      setStep('update');
      setLeagueToUpdate({ ...data.data });
      setVisibility(data?.data?.visible);
    }
  };

  const updateVisibility = (e) => {
    setVisibility(e.target.checked);
  };

  const saveVisibility = () => {
    if (!loading) {
      setMessage({});

      setLoading(true);

      const data = {};
      data.name = league.name;
      data.visible = visibility;
      delete data?.id;
      delete data?.createdAt;
      delete data?.updatedAt;
      putLigue(league?.id, data)
        .then(() => {
          displayPopUp();
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          } else {
            setMessage([false, 'Une erreur est survenue. Veuillez réessayer']);
          }
          setLoading(false);
        });
    }
  };

  const update = (id, data) => {
    setLoading(true);

    putLigue(id, data)
      .then(() => {
        setNumberPlayer(0);
        listLigue();
        setLoading(false);
        setMessage([true, 'La ligue a été mise a jour !']);

        setTimeout(() => {
          displayPopUp();
        }, 1000);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        } else {
          setMessage([false, 'Une erreur est survenue. Veuillez réessayer']);
        }
        setLoading(false);
      });
  };

  const stepPopupCreate = () => {
    const actionButton = () => (step === 'create' ? 'Créer' : 'Modifier');
    if (step === 'visibility') {
      return (
        <div className="py-7 text-base sm:py-9">
          <div
            className={`mb-6 text-center text-xl ${
              message[0] ? 'text-primary' : 'text-red-500'
            }`}
          >
            {message[1]}
          </div>
          <div className="flex justify-between bg-gray-300 p-7">
            <p className="text-2xl">Visibilité de la ligue : {league.name}</p>
          </div>
          <div className="p-7 text-base sm:p-9">
            <p>
              En rendant la ligue visible, vous permettez à n&#39;importe quel
              utilisateur de rechercher et la visualiser.
            </p>
            <div className="w-full text-center">
              <label
                htmlFor="visibility"
                className="inline-flex cursor-pointer items-baseline"
              >
                Visible:{' '}
                <strong className="ml-1">{visibility ? 'Oui' : 'Non'}</strong>
                <input
                  checked={visibility}
                  name="visibility"
                  onChange={updateVisibility}
                  type="checkbox"
                  aria-label="changer la visibilité"
                  className="checked:bg-primary-500 xt-check xt-switch ml-6 rounded-full border border-transparent bg-gray-200 transition-all"
                />
              </label>
            </div>
            <button
              onClick={saveVisibility}
              type="button"
              className="close-button relative float-right mb-8 mr-8 inline-flex rounded-xl bg-primary px-6 py-2 text-white"
            >
              Confirmer
              {loading && <ButtonLoading />}
            </button>
          </div>
        </div>
      );
    }
    return (
      <div className="w-full">
        <div className="xt-h4 text-center">
          {step === 'create'
            ? 'Créer une ligue'
            : `Modifier la ligue "${leagueToUpdate.name}"`}
        </div>
        <Formik
          initialValues={leagueToUpdate}
          validationSchema={LeaguesSchema}
          onSubmit={(values) => {
            if (!loading) {
              if (step === 'create') {
                createLeague(values);
              } else {
                const data = {
                  name: values.name,
                  visible: visibility,
                };
                update(leagueToUpdate.id, data);
              }
            }
          }}
        >
          {({ errors, touched }) => (
            <Form className="flex flex-col gap-4 pt-8">
              <div className="flex flex-wrap justify-between gap-y-4">
                {list.map((field) => {
                  return (
                    field.show && (
                      <div
                        key={field.name}
                        className={`flex w-full flex-col md:${
                          field.semiWidth && 'w-1/2'
                        }`}
                        onChange={() => {
                          setMessage({});
                        }}
                      >
                        <div className="flex flex-col items-start justify-start space-y-1">
                          <h3 className="text-xl leading-normal text-gray-900">
                            {[field.title]}
                          </h3>
                        </div>
                        <div className="">
                          {formikField(field)}
                          {errors[field.name] && touched[field.name] ? (
                            <div className="text-red-400">
                              {errors[field.name]}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )
                  );
                })}
              </div>
              {step === 'update' && (
                <div className="w-full">
                  <div className="flex flex-col items-start justify-start space-y-1">
                    <h3 className="text-xl leading-normal text-gray-900">
                      Visible : <strong>Oui</strong>
                    </h3>
                  </div>
                  <input
                    checked={visibility}
                    name="visible"
                    onChange={updateVisibility}
                    type="checkbox"
                    className="checked:bg-primary-500 xt-check xt-switch ml-6 rounded-full border border-transparent bg-gray-200 transition-all"
                  />
                  <div
                    className={`mb-6 text-center text-xl ${
                      message[0] ? 'text-primary' : 'text-red-500'
                    }`}
                  >
                    {message[1]}
                  </div>
                </div>
              )}
              <div
                className={`text-center text-sm ${
                  message[0] ? 'text-transparent' : 'text-red-500'
                }`}
              >
                {message[1]}
              </div>
              <div className="flex w-full justify-between">
                <button
                  onClick={() => displayPopUp()}
                  type="button"
                  className={`xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-2 ml-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-secondary bg-transparent bg-none px-4 py-3 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:border-secondary
                                  ${
                                    !loading && 'duration-300 hover:scale-105'
                                  }`}
                  data-class="bg-white"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-transparent bg-primary bg-gradient-to-tl from-primary to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase text-white transition-all hover:border-primary"
                  data-class="bg-transparent"
                >
                  {loading ? 'En cours' : actionButton()}
                  {loading && <ButtonLoading />}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const onSearchLigue = (e, size) => {
    e.preventDefault();
    if (search.length > 2) {
      setSearchMode(true);
      setLoading(true);
      setLigues([]);

      let limit = currentLimit + 20;

      if (size) limit = size;
      searchLigue(search, limit)
        .then((res) => {
          updateLigues(res, limit);
          setLoading(false);
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            logOut();
          }
        });
    }
    if (search.length === 0) {
      setSearchMode(false);
    }
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <section
              ref={leagueRef}
              className="infinitescroll-container relative w-full md:min-h-screen"
            >
              <header className="mb-6 w-full px-10 py-10">
                <h1 className="text-4xl font-extrabold text-secondary">
                  Ligues
                </h1>
                <div className="xt-row mt-6 flex items-center justify-between">
                  <div className="max-w-xs">
                    <form
                      className="relative z-0 flex w-full -space-x-px"
                      onSubmit={(e) => {
                        setNumberPlayer(20);
                        setSearchMode(true);
                        onSearchLigue(e, 20);
                      }}
                    >
                      <div className="relative w-full">
                        <svg
                          className="pointer-events-none absolute inset-y-0 left-0 h-full w-8 stroke-gray-400 pl-2.5"
                          viewBox="0 0 256 256"
                          aria-hidden="true"
                        >
                          <circle
                            cx="116"
                            cy="116"
                            r="84"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          />
                          <line
                            x1="175.4"
                            y1="175.4"
                            x2="224"
                            y2="224"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="16"
                          />
                        </svg>
                        <span htmlFor="search-button" className="sr-only">
                          Rechercher une ligue
                        </span>
                        <input
                          id="search-button"
                          type="search"
                          placeholder="Rechercher une ligue..."
                          className="block w-full rounded-l-md border-gray-200 pl-10 text-sm transition focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:opacity-75"
                          onChange={(e) => {
                            setSearch(e.target.value);
                            if (e.target.value.length === 0) {
                              setTimeout(() => {
                                listLigue();
                              }, 500);
                            }
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded-r border border-gray-200 bg-white px-3 py-2 text-sm font-medium text-gray-800 transition focus:z-10 focus:border-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 hover:border-gray-300 hover:bg-gray-100"
                      >
                        Rechercher
                      </button>
                    </form>
                  </div>
                  <button
                    type="button"
                    className="mt-6 flex rounded-full bg-primary py-4 px-7 font-semibold text-white md:mt-0"
                    onClick={() => displayPopUp()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                        fill="currentColor"
                      />
                    </svg>{' '}
                    Ajouter une ligue
                  </button>
                </div>
              </header>
              {typeof ligues !== 'undefined' &&
                ligues.length === 0 &&
                !loading &&
                !searchMode && (
                  <p className="px-10">
                    Il n&apos;y a pas encore de ligue pour le moment.
                  </p>
                )}
              {searchMode && loading && (
                <p className="px-10 text-center">Chargement...</p>
              )}
              {searchMode && !loading && ligues.length === 0 && (
                <p className="px-10 text-center">
                  Aucune ligue ne correspond à votre recherche
                </p>
              )}
              <InfiniteScroll
                dataLength={20}
                next={!searchMode ? listLigue : onSearchLigue}
                hasMore={ligues.length === currentLimit}
                loader={
                  loading && <h4 className="text-center">Chargement...</h4>
                }
                endMessage={
                  currentLimit % 50 > 20 && (
                    <p style={{ textAlign: 'center', marginBottom: '0.5rem' }}>
                      <b>Vous avez atteint la fin !</b>
                    </p>
                  )
                }
                refreshFunction={!searchMode ? listLigue : onSearchLigue}
              >
                <div className="flex flex-wrap justify-start gap-4 p-4">
                  {ligues.map((ligue) => {
                    return (
                      <div
                        key={ligue.id}
                        className="break-inside relative mb-4 flex w-full max-w-[15rem] flex-col justify-between space-y-2 overflow-hidden rounded-xl bg-default p-4 text-sm text-white"
                      >
                        <span className="text-xs uppercase text-[#D2BDFF]">
                          {ligue.data.name}
                        </span>
                        <div className="flex flex-row items-center space-x-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            fill="currentColor"
                          >
                            <path fill="none" d="M0 0h24v24H0z" />
                            <path d="M12 17c3.662 0 6.865 1.575 8.607 3.925l-1.842.871C17.347 20.116 14.847 19 12 19c-2.847 0-5.347 1.116-6.765 2.796l-1.841-.872C5.136 18.574 8.338 17 12 17zm0-15a5 5 0 0 1 5 5v3a5 5 0 0 1-4.783 4.995L12 15a5 5 0 0 1-5-5V7a5 5 0 0 1 4.783-4.995L12 2zm0 2a3 3 0 0 0-2.995 2.824L9 7v3a3 3 0 0 0 5.995.176L15 10V7a3 3 0 0 0-3-3z" />
                          </svg>
                          <span>Clubs: </span>
                          <span className="ml-1 text-base font-medium">
                            {ligue.clubCount}
                          </span>
                        </div>
                        <div className="flex items-center justify-between">
                          <button
                            type="button"
                            className="transtion-all text-opacity-90 duration-75 hover:font-bold hover:text-opacity-100"
                            onClick={() => displayPopUp(ligue)}
                          >
                            Modifier
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
              <div className="flex items-center justify-center">
                {showPopUp && (
                  <div className="sticky-0 fixed inset-0 z-[999] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-80 px-3">
                    <div className="relative w-full max-w-md rounded-xl bg-white py-16 px-4 xl:py-24">
                      {stepPopupCreate()}
                      <button
                        type="button"
                        className="absolute top-8 right-8 text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2"
                        onClick={() => displayPopUp(-1)}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 6L6 18"
                            stroke="currentColor"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M6 6L18 18"
                            stroke="currentColor"
                            strokeWidth="1.66667"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leagues;
