import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getUser } from './api/user';

const User = () => {
  const { id } = useParams();
  const [user, setUser] = useState({});

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const getProfile = () => {
    getUser(id)
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-y-4 bg-gray-50 transition-all duration-200">
      <div className="mx-auto w-full px-6">
        <div
          className="min-h-75 relative mt-6 flex items-center overflow-hidden rounded-2xl bg-cover bg-center p-0"
          style={{
            backgroundImage: 'url(/bg-clubs-associations.jpg)',
            backgroundPositionY: '50%',
          }}
        >
          <span className="absolute inset-y-0 h-full w-full bg-gradient-to-b from-purple-700 to-blue-500 bg-cover bg-center opacity-60" />
        </div>
        <div className="shadow-blur relative mx-6 -mt-16 flex min-w-0 flex-auto flex-col overflow-hidden break-words rounded-2xl border-0 bg-white/80 bg-clip-border p-4 backdrop-blur-2xl backdrop-saturate-200">
          <div className="-mx-3 flex flex-wrap">
            <div className="w-auto max-w-full flex-none px-3">
              <div className="ease-soft-in-out h-18.5 w-18.5 relative inline-flex items-center justify-center rounded-xl text-base text-white transition-all duration-200">
                {user.image_url && (
                  <img
                    src={user.image_url}
                    alt={user.name}
                    className="shadow-soft-sm h-full w-full rounded-full object-cover"
                  />
                )}
                {!user.image_url && (
                  <span className="shadow-soft-sm inline-block h-full w-full overflow-hidden rounded-full bg-gray-100">
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                )}
              </div>
            </div>
            <div className="my-auto w-auto max-w-full flex-none px-3">
              <div className="h-full">
                <h5 className="mb-1">{`${user?.lastName || ''} ${
                  user?.firstName || ''
                }`}</h5>
                <p className="mb-0 text-sm font-semibold leading-normal">
                  {user?.occupation}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto w-full p-6">
        <div className="-mx-3 flex flex-wrap">
          <div className="lg-max:mt-6 w-full max-w-full px-3">
            <div className="shadow-soft-xl relative flex h-full min-w-0 flex-col break-words rounded-2xl border-0 bg-white bg-clip-border">
              <div className="mb-0 rounded-t-2xl border-b-0 bg-white p-4 pb-0">
                <div className="-mx-3 flex flex-wrap">
                  <div className="flex w-full max-w-full shrink-0 items-center px-3 md:w-8/12 md:flex-none">
                    <h6 className="mb-0">A propos</h6>
                  </div>
                </div>
              </div>
              <div className="flex-auto p-4">
                <p className="text-sm leading-normal">
                  {user?.bio || 'Pas de description'}
                </p>
                <hr className="my-6 h-px bg-transparent bg-gradient-to-r from-transparent via-white to-transparent" />
                <ul className="mb-0 flex flex-col rounded-lg pl-0">
                  <li className="relative block rounded-t-lg border-0 bg-white px-4 py-2 pt-0 pl-0 text-sm leading-normal text-inherit">
                    <strong className="text-slate-700">Email:</strong> &nbsp;{' '}
                    {user?.email}
                  </li>
                  {user?.country && (
                    <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                      <strong className="text-slate-700">Pays:</strong> &nbsp;
                      {user?.country}
                    </li>
                  )}
                  {user?.phone && (
                    <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                      <strong className="text-slate-700">Téléphone:</strong>{' '}
                      &nbsp;
                      {user?.phone}
                    </li>
                  )}
                  {user?.club?.name && (
                    <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                      <strong className="text-slate-700">Club:</strong> &nbsp;
                      {user?.club?.name}
                    </li>
                  )}
                  <li className="relative block border-0 border-t-0 bg-white px-4 py-2 pl-0 text-sm leading-normal text-inherit">
                    <strong className="text-slate-700">Licence:</strong> &nbsp;{' '}
                    {user?.license || 'Pas de licence'}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
