/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { TOKEN } from './api';
import { confirmAccount } from './api/user';

const Confirm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (location.pathname.split('/')[2]) {
      setToken(location.pathname.split('/')[2]);
    }
  }, []);

  useEffect(() => {
    if (token) {
      confirmAccount({
        token,
      })
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setError(true);
        });
    }
  }, [token]);

  const login = (e) => {
    e.preventDefault();

    if (!loading && TOKEN) {
      navigate('/');
    } else {
      navigate('/signin');
    }
  };

  return (
    <div className="h-screen w-full">
      <div className="m-auto h-full min-h-full w-full max-w-lg items-center justify-center">
        <div className="m-auto flex h-full w-full max-w-xl flex-col items-center justify-center space-y-10 rounded px-4 py-8">
          <img src="/logo-2.png" alt="logo" className="mx-auto block w-24" />
          <div className="w-full space-y-6">
            <div className="relative -top-44 text-center">
              <div className="xt-loader inset-0 z-content overflow-hidden rounded-inherit">
                <div
                  className={`xt-spinner absolute inset-0 mx-auto h-44 w-44 transition-colors
                ${loading ? 'text-primary' : 'text-transparent'}`}
                >
                  <svg
                    viewBox="0 0 240 240"
                    className="absolute"
                    preserveAspectRatio="xMinYMin meet"
                  >
                    <circle
                      className="origin-center stroke-current opacity-25"
                      style={{
                        fill: 'none',
                      }}
                      strokeWidth="10"
                      cx="120"
                      cy="120"
                      r="100"
                      strokeDasharray="628"
                      strokeDashoffset="0"
                      pathLength="628"
                    />
                  </svg>
                  <svg viewBox="0 0 240 240">
                    <circle
                      className="xt-icon relative origin-center animate-xt-spinner stroke-current"
                      style={{
                        fill: 'none',
                      }}
                      strokeWidth="10"
                      cx="120"
                      cy="120"
                      r="100"
                      strokeDasharray="628"
                      strokeDashoffset="628"
                      pathLength="628"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <h1 className="text-center text-xl font-bold leading-10 text-gray-900 md:text-4xl">
              {loading && 'Vérification en cours...'}
              {!loading && !error && 'Votre compte a été confirmé !'}
              {!loading &&
                error &&
                "Votre compte n'a pas pu être confirmé en raison de lien expiré ou invalide ! Veuillez réessayer."}
            </h1>

            <div
              className={`flex flex-col items-center justify-center space-y-5
          ${loading ? 'invisible' : 'visible'}`}
            >
              <button
                type="button"
                className="relative rounded-xl bg-secondary py-2 px-3 text-white"
                onClick={login}
              >
                Se connecter
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirm;
