/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { TOKEN } from './api';
import { getMyProfile } from './api/user';
import Aside from './components/Aside';
import Navbar from './components/Navbar';
// import MobileAside from './components/MobileAside';
// import Navbar from './components/Navbar';
import ScreenLoading from './components/ScreenLoading';

const Layout = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [loading, setLoading] = useState(true);

  const isAuth = () => {
    return (
      (path === '/register' ||
        path === '/signin' ||
        path === 'confirm' ||
        path === '/confirmed') &&
      !TOKEN
    );
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem('xprincipal');
    window.location.href = '/signin';
  };

  const login = () => {
    if (!TOKEN) {
      setTimeout(() => {
        localStorage?.setItem('xstate', false);
        window.location.href = '/signin';
      }, 50);
    }
  };

  useEffect(() => {
    setLoading(
      typeof window !== 'undefined' &&
        localStorage?.getItem('xstate') &&
        JSON.parse(localStorage?.getItem('xstate')) === true
    );
    if (!localStorage?.getItem('xprincipal')) {
      setLoading(true);
      getMyProfile().then(
        (resp) => {
          localStorage?.setItem('xprincipal', resp?.data?.principal);
          setLoading(false);
        },
        (err) => {
          if (err?.response?.status === 401) {
            setLoading(false);
            logOut();
          }
        }
      );
    }
    localStorage?.setItem('xstate', !isAuth() && !TOKEN);
    login();
  }, []);

  return (
    <main className="leading-default relative m-0 bg-gray-50 font-sans text-base font-normal text-slate-500 antialiased">
      {!isAuth() && TOKEN && !loading && (
        <div className="flex w-full">
          <Aside />
          <div className="ease-soft-in-out xl:w-content xl:ml-68.5 relative h-full max-h-screen min-h-screen w-full bg-gray-50 transition-all duration-200">
            <Navbar />
            <Outlet />
          </div>
        </div>
      )}
      {loading && <ScreenLoading />}
    </main>
  );
};

export default Layout;
