/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/aria-role */
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { postContest, putContest } from '../api/contest';
import ButtonLoading from './ButtonLoading';

const DecisionTreeModal = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [data, setData] = useState({
    type: '',
    date: '',
    time: '',
    notify: false,
    public: false,
  });

  const handleClose = () => {
    if (show) {
      setShow(false);

      setTimeout(() => {
        if (props.onOpen) {
          props.onClose();
        }
      }, 50);
    }
  };

  const formRef = useRef();
  const modalRef = useDetectClickOutside({ onTriggered: handleClose });

  useEffect(() => {
    setShow(true);
  }, []);

  const openTab = (evt, type) => {
    setData({ ...data, type });
    const tab = evt.currentTarget;
    const tabActive = tab.getAttribute('aria-selected');

    // Check tab not already active
    if (tabActive === 'false') {
      // Close previous sub-tabs
      const parentTabPanel =
        tab.closest('.decision-tree__panel') || tab.closest('.decision-tree');
      const activeTabs = parentTabPanel.querySelectorAll(
        "[aria-selected='true']"
      );

      activeTabs.forEach((activeTab) => {
        const prevTabPanelId = activeTab.getAttribute('aria-controls');
        const prevTabPanel = document.querySelector(`#${prevTabPanelId}`);

        activeTab.setAttribute('aria-selected', 'false');

        prevTabPanel.classList.add('hidden');
      });

      // Activate new tab
      const newTabPanelId = tab.getAttribute('aria-controls');
      const newTabPanel = document.querySelector(`#${newTabPanelId}`);

      tab.setAttribute('aria-selected', 'true');

      newTabPanel.classList.remove('hidden');
      newTabPanel.focus();
    }
  };

  const renderType = () => {
    switch (data.type) {
      case 'one':
        return 'Tête à tête';
      case 'doubles':
        return 'Doublette';
      case 'triples':
        return 'Triplette';
      case 'shot':
        return 'Tir de précision';

      default:
        return '';
    }
  };

  useEffect(() => {
    if (props.contest) {
      setData({
        ...data,
        type: props.contest.type,
        date: props.contest.start_date.split('T')[0],
        time: props.contest.start_date.split('T')[1].split('.')[0],
        notify: props.contest.notify,
        public: props.contest.public,
      });

      // Activate new tab
      const newTabPanel = document.querySelector(`#option-1-panel`);

      newTabPanel.classList.remove('hidden');
      newTabPanel.focus();
    }
  }, [props.contest]);

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  return (
    <div
      className={`decision-tree sticky-0 fixed inset-0 z-[999] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-80 px-3 transition-all
      ${show ? 'bg-gray-900' : 'bg-transparent'}`}
    >
      <div
        ref={modalRef}
        className="relative mx-auto max-w-2xl overflow-hidden rounded-3xl bg-white"
      >
        <div className="max-h-[80vh] overflow-y-scroll p-10 px-4 pt-4 pb-16 shadow transition-all scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200 xl:pb-24">
          <header id="header-3a" className="gap- flex items-center">
            <button
              type="button"
              className="ml-auto inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-5 text-sm font-medium tracking-wide text-gray-500 transition duration-300 focus:text-primary focus-visible:outline-none disabled:cursor-not-allowed"
              aria-label="close dialog"
              onClick={handleClose}
            >
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  role="graphics-symbol"
                  aria-labelledby="title-79 desc-79"
                >
                  <title id="title-79">Close</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </header>
          <div className="">
            <Formik
              ref={formRef}
              initialValues={
                props?.contest?.type
                  ? {
                      ...data,
                      type: props.contest.type,
                      date: props.contest.start_date.split('T')[0],
                      time: props.contest.start_date
                        .split('T')[1]
                        .split('.')[0],
                      price: props.contest.price,
                      notify: props.contest.notify,
                      public: props.contest.public,
                    }
                  : data
              }
              onSubmit={(values, { setErrors, setValues }) => {
                let errors = {};
                if (!values.type) {
                  setValues({ ...values, type: data.type });
                  errors = {
                    ...errors,
                    type: 'Veuillez choisir le type de formation',
                  };
                }
                if (!values.date) {
                  errors = {
                    ...errors,
                    date: 'Veuillez saisir une date valide',
                  };
                }
                if (!values.price) {
                  errors = {
                    ...errors,
                    price: 'Veuillez saisir un montant',
                  };
                }
                if (!values.time) {
                  errors = {
                    ...errors,
                    time: 'Veuillez choisir une heure valide',
                  };
                }

                setErrors(errors);

                if (values.type && values.date && values.time) {
                  setLoading(true);
                  // new date and add time
                  const date = new Date(values.date);
                  const time = values.time.split(':');
                  date.setHours(time[0]);
                  date.setMinutes(time[1]);

                  if (props.contest?.type) {
                    const { id } = props.contest;
                    putContest(id, {
                      // name: AAAA-MM-JJ HH:MM
                      name: `${date.getFullYear()}-${
                        date.getMonth() + 1
                      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`,
                      type: props.type,
                      start_date: date,
                      public: values.public,
                      notify: values.notify,
                      price: values.price.toString(),
                    })
                      .then(() => {
                        setLoading(false);
                        setMessage({
                          type: 'success',
                          text: `Le ${
                            props.type === 'tournament'
                              ? 'Championnat'
                              : 'Concours'
                          } a été modifié`,
                        });

                        setTimeout(() => {
                          props?.load();
                          handleClose();
                        }, 1000);
                      })
                      .catch((err) => {
                        setLoading(false);
                        if (
                          err.response?.status === 401 ||
                          err.response?.status === 403
                        ) {
                          logOut();
                        } else {
                          setMessage({
                            type: 'error',
                            text: 'Une erreur est survenue, veuillez réessayer',
                          });
                        }
                      });
                  } else {
                    postContest({
                      // name: AAAA-MM-JJ HH:MM
                      name: `${date.getFullYear()}-${
                        date.getMonth() + 1
                      }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`,
                      type: props.type,
                      composition: values.type,
                      start_date: date,
                      public: values.public,
                      notify: values.notify,
                      price: values.price.toString(),
                    })
                      .then(() => {
                        setLoading(false);
                        setMessage({
                          type: 'success',
                          text: `Le nouveau ${
                            props.type === 'tournament'
                              ? 'Championnat'
                              : 'Concours'
                          } a été créé`,
                        });

                        setTimeout(() => {
                          props?.load();
                          handleClose();
                        }, 1000);
                      })
                      .catch((err) => {
                        setLoading(false);
                        if (
                          err.response?.status === 401 ||
                          err.response?.status === 403
                        ) {
                          logOut();
                        } else {
                          setMessage({
                            type: 'error',
                            text: 'Une erreur est survenue, veuillez réessayer',
                          });
                        }
                      });
                  }
                }
              }}
            >
              {({ errors, touched }) => (
                <Form className="flex flex-col gap-4 pt-8">
                  <div className="pb-9 text-center">
                    <h1 className="mb-4 text-4xl font-bold">
                      Configurer votre{' '}
                      {props.type === 'tournament' ? 'championnat' : 'concours'}
                    </h1>
                    <p className="mb-4">
                      Pour démarrer un{' '}
                      {props.type === 'tournament' ? 'championnat' : 'concours'}
                      , vous devez définir la date et l&apos;heure de début et
                      choisir le type de formation.
                    </p>
                    <h2 className="mb-3 font-bold">
                      Sélectionner le type de formation:
                    </h2>

                    {/* Top-Level Tabs  */}
                    <div
                      role="tablist"
                      className="flex flex-wrap justify-center gap-4"
                    >
                      <button
                        role="tab"
                        id="option-1-tab"
                        data-type="one"
                        aria-selected="false"
                        aria-controls="option-1-panel"
                        className={`decision-tree__tab aria-selected:bg-cyan-900 rounded-full border border-solid border-cyan-700 bg-cyan-50 py-3 px-8 font-semibold text-cyan-900 shadow transition-colors focus:bg-cyan-800 focus:text-cyan-50 hover:bg-cyan-800 hover:text-cyan-50 [&[aria-selected='true']]:bg-cyan-900 [&[aria-selected='true']]:text-cyan-50
                        ${
                          props?.contest?.composition === 'one'
                            ? 'bg-cyan-900 text-cyan-50'
                            : ''
                        }
                        `}
                        disabled={props?.contest?.type}
                        onClick={(e) => openTab(e, 'one')}
                      >
                        Tête à tête
                      </button>
                      <button
                        role="tab"
                        id="option-1-tab"
                        data-type="doubles"
                        aria-selected="false"
                        aria-controls="option-1-panel"
                        className={`decision-tree__tab aria-selected:bg-cyan-900 rounded-full border border-solid border-cyan-700 bg-cyan-50 py-3 px-8 font-semibold text-cyan-900 shadow transition-colors focus:bg-cyan-800 focus:text-cyan-50 hover:bg-cyan-800 hover:text-cyan-50 [&[aria-selected='true']]:bg-cyan-900 [&[aria-selected='true']]:text-cyan-50
                        ${
                          props?.contest?.composition === 'doubles'
                            ? 'bg-cyan-900 text-cyan-50'
                            : ''
                        }
                        `}
                        disabled={props?.contest?.type}
                        onClick={(e) => openTab(e, 'doubles')}
                      >
                        Doublette
                      </button>
                      <button
                        role="tab"
                        id="option-1-tab"
                        data-type="triples"
                        aria-selected="false"
                        aria-controls="option-1-panel"
                        className={`decision-tree__tab aria-selected:bg-cyan-900 rounded-full border border-solid border-cyan-700 bg-cyan-50 py-3 px-8 font-semibold text-cyan-900 shadow transition-colors focus:bg-cyan-800 focus:text-cyan-50 hover:bg-cyan-800 hover:text-cyan-50 [&[aria-selected='true']]:bg-cyan-900 [&[aria-selected='true']]:text-cyan-50
                        ${
                          props?.contest?.composition === 'triples'
                            ? 'bg-cyan-900 text-cyan-50'
                            : ''
                        }
                        `}
                        disabled={props?.contest?.type}
                        onClick={(e) => openTab(e, 'triples')}
                      >
                        Triplette
                      </button>
                      {/* <button
                        role="tab"
                        id="option-1-tab"
                        data-type="shot"
                        aria-selected="false"
                        aria-controls="option-1-panel"
                        className={`decision-tree__tab aria-selected:bg-cyan-900 rounded-full border border-solid border-cyan-700 bg-cyan-50 py-3 px-8 font-semibold text-cyan-900 shadow transition-colors focus:bg-cyan-800 focus:text-cyan-50 hover:bg-cyan-800 hover:text-cyan-50 [&[aria-selected='true']]:bg-cyan-900 [&[aria-selected='true']]:text-cyan-50
                        ${
                          props?.contest?.composition === 'shot'
                            ? 'bg-cyan-900 text-cyan-50'
                            : ''
                        }
                        `}
                        disabled={props?.contest?.type}
                        onClick={(e) => openTab(e, 'shot')}
                      >
                        Tir de précision
                      </button> */}
                    </div>
                  </div>
                  {errors.type ? (
                    <div className="-mt-8 mb-8 text-center text-red-400">
                      {errors.type}
                    </div>
                  ) : null}

                  {/* Option 1 Panel  */}
                  <div
                    role="tabpanel"
                    id="option-1-panel"
                    aria-labelledby="option-1-tab option-1-tab-heading"
                    className="decision-tree__panel relative hidden border-t border-solid border-cyan-700 after:absolute after:-top-px after:left-1/2 after:h-7 after:w-7 after:-translate-x-2/4 after:-translate-y-2/4 after:-rotate-45 after:border after:border-l-0 after:border-b-0 after:border-solid after:border-cyan-700 after:bg-white"
                    tabIndex="0"
                  >
                    <div className="pt-6">
                      <h2
                        className="mb-3 text-2xl font-bold"
                        id="option-1-heading"
                      >
                        {renderType()}
                      </h2>
                      <p className="mb-4">
                        Ajuster les paramètres qui vous conviennent.
                      </p>
                      <div className="flex flex-wrap gap-4">
                        <div className="w-full md:w-1/2">
                          <label
                            htmlFor="option-1-date"
                            className="mb-2 block font-bold"
                          >
                            Date du{' '}
                            {props.type === 'tournament'
                              ? 'championnat'
                              : 'concours'}
                          </label>

                          <Field
                            name="date"
                            type="date"
                            min={new Date().toISOString().split('T')[0]}
                            id="option-1-date"
                            className="w-full rounded-md border border-solid border-cyan-700 py-3 px-4"
                          />
                          {errors.date && touched.date ? (
                            <div className="text-red-400">{errors.date}</div>
                          ) : null}
                        </div>

                        <div className="w-full md:w-1/2">
                          <label
                            htmlFor="option-1-time"
                            className="mb-2 block font-bold"
                          >
                            Heure du{' '}
                            {props.type === 'tournament'
                              ? 'championnat'
                              : 'concours'}
                          </label>

                          <Field
                            name="time"
                            type="time"
                            id="option-1-time"
                            className="w-full rounded-md border border-solid border-cyan-700 py-3 px-4"
                          />
                          {errors.time && touched.time ? (
                            <div className="text-red-400">{errors.time}</div>
                          ) : null}
                        </div>

                        <div className="w-full md:w-1/2">
                          <label
                            htmlFor="option-price"
                            className="mb-2 block font-bold"
                          >
                            Frais d&apos;inscription
                          </label>

                          <div className="relative">
                            <Field
                              name="price"
                              type="number"
                              id="option-price"
                              className="w-full rounded-md border border-solid border-cyan-700 py-3 px-4 !pr-16"
                            />
                            <span className="absolute right-4 top-1/2 -translate-y-1/2 transform">
                              FCFA
                            </span>
                          </div>
                          {errors.price && touched.price ? (
                            <div className="text-red-400">{errors.price}</div>
                          ) : null}
                        </div>
                        <div className="flex w-full">
                          <label
                            htmlFor="option-1-players"
                            className="mr-2 block font-bold"
                          >
                            Avertir vos membres du tournoi par mail :
                          </label>
                          <Field
                            name="notify"
                            type="checkbox"
                            className="checked:bg-primary-500 xt-check xt-switch ml-auto rounded-full border border-transparent bg-gray-200 transition-all"
                          />
                        </div>
                        <div className="flex w-full">
                          <label
                            htmlFor="option-1-players"
                            className="mr-2 block font-bold"
                          >
                            Rendre public la compétition* :{' '}
                          </label>
                          <Field
                            name="public"
                            type="checkbox"
                            className="checked:bg-primary-500 xt-check xt-switch ml-auto rounded-full border border-transparent bg-gray-200 transition-all"
                          />
                        </div>

                        <p>
                          * tout le monde peut voir la compétition et y accéder
                        </p>

                        {errors.notify && touched.notify ? (
                          <div className="text-red-400">{errors.notify}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  {message?.type && (
                    <div
                      className={`text-center text-sm ${
                        message.type === 'success'
                          ? 'text-green-500'
                          : 'text-red-500'
                      }`}
                    >
                      {message.text}
                    </div>
                  )}

                  <div className="flex">
                    <button
                      onClick={handleClose}
                      type="button"
                      className={`close-button relative mr-8 w-1/2 rounded-xl bg-secondary py-2 text-white
                                          ${
                                            !loading &&
                                            'duration-300 hover:scale-105'
                                          }`}
                    >
                      Annuler
                    </button>
                    <button
                      type="submit"
                      className={`close-button relative w-1/2 rounded-xl bg-primary py-2 text-white
                                          ${
                                            !loading &&
                                            'duration-300 hover:scale-105'
                                          }`}
                    >
                      {loading && 'En cours'}
                      {!loading &&
                        props.type === 'tournament' &&
                        !props.contest &&
                        'Créer le championnat'}
                      {!loading &&
                        props.type === 'challenge' &&
                        !props.contest &&
                        'Créer le concours'}
                      {!loading &&
                        props.contest &&
                        'Appliquer les modifications'}

                      {loading && <ButtonLoading />}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {props.loading && (
            <div className="xt-loader absolute inset-0 z-content overflow-hidden rounded-inherit">
              <div className="xt-spinner text-primary-500 absolute inset-0 m-auto h-6 w-6">
                <svg
                  viewBox="0 0 240 240"
                  className="absolute scale-150"
                  preserveAspectRatio="xMinYMin meet"
                >
                  <circle
                    className="origin-center stroke-current opacity-25"
                    fill="none"
                    strokeWidth="30"
                    cx="120"
                    cy="120"
                    r="100"
                    strokeDasharray="628"
                    strokeDashoffset="0"
                    pathLength="628"
                  />
                </svg>
                <svg viewBox="0 0 240 240">
                  <circle
                    className="relativext-icon origin-center animate-xt-spinner stroke-current"
                    fill="none"
                    strokeWidth="30"
                    cx="120"
                    cy="120"
                    r="100"
                    strokeDasharray="628"
                    strokeDashoffset="628"
                    pathLength="628"
                  />
                </svg>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

DecisionTreeModal.propTypes = {
  type: PropTypes.string.isRequired,
  onOpen: PropTypes.bool,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  contest: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    number_of_team: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    notify: PropTypes.bool,
    public: PropTypes.bool,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    drawing_date: PropTypes.string,
    composition: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  load: PropTypes.func,
};

export default DecisionTreeModal;
