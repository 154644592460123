import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Championships from './championships';
import Claim from './claim';
import Club from './club';
import Clubs from './clubs';
import Competitions from './competitions';
import Confirm from './confirm';
import Contest from './contest';
import Documents from './documents';
import Donation from './donation';
import ForgotPassword from './forgotPassword';
import Home from './home';
import Invitations from './invitations';
import Layout from './layout';
import Leagues from './leagues';
import Licenses from './licenses';
import MutationRequest from './mutation';
import MyAccount from './myAccount';
import NoMatch from './noMatch';
import Players from './players';
import Projets from './projets';
import Register from './register';
import ResetPassword from './resetPassword';
import SignIn from './signIn';
import User from './User';
import VerifyLicense from './verifyLicense';

const App = () => {
  return (
    <BrowserRouter>
      <Routes onUpdate={() => window.scrollTo(0, 0)}>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset/:token" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/confirm/:token" element={<Confirm />} />

        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/mon-compte" element={<MyAccount />} />
          <Route path="/championships" element={<Championships />} />
          <Route path="/competitions" element={<Competitions />} />
          <Route path="/invitations" element={<Invitations />} />
          <Route path="/clubs" element={<Clubs />} />
          <Route path="/leagues" element={<Leagues />} />
          <Route path="/players" element={<Players />} />
          <Route path="/users" element={<Players />} />
          <Route path="/club" element={<Club />} />
          <Route path="/club/:id" element={<Club />} />
          <Route path="/user/:id" element={<User />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/projets" element={<Projets />} />
          <Route path="/verifier-licence" element={<VerifyLicense />} />
          <Route path="/contest/:id" element={<Contest />} />
          <Route path="/licences" element={<Licenses />} />
          <Route path="/demander-mutation" element={<MutationRequest />} />
          <Route path="/demander-une-reclamation" element={<Claim />} />
          <Route path="/faire-un-don" element={<Donation />} />
        </Route>

        <Route path="*" element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
