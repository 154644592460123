/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-undef */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getClub } from './api/club';
import { postInvitation } from './api/invitation';
import { getMyProfile } from './api/user';
import countries from './assets/countries.json';
import ButtonLoading from './components/ButtonLoading';

const Club = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [id, setId] = useState(location.pathname.split('/')[2]);
  const [club, setClub] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [activeInfoTab, setActiveInfoTab] = useState('joueurs');

  const renderCharacter = (string) => {
    if (string) {
      return (
        String(string?.slice(0, 1)).toUpperCase() +
        String(string?.slice(1)).toLowerCase()
      );
    }
    return '';
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const getProfile = (clubId) => {
    getMyProfile()
      .then((res) => {
        localStorage?.setItem('xrole', res?.data?.role?.name);
        const CONNECTED_USER = res?.data;
        setUser({
          ...user,
          id: CONNECTED_USER?.id,
          firstName: renderCharacter(CONNECTED_USER?.firstName),
          lastName: renderCharacter(CONNECTED_USER?.lastName),
          email: CONNECTED_USER?.email || '',
          phone: CONNECTED_USER?.phone || '',
          profile: CONNECTED_USER?.profile || '',
          occupation: CONNECTED_USER?.occupation || '',
          verified: CONNECTED_USER?.verified || '',
          birthDate: CONNECTED_USER?.birthDate || '',
          country: CONNECTED_USER?.country || countries.fr[0].value,
          bio: CONNECTED_USER?.bio || '',
          club: CONNECTED_USER?.club || '',
          ligue: CONNECTED_USER?.ligue || '',
          role: CONNECTED_USER?.role || '',
        });
        if (!clubId) setId(CONNECTED_USER?.club.id || '');
        setTimeout(() => {}, 500);
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
        setTimeout(() => {}, 500);
      });
  };

  const clubDataProvider = () => {
    if (id) {
      getClub(id).then((res) => {
        setClub(res.data);
        getProfile(id);
      });
    } else {
      getProfile(id);
    }
  };

  useEffect(() => {
    clubDataProvider();
  }, [id]);

  const handleInvitation = () => {
    setLoading(true);

    postInvitation({
      userId: user.id,
      clubId: id,
    })
      .then(() => {
        setLoading(false);
        setMessage({
          type: 'success',
          message: 'Invitation envoyée avec succès',
        });
      })
      .catch(() => {
        setLoading(false);
        setMessage({
          type: 'error',
          message: 'Une erreur est survenue, veuillez réessayer',
        });
      });
  };

  const renderDate = (date) => {
    return date
      ? Intl.DateTimeFormat('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date(date))
      : '';
  };

  return (
    <div className="mx-auto w-full px-6 py-6">
      <div className="-mx-3 flex flex-wrap">
        <div className="w-full max-w-full flex-none px-3">
          <div className="shadow-soft-xl relative mb-6 flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid border-transparent bg-white bg-clip-border">
            <div className="relative w-full md:min-h-screen">
              <div className="w-full px-4 pt-14 pb-24 sm:px-6 sm:pt-16 sm:pb-32 lg:max-w-7xl lg:px-8">
                {/* Product */}
                <div className="lg:grid lg:grid-cols-7 lg:grid-rows-1 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                  {/* Product image */}
                  <div className="lg:col-span-4 lg:row-end-1">
                    <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-lg bg-gray-100">
                      <img
                        src="/default.png"
                        alt="Sample of 30 icons with friendly and fun details in outline, filled, and brand color styles."
                        className="object-cover object-center"
                      />
                    </div>
                  </div>

                  {/* Product details */}
                  <div className="mx-auto mt-14 max-w-2xl sm:mt-16 lg:col-span-3 lg:row-span-2 lg:row-end-2 lg:mt-0 lg:max-w-none">
                    <div className="flex flex-col-reverse">
                      <div className="mt-4">
                        <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl sm:tracking-tight">
                          {club?.name}
                        </h1>

                        <h2 id="information-heading" className="sr-only">
                          Ligue
                        </h2>
                        {club?.ligue?.name && (
                          <p className="mt-2 text-xs text-gray-500">
                            <span>{club?.ligue?.name}</span>
                          </p>
                        )}
                      </div>
                    </div>

                    <p className="mt-6 text-gray-500">
                      {club?.description ?? 'Aucune description'}
                    </p>

                    {club &&
                      user?.club.id !== club?.id &&
                      user?.role?.name === 'player' &&
                      !user?.principal && (
                        <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-2">
                          <button
                            onClick={handleInvitation}
                            type="button"
                            className="relative flex w-full items-center justify-center rounded-md border border-transparent bg-secondary py-3 px-8 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 focus:ring-offset-gray-50 hover:bg-primary"
                          >
                            Adhérer
                            {loading && <ButtonLoading />}
                          </button>
                        </div>
                      )}

                    <div className="mt-10 border-t border-gray-200 pt-10">
                      <h3 className="text-sm font-medium text-gray-900">
                        Réseaux sociaux
                      </h3>
                      <ul className="mt-4 flex items-center space-x-6">
                        {club?.facebook && (
                          <li>
                            <a
                              target="_blank"
                              href={club?.facebook}
                              className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                              rel="noreferrer"
                            >
                              <span className="sr-only">Facebook</span>
                              <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          </li>
                        )}
                        {club?.instagram && (
                          <li>
                            <a
                              target="_blank"
                              href={club?.instagram}
                              className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                              rel="noreferrer"
                            >
                              <span className="sr-only">Instagram</span>
                              <svg
                                className="h-6 w-6"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                aria-hidden="true"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </a>
                          </li>
                        )}
                        {club?.twitter && (
                          <li>
                            <a
                              target="_blank"
                              href={club?.twitter}
                              className="flex h-6 w-6 items-center justify-center text-gray-400 hover:text-gray-500"
                              rel="noreferrer"
                            >
                              <span className="sr-only">Twitter</span>
                              <svg
                                className="h-5 w-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                              >
                                <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                              </svg>
                            </a>
                          </li>
                        )}
                      </ul>
                      {(!club?.facebook ||
                        !club?.twitter ||
                        !club?.instagram ||
                        !club?.youtube) && (
                        <p className="text-sm font-medium text-gray-500">
                          Aucun
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mx-auto mt-16 w-full max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
                    <div>
                      <div className="border-b border-gray-200">
                        <div
                          className="-mb-px flex space-x-8"
                          aria-orientation="horizontal"
                          role="tablist"
                        >
                          <button
                            id="tab-reviews"
                            className={`whitespace-nowrap border-b-2 ${
                              activeInfoTab === 'joueurs'
                                ? 'border-secondary text-secondary'
                                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800'
                            }  py-6 text-sm font-medium `}
                            // className="{ 'border-secondary text-secondary': selected, 'border-transparent hover:border-gray-300': !(selected) } text-gray-700 hover:text-gray-800"
                            aria-controls="tab-panel-reviews"
                            role="tab"
                            type="button"
                            tabIndex="0"
                            aria-selected="true"
                            onClick={() => setActiveInfoTab('joueurs')}
                          >
                            Joueurs
                          </button>
                          <button
                            id="tab-faq"
                            className={`whitespace-nowrap border-b-2 py-6 text-sm font-medium ${
                              activeInfoTab === 'stats'
                                ? 'border-secondary text-secondary'
                                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800'
                            } `}
                            // className="{ 'border-secondary text-secondary': selected, 'border-transparent hover:border-gray-300': !(selected) } text-gray-700 hover:text-gray-800"
                            aria-controls="tab-panel-faq"
                            role="tab"
                            type="button"
                            tabIndex="-1"
                            aria-selected="false"
                            onClick={() => setActiveInfoTab('stats')}
                          >
                            Stats
                          </button>
                          <button
                            id="tab-license"
                            className={`whitespace-nowrap border-b-2 py-6 text-sm font-medium ${
                              activeInfoTab === 'about'
                                ? 'border-secondary text-secondary'
                                : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800'
                            } `}
                            // className="{ 'border-secondary text-secondary': selected, 'border-transparent hover:border-gray-300': !(selected) } text-gray-700 hover:text-gray-800"
                            aria-controls="tab-panel-license"
                            role="tab"
                            type="button"
                            tabIndex="-1"
                            aria-selected="false"
                            onClick={() => setActiveInfoTab('about')}
                          >
                            A propos
                          </button>
                        </div>
                      </div>

                      <div
                        id="tab-panel-reviews"
                        className={`-mb-10 ${
                          activeInfoTab === 'joueurs' ? 'block' : 'hidden'
                        }`}
                        aria-labelledby="tab-reviews"
                        role="tabpanel"
                        tabIndex="0"
                      >
                        <h3 className="sr-only">Joueurs</h3>

                        <div className="">
                          {club?.users.length > 0 &&
                            club?.users.map((cUser) => {
                              return (
                                <div
                                  role="button"
                                  className="flex h-full w-full cursor-pointer gap-4 border-b border-gray-300 px-2 py-4 last:border-b-0 hover:bg-slate-50"
                                  onClick={() => {
                                    navigate(`/user/${user.id}`);
                                  }}
                                >
                                  {user?.profile ? (
                                    <img
                                      src={`${process.env.REACT_APP_WEB_APP_URL_ASSET_DIR}${user.profile}`}
                                      alt="PROFILE"
                                      className="h-20 w-20 rounded-full object-cover"
                                    />
                                  ) : (
                                    <svg
                                      className="h-10 w-10 rounded-full object-cover text-gray-300"
                                      fill="currentColor"
                                      viewBox="0 0 24 24"
                                    >
                                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                    </svg>
                                  )}

                                  <div className="mt-3 text-left sm:mt-0">
                                    <h2 className="font-semibold">
                                      {cUser.firstName} {cUser.lastName}
                                    </h2>

                                    {cUser.principal && (
                                      <div className="mt-2 w-fit flex-wrap items-center gap-1 rounded-full border border-gray-300 bg-white px-2 py-px text-xs text-indigo-500">
                                        Président
                                      </div>
                                    )}

                                    {cUser?.licence?.number && (
                                      <div>
                                        <span className="text-sm text-gray-500">
                                          {cUser.licence.number}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          {club?.users.length === 0 && (
                            <div className="mt-6 text-sm">
                              Ce club ne possède aucun joueur
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        id="tab-panel-faq"
                        className={`text-sm text-gray-500 ${
                          activeInfoTab === 'stats' ? 'block' : 'hidden'
                        }`}
                        aria-labelledby="tab-faq"
                        role="tabpanel"
                        tabIndex="0"
                      >
                        <h3 className="sr-only">Stats</h3>

                        <div className="">
                          {club?.users && (
                            <div className=" border-b border-dashed border-gray-300 py-4">
                              <span className="font-semibold">
                                Nombre de joueurs:{' '}
                              </span>
                              {club?.users.length}
                            </div>
                          )}

                          {!club?.stats && (
                            <div className="mt-6 text-sm">
                              Ce club n&apos;a participé à aucun
                              concours/championnat
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        id="tab-panel-license"
                        className={`pt-10 ${
                          activeInfoTab === 'about' ? 'block' : 'hidden'
                        }`}
                        aria-labelledby="tab-license"
                        role="tabpanel"
                        tabIndex="0"
                      >
                        <h3 className="sr-only">A propos</h3>

                        <div className="-mt-4 space-y-4 text-sm">
                          {club?.address && (
                            <div className="">
                              <span className="">Situé à </span> {club.address}
                            </div>
                          )}

                          {club?.email && (
                            <div className="">
                              <span className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="inline-block h-5 w-5 text-gray-500"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                                  />
                                </svg>
                              </span>{' '}
                              {club.email}
                            </div>
                          )}

                          {club?.website && (
                            <div className="">
                              <span className="">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  className="inline-block h-5 w-5 text-gray-500"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M20.893 13.393l-1.135-1.135a2.252 2.252 0 01-.421-.585l-1.08-2.16a.414.414 0 00-.663-.107.827.827 0 01-.812.21l-1.273-.363a.89.89 0 00-.738 1.595l.587.39c.59.395.674 1.23.172 1.732l-.2.2c-.212.212-.33.498-.33.796v.41c0 .409-.11.809-.32 1.158l-1.315 2.191a2.11 2.11 0 01-1.81 1.025 1.055 1.055 0 01-1.055-1.055v-1.172c0-.92-.56-1.747-1.414-2.089l-.655-.261a2.25 2.25 0 01-1.383-2.46l.007-.042a2.25 2.25 0 01.29-.787l.09-.15a2.25 2.25 0 012.37-1.048l1.178.236a1.125 1.125 0 001.302-.795l.208-.73a1.125 1.125 0 00-.578-1.315l-.665-.332-.091.091a2.25 2.25 0 01-1.591.659h-.18c-.249 0-.487.1-.662.274a.931.931 0 01-1.458-1.137l1.411-2.353a2.25 2.25 0 00.286-.76m11.928 9.869A9 9 0 008.965 3.525m11.928 9.868A9 9 0 118.965 3.525"
                                  />
                                </svg>
                              </span>{' '}
                              {club?.website}
                            </div>
                          )}

                          {club?.createdAt && (
                            <div className="">
                              <span className="">Crée le</span>{' '}
                              {renderDate(club?.createdAt)}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Club;
