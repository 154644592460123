import { publicRequest, userRequest } from '.';

export const login = async (user) => {
  return publicRequest.post('auth/login', user);
};

export const confirmAccount = async (token) => {
  return publicRequest.post('auth/confirm', token);
};

export const register = async (user) => {
  return publicRequest.post('auth/register', user);
};

export const request = async (email) => {
  return publicRequest.post('auth/forgot', email);
};

export const reset = async (data) => {
  return publicRequest.post('auth/reset', data);
};

export const resend = async (user) => {
  return publicRequest.post(`auth/send-confirmation`, user);
};

export const deleteUser = async (id) => {
  return userRequest.delete(`user/${id}`);
};

export const updateUser = async (id, user) => {
  return userRequest.put(`users/${id}`, user);
};

export const addUser = async (user) => {
  return userRequest.post(`users/`, user);
};

export const updateAdmin = async (user) => {
  return userRequest.put('user/update', user);
};

export const get = async () => {
  return userRequest.get('auth/me');
};

export const getUserRole = async () => {
  return publicRequest.get('roles/user');
};

export const getAllClubs = async () => {
  return publicRequest.get(`clubs`);
};

export const getClubs = async (id) => {
  return publicRequest.get(`ligues/${id}/clubs`);
};

export const getRecentUsers = async () => {
  return userRequest.get(`users/recent`);
};

export const getMyProfile = async () => {
  return userRequest.get(`users/me`);
};

export const getUser = async (id) => {
  return userRequest.get(`users/${id}`);
};

export const getUsers = async (offset) => {
  return userRequest.get(`users?offset=${offset}`);
};

export const getPlayers = async (offset, club) => {
  return userRequest.get(`users/players?offset=${offset}&club=${club}`);
};

export const getAllPlayers = async (club) => {
  return userRequest.get(`users/players?club=${club}`);
};

export const getStats = async () => {
  return userRequest.get(`users/me/stats`);
};

export const getUserLicensesStats = async () => {
  return userRequest.get(`statistics/monthly/license`);
};

export const searchUsers = async (query, offset, club) => {
  return userRequest.get(
    `users/search?query=${query}&offset=${offset}&club=${club}`
  );
};

export const searchPlayers = async (query, offset, club) => {
  return userRequest.get(
    `users/players/search?query=${query}&offset=${offset}&club=${club}`
  );
};
