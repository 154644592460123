/* eslint-disable react/forbid-prop-types */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unknown-property */
/* eslint-disable react/react-in-jsx-scope */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const ContestToolbar = ({
  openSetting,
  openConfirmationPopup,
  createTeam,
  setCreateTeam,
  contest,
  teams,
  count,
  setIsClosedModal,
  displayStats,
  setDisplayStats,
}) => {
  const [leftTime, setLeftTime] = useState(null);

  const renderType = (type) => {
    switch (type) {
      case 'one':
        return 'Tête à tête';
      case 'doubles':
        return 'Doublette';
      case 'triples':
        return 'Triplette';
      case 'shot':
        return 'Tir de précision';

      default:
        return '';
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case 'open':
        return 'Ouvert';
      case 'closed':
        return 'Annulé';
      case 'drawing':
        return 'En cours';
      case 'finished':
        return 'Terminé';
      default:
        return '';
    }
  };

  const renderStatusAction = (status) => {
    switch (status) {
      case 'open':
        return 'Démarrer';
      case 'drawing':
        return 'Clôturer';
      case 'finished':
        return 'Clos';
      case 'closed':
        return 'Annulé';
      default:
        return '';
    }
  };

  const renderLeftTime = () => {
    const now = new Date();
    const end = new Date(contest.start_date);

    const diff = end - now;

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    // render DD Jr - HH H - MM mm - SS s
    if (days > 0 && hours > 0 && minutes > 0 && seconds > 0) {
      setLeftTime(`${days} Jr - ${hours} H - ${minutes} mm - ${seconds} s`);
    } else {
      setLeftTime('----');
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (contest.start_date) renderLeftTime();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [contest?.start_date]);

  return (
    <section className="relative bg-gradient-to-r from-default/80 via-primary to-default  font-sans text-sm font-semibold shadow-black drop-shadow-lg">
      <div className="flex h-fit justify-between py-2 px-5">
        <div className="flex items-center justify-between space-x-8">
          <div className="text-white">
            {contest.type && (
              <p className="block">
                {contest.type === 'challenge' ? 'Concours' : 'Championnat'}/{' '}
                {contest.name}/ {renderStatus(contest.status)}
              </p>
            )}
            <p className="block">{renderType(contest.composition)}</p>
          </div>
        </div>

        <div className="flex items-center">
          <button
            type="button"
            className="hidden items-center justify-center space-x-1 rounded-full bg-white/30 px-4 py-2 text-xs font-medium text-white hover:bg-opacity-90 sm:flex"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M10 19.748V16.4c0-1.283.995-2.292 2.467-2.868A8.482 8.482 0 0 0 9.5 13c-1.89 0-3.636.617-5.047 1.66A8.017 8.017 0 0 0 10 19.748zm8.88-3.662C18.485 15.553 17.17 15 15.5 15c-2.006 0-3.5.797-3.5 1.4V20a7.996 7.996 0 0 0 6.88-3.914zM9.55 11.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5zm5.95 1a2 2 0 1 0 0-4 2 2 0 0 0 0 4zM12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"
                fill="rgba(255,255,255,1)"
              />
            </svg>
            <p>{count} équipes</p>
          </button>
        </div>
      </div>

      <div className="relative top-0 w-full text-center text-white">
        <b className="mx-auto text-xl leading-10">
          {contest?.status === 'finished' && 'Statistiques de la compétition'}
          {contest?.status === 'drawing' && 'Compétition en cours'}
          {contest?.status === 'closed' && 'Compétition annulée'}
          {contest?.status === 'open' && 'Inscription des joueurs'}
        </b>
      </div>

      {/* Sub header */}
      <div className="hidden overflow-hidden p-1 sm:block">
        <div className="relative mx-3 flex w-full flex-row space-x-5">
          {createTeam && contest?.status === 'open' && (
            <button
              type="button"
              onClick={() => {
                createTeam ? setCreateTeam(!createTeam) : '';
                displayStats ? setDisplayStats(!displayStats) : '';
              }}
              className="flex items-center justify-center space-x-1 rounded-full bg-white/30 px-4 py-2 text-xs font-medium text-white hover:bg-opacity-90"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M12 11a5 5 0 0 1 5 5v6H7v-6a5 5 0 0 1 5-5zm-6.712 3.006a6.983 6.983 0 0 0-.28 1.65L5 16v6H2v-4.5a3.5 3.5 0 0 1 3.119-3.48l.17-.014zm13.424 0A3.501 3.501 0 0 1 22 17.5V22h-3v-6c0-.693-.1-1.362-.288-1.994zM5.5 8a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zm13 0a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5zM12 2a4 4 0 1 1 0 8 4 4 0 0 1 0-8z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
              <p>Liste des équipes</p>
            </button>
          )}

          {!createTeam &&
            contest?.status === 'open' &&
            ((localStorage.getItem('xrole') === 'player' &&
              localStorage.getItem('xprincipal') === 'true') ||
              localStorage.getItem('xrole') !== 'player') &&
            localStorage.getItem('xrole') !== 'player' &&
            localStorage.getItem('xrole') !== 'other' && (
              // Button for teams creation
              <button
                type="button"
                disabled={createTeam}
                onClick={() => {
                  setCreateTeam(true);
                }}
                className={`items-center justify-center space-x-1 rounded-full
                 bg-white/30 px-4 py-2 text-xs font-medium text-white hover:bg-opacity-90
                  ${contest.status !== 'open' ? 'hidden' : 'flex'}
                 `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                <p>Créer une équipe</p>
              </button>
            )}

          {!displayStats &&
            contest?.id &&
            contest?.status === 'drawing' &&
            ((localStorage.getItem('xrole') === 'player' &&
              localStorage.getItem('xprincipal') === 'true') ||
              localStorage.getItem('xrole') !== 'player') &&
            localStorage.getItem('xrole') !== 'player' &&
            localStorage.getItem('xrole') !== 'other' && (
              // Button for stats section display
              <button
                type="button"
                disabled={createTeam}
                onClick={() => {
                  setDisplayStats(true);
                }}
                className={`items-center justify-center space-x-1 rounded-full
                 bg-white/30 px-4 py-2 text-xs font-medium text-white hover:bg-opacity-90
                  ${contest.status !== 'open' ? 'flex' : 'hidden'}
                 `}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M13 21v2h-2v-2H3a1 1 0 0 1-1-1V6h20v14a1 1 0 0 1-1 1h-8zM8 10a3 3 0 1 0 3 3H8v-3zm5 0v2h6v-2h-6zm0 4v2h6v-2h-6zM2 3h20v2H2V3z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                <p>Émettre les résultats</p>
              </button>
            )}
        </div>

        <div
          className={`mt-2 flex w-full px-3 ${
            createTeam ? 'justify-between' : 'justify-end'
          }`}
        >
          {createTeam && (
            <div className="flex flex-row space-x-3">
              <form className="max-w-sm px-4">
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="absolute top-0 bottom-0 left-3 my-auto h-6 w-6 text-gray-400"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"
                      fill="rgba(190,189,189,1)"
                    />
                  </svg>
                  <input
                    type="text"
                    placeholder="Recherche par pseudo"
                    className="outline-blue focus:border-blue w-full rounded-3xl  border bg-gray-50 pl-12 pr-4 text-gray-500 focus:bg-white"
                  />
                </div>
              </form>
            </div>
          )}

          <div className="flex items-center justify-center space-x-4">
            {localStorage.getItem('xrole') !== 'player' &&
              localStorage.getItem('xrole') !== 'other' &&
              contest?.status && (
                <button
                  type="button"
                  disabled={
                    createTeam ||
                    contest.status === 'finished' ||
                    contest.status === 'closed'
                  }
                  className="flex h-10 w-fit items-center justify-around space-x-2 rounded-3xl bg-green-700 p-3 text-white transition-all"
                  onClick={() => {
                    if (contest.status === 'open')
                      openConfirmationPopup('start');
                    else openConfirmationPopup('finish');
                  }}
                >
                  {contest?.status === 'open' && (
                    <svg
                      className="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z"
                        fill="rgba(255,255,255,1)"
                      />
                    </svg>
                  )}

                  {contest?.status !== 'open' &&
                    contest?.status !== 'closed' &&
                    contest?.status !== 'finished' && (
                      <svg
                        className="inline-block"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="24"
                        height="24"
                      >
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path
                          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z"
                          fill="rgba(255,255,255,1)"
                        />
                      </svg>
                    )}
                  <p>{renderStatusAction(contest?.status)}</p>
                </button>
              )}

            {contest.status === 'drawing' && (
              <button
                type="button"
                disabled={createTeam}
                className="flex h-10 w-fit items-center justify-around space-x-2 
                          rounded-3xl bg-slate-400 p-3 text-white transition-all"
                onClick={() => {
                  setIsClosedModal(true);
                  openConfirmationPopup('cancel');
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                <p>Annuler</p>
              </button>
            )}
          </div>
        </div>

        <div
          className={`mt-4 mb-1 flex items-center px-4 ${
            createTeam ? 'justify-between' : 'justify-end'
          }`}
        >
          {createTeam && (
            <div className="items-center space-x-6 text-zinc-300">
              <p className="inline">Inscriptions : {teams.length}</p>
            </div>
          )}

          <div className="flex items-center space-x-4">
            {contest.start_date && (
              <p className="h-fit w-fit rounded-3xl border border-dashed border-white/50 px-4 text-white">
                {leftTime}
              </p>
            )}

            {localStorage.getItem('xrole') !== 'player' &&
              localStorage.getItem('xrole') !== 'other' && (
                <button
                  type="button"
                  className="flex h-10 w-12 items-center justify-center rounded-3xl bg-white/50"
                  onClick={openSetting}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M12 1l9.5 5.5v11L12 23l-9.5-5.5v-11L12 1zm0 14a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                      fill="rgba(255,255,255,1)"
                    />
                  </svg>
                </button>
              )}
          </div>
        </div>
      </div>
      {/* Responsive subheader */}
      <div className="bg-silver fixed bottom-0 flex w-full items-center justify-around pt-3 pb-10 text-sm sm:hidden">
        {createTeam && (
          <div className="flex-col items-center space-y-1">
            <button
              type="button"
              onClick={() => {
                setCreateTeam(!createTeam);
              }}
              className="bg-blue flex h-10 w-12 items-center justify-center rounded-3xl"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
                  fill="rgba(255,255,255,1)"
                />
              </svg>
            </button>
            <p>Liste des équipes</p>
          </div>
        )}

        {createTeam && (
          <div className="flex-col items-center space-y-1">
            <button
              type="button"
              className="flex h-10 w-12 items-center justify-center rounded-3xl bg-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"
                  fill="rgba(195,191,191,1)"
                />
              </svg>
            </button>
            <p>Rechercher</p>
          </div>
        )}

        <div className="flex flex-col items-center space-y-1">
          <button
            type="button"
            onClick={() => {
              setCreateTeam(!createTeam);
            }}
            className="bg-blue flex h-10 w-12 items-center justify-center rounded-3xl"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M11 11V5h2v6h6v2h-6v6h-2v-6H5v-2z"
                fill="rgba(255,255,255,1)"
              />
            </svg>
          </button>
          <p>Créer une équipe</p>
        </div>

        {!createTeam &&
          localStorage.getItem('xrole') !== 'player' &&
          localStorage.getItem('xrole') !== 'other' && (
            <div className="flex-col items-center space-y-1">
              <button
                type="button"
                className="bg-blue flex h-10 w-12 items-center justify-center rounded-3xl"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M2 12c0-.865.11-1.703.316-2.504A3 3 0 0 0 4.99 4.867a9.99 9.99 0 0 1 4.335-2.505 3 3 0 0 0 5.348 0 9.99 9.99 0 0 1 4.335 2.505 3 3 0 0 0 2.675 4.63c.206.8.316 1.638.316 2.503 0 .865-.11 1.703-.316 2.504a3 3 0 0 0-2.675 4.629 9.99 9.99 0 0 1-4.335 2.505 3 3 0 0 0-5.348 0 9.99 9.99 0 0 1-4.335-2.505 3 3 0 0 0-2.675-4.63C2.11 13.704 2 12.866 2 12zm4.804 3c.63 1.091.81 2.346.564 3.524.408.29.842.541 1.297.75A4.993 4.993 0 0 1 12 18c1.26 0 2.438.471 3.335 1.274.455-.209.889-.46 1.297-.75A4.993 4.993 0 0 1 17.196 15a4.993 4.993 0 0 1 2.77-2.25 8.126 8.126 0 0 0 0-1.5A4.993 4.993 0 0 1 17.195 9a4.993 4.993 0 0 1-.564-3.524 7.989 7.989 0 0 0-1.297-.75A4.993 4.993 0 0 1 12 6a4.993 4.993 0 0 1-3.335-1.274 7.99 7.99 0 0 0-1.297.75A4.993 4.993 0 0 1 6.804 9a4.993 4.993 0 0 1-2.77 2.25 8.126 8.126 0 0 0 0 1.5A4.993 4.993 0 0 1 6.805 15zM12 15a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-2a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </button>
              <p className="hidden sm:block">Configurer</p>
            </div>
          )}

        {!createTeam &&
          localStorage.getItem('xrole') !== 'player' &&
          localStorage.getItem('xrole') !== 'other' && (
            <div className="flex-col items-center space-y-1">
              <button
                type="button"
                className="bg-green flex h-10 w-12 items-center justify-center rounded-3xl"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
              </button>
              <p>Démarrer</p>
            </div>
          )}
      </div>
    </section>
  );
};

ContestToolbar.propTypes = {
  createTeam: PropTypes.bool.isRequired,
  setCreateTeam: PropTypes.func.isRequired,
  openSetting: PropTypes.func.isRequired,
  openConfirmationPopup: PropTypes.func.isRequired,
  teams: PropTypes.arrayOf(PropTypes.shape([])),
  contest: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    number_of_team: PropTypes.string,
    description: PropTypes.string,
    address: PropTypes.string,
    notify: PropTypes.bool,
    public: PropTypes.bool,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    drawing_date: PropTypes.string,
    composition: PropTypes.string,
    status: PropTypes.string,
    type: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  count: PropTypes.number.isRequired,
  setIsClosedModal: PropTypes.func.isRequired,
  displayStats: PropTypes.bool,
  setDisplayStats: PropTypes.func.isRequired,
};

export default ContestToolbar;
