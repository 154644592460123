/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/aria-role */
import { useFormik } from 'formik';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { addProject, updateProject } from '../api/projet';
import ButtonLoading from './ButtonLoading';

const ProjectModal = (props) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleClose = () => {
    if (show) {
      setShow(false);

      setTimeout(() => {
        if (props.onOpen) {
          props.onClose();
        }
      }, 50);
    }
  };

  const logOut = () => {
    localStorage.removeItem('persist:root');
    window.location.href = '/signin';
  };

  const modalRef = useDetectClickOutside({ onTriggered: handleClose });

  useEffect(() => {
    setShow(true);
  }, []);

  const formik = useFormik({
    initialValues: {
      title: props.data.name || '',
      description: props.data.description || '',
      status: props.data.status || 'open',
    },
    onSubmit: (values) => {
      if (values.title && values.description) {
        if (loading) return;
        setLoading(true);
        setMessage('');
        // Sending datas to the api
        if (!props?.data?.id) {
          addProject({
            name: values.title,
            description: values.description,
            status: values.status,
          })
            .then(() => {
              setLoading(false);
              setMessage([true, 'Le projet a été ajouté avec succès']);
              setTimeout(() => {
                handleClose();
              }, 1000);
            })
            .catch((err) => {
              if (
                err.response?.status === 401 ||
                err.response?.status === 403
              ) {
                logOut();
              } else if (err.response?.status === 400) {
                setMessage([false, 'Le projet existe déjà']);
              } else {
                setMessage([
                  false,
                  "Une erreur est survenue lors de l'ajout du projet",
                ]);
              }
              setLoading(false);
            });
        } else {
          updateProject(props.data.id, {
            name: values.title,
            description: values.description,
            status: values.status,
          })
            .then(() => {
              setLoading(false);
              setMessage([true, 'Le projet a été mise à jour avec succès']);
              setTimeout(() => {
                handleClose();
              }, 1000);
            })
            .catch((err) => {
              if (
                err.response?.status === 401 ||
                err.response?.status === 403
              ) {
                logOut();
              } else {
                setMessage([
                  false,
                  'Une erreur est survenue lors de la modification du projet',
                ]);
              }
              setLoading(false);
            });
        }
      } else {
        setMessage([true, 'Veuillez remplir tous les champs']);
      }
    },
  });

  return (
    <div
      className={`decision-tree sticky-0 fixed inset-0 z-[999] flex h-full w-full items-center justify-center bg-gray-900 bg-opacity-80 px-3 transition-all
      ${show ? 'bg-gray-900' : 'bg-transparent'}`}
    >
      <div
        ref={modalRef}
        className="relative mx-auto w-full max-w-2xl overflow-hidden rounded-3xl bg-white"
      >
        <div className="max-h-[80vh] overflow-y-scroll p-10 px-4 pt-4 pb-16 shadow transition-all scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200 xl:pb-8">
          <header id="header-3a" className="gap- flex items-center">
            <h1 className="text-xl font-bold">Création d&apos;un projet</h1>
            <button
              type="button"
              className="ml-auto inline-flex h-10 items-center justify-center gap-2 justify-self-center whitespace-nowrap rounded-full px-5 text-sm font-medium tracking-wide text-gray-500 transition duration-300 focus:text-primary focus-visible:outline-none disabled:cursor-not-allowed"
              aria-label="close dialog"
              onClick={handleClose}
            >
              <span className="relative only:-mx-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                  role="graphics-symbol"
                  aria-labelledby="title-79 desc-79"
                >
                  <title id="title-79">Close</title>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </header>

          <form onSubmit={formik.handleSubmit}>
            <div className="my-3">
              <label htmlFor="email">Titre</label>

              <input
                id="title"
                name="title"
                className="w-full rounded border border-gray-300 bg-white p-3.5"
                onChange={formik.handleChange}
                value={formik.values.title}
              />
            </div>
            <div className="my-3">
              <label htmlFor="email">Description</label>

              <textarea
                name="description"
                id="description"
                cols="30"
                rows="10"
                onChange={formik.handleChange}
                value={formik.values.description}
                className="w-full rounded border border-gray-300 bg-white p-3.5"
              />
            </div>

            {props?.data && (
              // select status
              <div className="my-3">
                <label htmlFor="status">Status</label>
                <select
                  name="status"
                  id="status"
                  onChange={formik.handleChange}
                  value={formik.values.status}
                  className="w-full rounded border border-gray-300 bg-white p-3.5"
                >
                  <option value="open">Ouvert</option>
                  <option value="closed">Fermé</option>
                </select>
              </div>
            )}

            <div
              className={`w-full items-center justify-center text-center text-sm ${
                message[0] ? 'text-primary' : 'text-secondary'
              }`}
            >
              {message[1]}
            </div>

            <div className="flex w-full justify-between">
              <button
                type="button"
                className={`xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md bg-150 bg-x-25 mb-2 ml-2 inline-block w-fit cursor-pointer rounded-lg border border-solid border-secondary bg-transparent bg-none px-4 py-3 text-center align-middle text-xs font-bold uppercase text-secondary transition-all hover:border-secondary
                  ${!loading && 'duration-300 hover:scale-105'}`}
                data-class="bg-white"
                onClick={handleClose}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="xl-max:cursor-not-allowed xl-max:opacity-65 xl-max:pointer-events-none 
                xl-max:bg-gradient-to-tl xl-max:from-purple-700 xl-max:to-pink-500 
                xl-max:text-white xl-max:border-0 hover:scale-102 hover:shadow-soft-xs 
                active:opacity-85 leading-pro ease-soft-in tracking-tight-soft shadow-soft-md 
                bg-150 bg-x-25 relative mb-2 inline-block w-fit cursor-pointer rounded-lg border 
                border-solid border-transparent bg-primary bg-gradient-to-tl from-primary
                 to-default/10 px-4 py-3 text-center align-middle text-xs font-bold uppercase
                  text-white transition-all hover:border-primary"
                data-class="bg-transparent"
              >
                {loading && 'En cours'}
                {!loading && 'Valider'}
                {loading && <ButtonLoading />}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

ProjectModal.propTypes = {
  onOpen: PropTypes.bool,
  onClose: PropTypes.string,
  onSuccess: PropTypes.func,
  isClosedModal: PropTypes.bool,
  setIsClosedModal: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    status: PropTypes.string,
  }),
};

export default ProjectModal;
