/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getMyProfile } from '../api/user';

const Navbar = () => {
  const [connectUser, setConnectUser] = useState({});
  const path = useLocation().pathname;

  const logOut = () => {
    localStorage.removeItem('persist:root');
    localStorage.removeItem('xprincipal');
    window.location.href = '/signin';
  };

  const renderCharacter = (string) => {
    if (string) {
      return (
        String(string?.slice(0, 1)).toUpperCase() +
        String(string?.slice(1)).toLowerCase()
      );
    }
    return '';
  };

  const getProfile = () => {
    getMyProfile()
      .then((res) => {
        setConnectUser({
          firstName: renderCharacter(res.data?.firstName),
          lastName: renderCharacter(res.data?.lastName),
        });
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          logOut();
        }
      });
  };

  useEffect(() => {
    getProfile();
  }, []);

  useEffect(() => {
    const aside = document.querySelector('aside');
    aside.classList.remove('translate-x-0', 'shadow-soft-xl');
    aside.classList.add('-translate-x-full');
  }, [path]);

  return (
    <nav
      className="duration-250 ease-soft-in relative z-20 flex w-full flex-wrap items-center justify-between px-6 py-2 shadow-none transition-all lg:flex-nowrap lg:justify-start"
      navbar-profile
      navbar-scroll="true"
    >
      <div className="flex-wrap-inherit mx-auto flex w-full items-center justify-between py-1">
        <nav className="flex w-full">
          <ol className="mr-12 flex flex-wrap items-center rounded-lg bg-transparent pt-1 pl-2 pr-4 sm:mr-16">
            <li className="text-sm leading-normal">
              <a className="opacity-50" href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M21 20a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.49a1 1 0 0 1 .386-.79l8-6.222a1 1 0 0 1 1.228 0l8 6.222a1 1 0 0 1 .386.79V20zm-2-1V9.978l-7-5.444-7 5.444V19h14z" />
                </svg>
              </a>
            </li>
            <li
              className="pl-2 text-sm capitalize leading-normal before:float-left before:pr-2 before:content-['/']"
              aria-current="page"
            >
              <h6 className="mb-2 ml-2 font-bold capitalize">
                Tableau de bord
              </h6>
            </li>
          </ol>

          <button
            class="ease-soft-in-out ml-auto block p-0 text-sm text-white transition-all lg:hidden"
            sidenav-trigger
            type="button"
            onClick={() => {
              const aside = document.querySelector('aside');

              if (aside) {
                if ([...aside.classList].includes('-translate-x-full')) {
                  aside.classList.remove('-translate-x-full');
                  aside.classList.add('translate-x-0', 'shadow-soft-xl');
                } else {
                  aside.classList.remove('translate-x-0', 'shadow-soft-xl');
                  aside.classList.add('-translate-x-full');
                }
              }
            }}
          >
            <div class="ease-soft mb-0.75 relative block overflow-hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M3 4h18v2H3V4zm0 7h18v2H3v-2zm0 7h18v2H3v-2z" />
              </svg>
            </div>
          </button>
        </nav>

        <div className="mt-2 flex grow items-center sm:mt-0 sm:mr-6 md:mr-0  lg:flex lg:basis-auto">
          <div className="flex items-center md:ml-auto">
            <p className="mt-1.5 mb-3 text-lg font-bold text-[#11243D]">
              {connectUser.lastName} {connectUser.firstName}
            </p>
          </div>
          <button
            type="button"
            className="py-2.7 ease-nav-brand my-0 mx-4 flex hidden items-center whitespace-nowrap px-4 pl-1 text-sm transition-colors md:pr-4 lg:block"
            onClick={logOut}
          >
            <div className="shadow-soft-2xl mr-2 flex h-10 w-fit items-center justify-center rounded-lg bg-white bg-center stroke-0 text-center hover:bg-secondary hover:text-white xl:p-2.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                fill="currentColor"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M5 11h8v2H5v3l-5-4 5-4v3zm-1 7h2.708a8 8 0 1 0 0-12H4A9.985 9.985 0 0 1 12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10a9.985 9.985 0 0 1-8-4z" />
              </svg>
              <span className="ml-1 pr-1">Se déconnecter</span>
            </div>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
